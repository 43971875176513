import React, { FunctionComponent } from "react"
import { Grid, TextField } from "@material-ui/core"
import { useIntl } from "react-intl"
import { slugify } from "../utils"
import { FieldErrors } from "react-hook-form"
import { ICreateApplicationValues } from "./types"

type AppInfoStepProps = {
    onChange: (field: string, value: string | undefined, validate: boolean) => void
    fields: ICreateApplicationValues
    errors: FieldErrors<ICreateApplicationValues>
    disableName?: boolean
}

export const AppInfoStep: FunctionComponent<AppInfoStepProps> = (props) => {
    const { formatMessage: intl } = useIntl()

    return (
        <>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <TextField
                    data-tut="app.create.name"
                    color="primary"
                    variant="outlined"
                    disabled={props.disableName}
                    required
                    label={ intl({ id: "applications.forms.name" }) }
                    size="small"
                    placeholder={ intl({ id: "applications.forms.name" }) }
                    fullWidth
                    name="name"
                    value={ props.fields.name || "" }
                    onChange={ e => {props.onChange("name", e.target.value, true)} }
                    error={ !!props.errors.name }
                    helperText={ (!!props.errors.name && (props.errors.name as any).message) || `Application ID: ${ slugify(props.fields.name as string) }` }/>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 8 }>
                <TextField
                    data-tut="app.create.appUrl"
                    color="primary"
                    variant="outlined"
                    label={ intl({ id: "applications.forms.appUrl" }) }
                    size="small"
                    placeholder={ intl({ id: "applications.forms.appUrl" }) }
                    fullWidth
                    name="appUrl"
                    value={ props.fields.appUrl || "" }
                    onChange={ e => {props.onChange("appUrl", e.target.value, true)} }
                    error={ !!props.errors.appUrl }
                    helperText={ (intl({ id: "forms.errors.url" })) }/>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <TextField
                    data-tut="app.create.supportEmail"
                    color="primary"
                    variant="outlined"
                    label={ intl({ id: "applications.forms.supportEmail" }) }
                    size="small"
                    fullWidth
                    name="supportEmail"
                    value={ props.fields.supportEmail || "" }
                    onChange={ (e) => {
                        if (e.target.value === "") props.onChange("supportEmail", undefined, false)
                        else props.onChange("supportEmail", e.target.value, false)
                    } }
                    error={ !!props.errors.supportEmail }
                    helperText={ (!!props.errors.supportEmail && (props.errors.supportEmail as any).message) || " " }/>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <TextField
                    data-tut="app.create.termsUrl"
                    variant="outlined"
                    fullWidth={ true }
                    color="primary"
                    size="small"
                    label={ intl({ id: "applications.forms.termsUrl" }) }
                    name="terms_url"
                    onChange={ (e) => {props.onChange("termsUrl", e.target.value, false)} }
                    value={ props.fields.termsUrl || "" }
                    error={ !!props.errors.termsUrl }
                    helperText={ intl({ id: "forms.errors.url" }) }/>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <TextField
                    data-tut="app.create.policyUrl"
                    variant="outlined"
                    fullWidth={ true }
                    color="primary"
                    size="small"
                    label={ intl({ id: "applications.forms.policyUrl" }) }
                    name="policy_url"
                    onChange={ (e) => {props.onChange("policyUrl", e.target.value, false)} }
                    value={ props.fields.policyUrl || "" }
                    error={ !!props.errors.policyUrl }
                    helperText={ intl({ id: "forms.errors.url" }) }/>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 12 }>
                <TextField
                    data-tut="app.create.description"
                    variant="outlined"
                    fullWidth={ true }
                    color="primary"
                    size="small"
                    label={ intl({ id: "applications.forms.description" }) }
                    name="description"
                    onChange={ (e) => {props.onChange("description", e.target.value, false)} }
                    value={ props.fields.description || "" }
                    error={ !!props.errors.description }
                    helperText={ (!!props.errors.description && (props.errors.description as any).message) || " " }/>
            </Grid>
        </>
    )
}