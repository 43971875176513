import React, { ChangeEvent, FunctionComponent, useState } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { IAPIPermission } from "../api/types"
import apiService from "../api"
import { Chip, Tooltip, Button, makeStyles } from "@material-ui/core"
import { useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { useIntl } from "react-intl"
import classes from "*.module.css"

type AutocompletePermissionsProps = {
    onChange: (e: ChangeEvent<{}>, value: IAPIPermission[]) => void
    options?: IAPIPermission[]
    value?: IAPIPermission[]
    error?: any
    dataTut?: string
}

const useStyles = makeStyles(theme => ({
    root: {
        fontWeight: 400,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    fullWidth: {
        width: '100%',
        height: '100%'
    }
}))

export const AutocompletePermissions: FunctionComponent<AutocompletePermissionsProps> = (props) => {
    const [open, setOpen] = useState(false)
    const idOrganization = useSelector<IStoreState, string | undefined>(state => state.user?.jwtPayload?.ido)
    const [options, setOptions] = useState<IAPIPermission[]>([])
    const isAdmin = useSelector<IStoreState, boolean | undefined>(state => state.user?.jwtPayload?.admin)
    const loading = open && options.length === 0
    const { formatMessage: intl } = useIntl()
    const classes = useStyles()


    React.useEffect(() => {
        let active = true

        if (!loading) {
            return undefined
        }

        if (!props.options) {
            (async () => {
                try {
                    let options: IAPIPermission[]
                    let response
                    if (isAdmin) {
                        response = await apiService.getAdminPermissions()
                    } else {
                        response = await apiService.getOrganizationPermissions(idOrganization || "", { orderBy: "", page: 0, perPage: 0 })
                    }
                    options = response.data
                    if (active) {
                        const uniqueOptions: any = Array.from(new Set(options.map(o => o.idPermission))).map(id => {
                            return options.find(o => o.idPermission === id)
                        }) || []
                        setOptions(uniqueOptions)
                    }
                } catch (e) {
                }
            })()
        } else {
            setOptions(props.options)
        }
        console.log(options)
        return () => {
            active = false
        }
    }, [loading])

    return (
        <Autocomplete<IAPIPermission>
            id="permissions-filled"
            options={ options }
            value={ props.value }
            groupBy={ (row) => {
                if (row.idApplication && row.applicationName) return row.applicationName
                else return "Other"
            } }
            open={ open }
            onOpen={ () => {
                setOpen(true)
            } }
            onClose={ () => {
                setOpen(false)
            } }
            multiple
            getOptionLabel={ (option) => option.path }
            onChange={ props.onChange }
            filterSelectedOptions
            renderOption = {(option) => {
                    return (
                        <Tooltip className={classes.fullWidth} title={`${option.description}`} placement="bottom-start">
                          <div>
                            <Button className={classes.root}>
                                {option.path}
                            </Button>
                          </div>
                        </Tooltip>
                    );
                  }
            }
            renderTags={ (value, getTagProps) =>
                value.map((option, index) => (
                    <Chip label={ option.path } { ...getTagProps({ index }) }/>
                ))
            }
            loading={ loading }
            renderInput={ params => (
                <TextField
                    { ...params }
                    color="primary"
                    variant="outlined"
                    label={intl({ id: "labels.permissions" })}
                    size="small"
                    placeholder={intl({ id: "placeholders.permission" })}
                    fullWidth
                    name="permission"
                    error={ props.error }
                    helperText={ (props.error && (props.error as any).message) || " " }
                />
            ) }
        />
    )
}