import React, { FunctionComponent } from "react"
import { Grid, Typography } from "@material-ui/core"
import { useIntl } from "react-intl"
import { slugify } from "../utils"
import { ICreateApplicationValues } from "./types"
import { makeStyles } from "@material-ui/core/styles"

type AppReviewStepProps = {
    fields: ICreateApplicationValues
}

const useStyles = makeStyles(() => ({
    stepContainer: {
        marginTop: 20,
        paddingLeft: 40,
        paddingRight: 40
    }
}))

export const AppReviewStep: FunctionComponent<AppReviewStepProps> = (props) => {
    const { formatMessage: intl } = useIntl()
    const classes = useStyles()
    const { fields } = props

    return (
        <Grid container className={ classes.stepContainer } spacing={ 2 }>
            <Grid item xs={ 12 }>
                <Typography variant="h6">You are going to create the application:
                    <strong>{ fields.name }</strong>
                </Typography>
            </Grid>
            <Grid item xs={ 12 }>
                <Typography variant="h6">With the following properties:</Typography>
            </Grid>
            <Grid item xs={ 12 }>
                <Typography variant="h6">{ intl({ id: "applications.forms.supportEmail" }) }:
                    <strong>{ fields.supportEmail || intl({ id: "forms.na" }) }</strong>
                </Typography>
            </Grid>
            <Grid item xs={ 12 }>
                <Typography variant="h6">{ intl({ id: "applications.forms.termsUrl" }) }:
                    <strong>{ fields.termsUrl || intl({ id: "forms.na" }) }</strong>
                </Typography>
            </Grid>
            <Grid item xs={ 12 }>
                <Typography variant="h6">{ intl({ id: "applications.forms.policyUrl" }) }:
                    <strong>{ fields.policyUrl || intl({ id: "forms.na" }) }</strong>
                </Typography>
            </Grid>

            <Grid item xs={ 12 }>
                <Typography variant="h6">{ intl({ id: "applications.forms.description" }) }:
                    <strong>{ fields.description || intl({ id: "forms.na" }) }</strong>
                </Typography>
            </Grid>
            <Grid item xs={ 12 }>
                <Typography variant="h6">
                    <strong>{ fields.name }</strong> { intl({ id: "applications.forms.review.visTags" }) }
                </Typography>
            </Grid>
            <Grid item xs={ 12 }>
                <Typography variant="h6">
                    <strong>{ fields.visTags?.join(",") || intl({ id: "forms.na" }) }</strong>
                </Typography>
            </Grid>
            <Grid item xs={ 12 }>
                <Typography
                    variant="h6">{ intl({ id: "applications.forms.review.permissions" }) }</Typography>
            </Grid>
            {
                fields.features.map((feature, index) => {
                    return (
                        <Grid key={ feature.macroFunction + index } item xs={ 12 }>
                            <Typography variant="h6">
                                <strong>{ feature.macroFunction }</strong>: { feature.functions.join(",") }
                            </Typography>
                        </Grid>
                    )
                })
            }
            <Grid item xs={ 12 }>
                <Typography variant="h6">{ intl({ id: "applications.forms.review.roles" }) }</Typography>
            </Grid>
            {
                fields.roles.map((role, index) => {
                    return (
                        <Grid key={ role.name + index } item xs={ 12 }>
                            <Typography variant="h6">
                                <strong>{ role.name }</strong>: { role.permissions.map((permission) => slugify(permission.macroFunction as string) + "." + slugify(permission.permission as string)).join(",") }
                            </Typography>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}