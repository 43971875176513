import React, { FunctionComponent } from "react"
import { Button, makeStyles } from "@material-ui/core"

type ToggleStatusButtonProps = {
    action: "enable" | "disable"
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    style?: React.CSSProperties
    disabled?: boolean
}

const useStyles = makeStyles(theme => ({
    disabledButton: {
        backgroundColor: 'lightgray',
        color: "white"
    },
    enabledButton: {
        backgroundColor: 'red',
        color: "white"
    }
}))


export const ToggleStatusButton: FunctionComponent<ToggleStatusButtonProps> = (props) => {
    const classes = useStyles();

    return (
        <Button
            type="button"
            variant="contained"
            disabled={props.disabled || false}
            onClick={ props.onClick }
            classes={{ disabled: classes.disabledButton, root: classes.enabledButton }}
        >
            { props.children }
        </Button>
    )
}
