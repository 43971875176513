import React, { FunctionComponent, useEffect, useState } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { Button, Dialog, Grid, Typography, DialogTitle, DialogContent, DialogContentText, DialogActions, useMediaQuery } from "@material-ui/core"
import { homeCards } from "../utils/config"
import { useIntl } from "react-intl"
import { enqueueSnackBar, startTutorial } from "../store/actions"
import { appViewerSteps } from "../tutorials"
import { useDispatch } from "react-redux"
import apiService from "../api"
import axios, { CancelTokenSource } from "axios"
import { DataTable } from "../components/DataTable"
import { IAPIInvitation } from "../api/types"
import { formatDate } from "../utils"

const CancelToken = axios.CancelToken

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    profileCard: {
        width: "100%"
    },
    profileList: {
        textAlign: "left"
    },
    avatarContainer: {
        paddingBottom: theme.spacing(2)
    },
    fieldHeader: {
        color: "#404040",
        flexGrow: 0,
        flexBasis: 156,
        paddingRight: 10
    },
    deleteIcon: {
        padding: 8
    },
    grow: {
        flexGrow: 1
    },
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    hidden: {
        display: "none"
    },
    buttonLink: {
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: 600
    },
    mb: {
        marginBottom: theme.spacing(3)
    },
    formControl: {
        margin: theme.spacing(0)
    },
    featuresContainer: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    }
}))

const columns = [
    { name: "email", title: "email" },
    {
        name: "created", title: "Created", getCellValue: (row: IAPIInvitation) => {
            return formatDate(row.created || "")
        }
    },
    {
        name: "type", title: "Type", getCellValue: (row: IAPIInvitation) => {
            return row.type && row.type ? row.type.toLocaleLowerCase() : ""
        }
    },
    { name: "status", title: "Status" }
]

type InvitationsProps = {}

export const Invitations: FunctionComponent<InvitationsProps> = () => {
    const classes = useStyles()
    const [openDialog, setOpenDialog] = React.useState(false)
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))
    const [selectedInvitation, setSelectedInvitation] = useState<IAPIInvitation | undefined>(undefined)
    const source = CancelToken.source()
    const [invitations, setInvitations] = useState<IAPIInvitation[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        listInvitations(source)
    }, [])

    const listInvitations = (source: CancelTokenSource) => {
        setLoading(true)
        apiService.listInvitations({}, source.token).then((response) => {
            setInvitations(response.data)
        }).catch().finally(() => setLoading(false))
    }

    const handleEnableDisableInvitation = (id: string) => {
        const idx = invitations.findIndex(invitation => invitation.idInvitation === id)
        setSelectedInvitation(invitations[idx])
        setOpenDialog(true)
    }

    const enableDisableInvitation = async () => {
        try {
            setLoading(true)
            await apiService.deleteInvitation(selectedInvitation?.idInvitation || "")
            dispatch(enqueueSnackBar(
                { key: selectedInvitation?.idInvitation || "", message: "Invitation successfully removed!", options: { persist: false, preventDuplicate: false, variant: "success" } }
            ))
            listInvitations(source)
        } catch {
            dispatch(enqueueSnackBar(
                { key: selectedInvitation?.idInvitation || "", message: "Impossible to remove Invitation", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        handleClose()
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    return (
        <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
            <Typography variant="h4">{ intl({ id: homeCards[6].intlId + ".title" }) }</Typography>
            <Typography variant="subtitle1"
                        className={ classes.caption }>{ intl({ id: homeCards[6].intlId + ".description" }) }</Typography>
            <Grid container justify="center" className={ classes.mb }>
                <Grid item>
                    <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                            onClick={ () => listInvitations(source) }>Refresh</Button>
                    <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                            onClick={ () => dispatch(startTutorial(appViewerSteps(intl))) }>{ intl({ id: "functions.help" }) }</Button>
                </Grid>
            </Grid>
            <DataTable<IAPIInvitation> loading={ loading }
                                       totalCount={ invitations.length }
                                       columns={ columns }
                                       rows={ invitations }
                                       idField="idInvitation"
                                       pageSize={ 10 }
                                       pageSizes={ [5, 10, 15] }
                                       defaultSorting={ [{ columnName: "email", direction: "asc" }] }
                                       showDeleteButton
                                       onDeleteRow={ (row) => handleEnableDisableInvitation(row.idInvitation || "") }/>
            < Dialog
                fullScreen={ fullScreen }
                open={ openDialog }
                onClose={ handleClose }
                aria-labelledby="enable-disable-app-dialog-title"
            >
                <DialogTitle id="enable-disable-app-dialog-title">Do you want
                    to { selectedInvitation?.status === "ACTIVE" ? "disable" : "enable" } { selectedInvitation?.email }'s
                    invitation?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that if the user has already accepted the invitation the operation won't have any
                        effects.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleClose }>
                        Cancel
                    </Button>
                    <Button onClick={ enableDisableInvitation } color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}