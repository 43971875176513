import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, LOGOUT, REMOVE_SNACKBAR, SET_API_FAILURE, SET_API_SUCCESS, SET_APPLICATIONS, SET_LOADING, SET_LOCALE, SET_REFRESHING_TOKEN, SET_SERVICE, SET_USER, START_TUTORIAL, STOP_TUTORIAL, SWITCH_THEME, SWITCH_VISUALIZATION } from "./constants"
import { INotification, IService, IUser } from "./types"
import { IAPIApplication, IAPIError, IAPISuccess } from "../api/types"

export interface ISetLocale {
    type: typeof SET_LOCALE
    locale: "en" | "it"
}

export interface ISwitchTheme {
    type: typeof SWITCH_THEME
}

export interface ISwitchVisualization {
    type: typeof SWITCH_VISUALIZATION
    visualization: "cards" | "table"
}

export interface ISetService {
    type: typeof SET_SERVICE
    service: IService
}

export interface ISetApplications {
    type: typeof SET_APPLICATIONS
    apps: IAPIApplication[]
}

export interface ISetUser {
    type: typeof SET_USER
    user: IUser
}

export interface IStartTutorial {
    type: typeof START_TUTORIAL
    steps: any
}

export interface IStopTutorial {
    type: typeof STOP_TUTORIAL
}

export interface ILogout {
    type: typeof LOGOUT
}

export interface ISetLoading {
    type: typeof SET_LOADING
    pending: boolean
}

export interface IApiRequestSuccess {
    type: typeof SET_API_SUCCESS
    data: IAPISuccess
}

export interface IApiRequestFailure {
    type: typeof SET_API_FAILURE
    error: IAPIError
}

export interface IApiRequestFailure {
    type: typeof SET_API_FAILURE
    error: IAPIError
}

export interface IEnqueueSnackbar {
    type: typeof ENQUEUE_SNACKBAR
    notification: INotification
}

export interface ISetRefreshingToken {
    type: typeof SET_REFRESHING_TOKEN
    refreshing: boolean
}

export interface IRemoveSnackbar {
    type: typeof REMOVE_SNACKBAR
    key: string
}

export interface ICloseSnackbar {
    type: typeof CLOSE_SNACKBAR
    dismissAll: boolean
    key?: string
}

//define actions
export function setApiRequestPending(pending: boolean): ISetLoading {
    return {
        type: SET_LOADING,
        pending
    }
}

export function setApiRequestSucceeded(data: IAPISuccess): IApiRequestSuccess {
    return {
        type: SET_API_SUCCESS,
        data
    }
}

export function setApiRequestFailed(error: IAPIError): IApiRequestFailure {
    return {
        type: SET_API_FAILURE,
        error
    }
}

export function setLoading(pending: boolean): ISetLoading {
    return {
        type: SET_LOADING,
        pending
    }
}

export function setLocale(locale: "en" | "it"): ISetLocale {
    return {
        type: SET_LOCALE,
        locale
    }
}

export function switchTheme(): ISwitchTheme {
    return {
        type: SWITCH_THEME
    }
}

export function switchVisualization(visualization: "cards" | "table"): ISwitchVisualization {
    return {
        type: SWITCH_VISUALIZATION,
        visualization
    }
}

export function setRefreshingToken(refreshing: boolean): ISetRefreshingToken {
    return {
        type: SET_REFRESHING_TOKEN,
        refreshing
    }
}

export function setService(service: IService): ISetService {
    return {
        type: SET_SERVICE,
        service
    }
}

export function setUser(user: IUser): ISetUser {
    return {
        type: SET_USER,
        user
    }
}

export function setApplications(apps: IAPIApplication[]): ISetApplications {
    return {
        type: SET_APPLICATIONS,
        apps
    }
}


export function startTutorial(steps: any): IStartTutorial {
    return {
        type: START_TUTORIAL,
        steps
    }
}

export function stopTutorial(): IStopTutorial {
    return {
        type: STOP_TUTORIAL
    }
}

export function enqueueSnackBar(notification: INotification): IEnqueueSnackbar {
    const key = notification.options && notification.options.key

    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random()
        }
    }
}

export function closeSnackbar(key?: string): ICloseSnackbar {
    return {
        type: CLOSE_SNACKBAR,
        dismissAll: !key, // dismiss all if no key has been defined
        key
    }
}

export function removeSnackbar(key: string): IRemoveSnackbar {
    return {
        type: REMOVE_SNACKBAR,
        key
    }
}

export function logout(): ILogout {
    return {
        type: LOGOUT
    }
}

export type ActionTypes = ISetLoading | IApiRequestSuccess | IApiRequestFailure | ISetUser | IStartTutorial | IStopTutorial | ILogout | ISetLocale | ISwitchTheme | ISwitchVisualization | ISetService | ISetApplications | ICloseSnackbar | IRemoveSnackbar | IEnqueueSnackbar | ISetRefreshingToken
