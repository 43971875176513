import client from "./config"
import { AxiosPromise, CancelToken, Method } from "axios"
import { IExchangeCodeRequest, IGetUsersWithRolesRequest } from "../store/types"
import { IAPIApplication, IAPIChangePassword, IAPIInvitation, IAPIOrganization, IAPIPaginatedRequest, IAPIUser, IAPIExchangeCodeResponse, IAPICreateOrganizationRequest, IAPICreateUserRequest, IAPIRole, IAPIServiceAccount, IAPICreateServiceRequest, IAPIPermission, IAPICreateApplicationRequest, IAPICreateRole, IAPICreatePermission, IUpdateApplication, IAPIUpdateRole, IAPIOrganizationUser, IAPIPermissions, IAPIUserRolesUpdate } from "./types"
import qs from "qs"

class ApiService {
    exchangeCode(data: IExchangeCodeRequest): AxiosPromise<IAPIExchangeCodeResponse> {
        return this._performRequest("post", "oauth/token/?grant_type=authorization_code", qs.stringify(data))
    }

    logout(userId: string): AxiosPromise<void> {
        return this._performRequest("patch", `authentication/logout`)
    }

    updateProfile(userId: string, data: any, token: CancelToken): AxiosPromise<IAPIUser> {
        return this._performRequest("put", `resources/users/${ userId }`, data, token)
    }

    changePassword(userId: string, data: IAPIChangePassword, token: CancelToken): AxiosPromise<void> {
        return this._performRequest("patch", `resources/users/${ userId }/change-password`, data, token)
    }

    listApplications(paginatedRequest: IAPIPaginatedRequest, token?: CancelToken): AxiosPromise<IAPIApplication[]> {
        return this._performRequest("get", "resources/applications", paginatedRequest, token)
    }

    createApplication(data: IAPICreateApplicationRequest): AxiosPromise<IAPIApplication> {
        return this._performRequest("post", "resources/applications", data)
    }

    updateApplication(idApplication: string, data: IUpdateApplication): AxiosPromise<IAPIApplication> {
        return this._performRequest("put", `resources/applications/${ idApplication }`, data)
    }

    updateApplicationPermissions(idApplication: string, data: IAPICreatePermission[]): AxiosPromise<IAPIPermission[]> {
        return this._performRequest("put", `resources/applications/${ idApplication }/permissions`, data)
    }

    updateApplicationRoles(idApplication: string, data: IAPIUpdateRole[]): AxiosPromise<IAPIRole[]> {
        return this._performRequest("put", `resources/applications/${ idApplication }/roles`, data)
    }

    updateApplicationVisTags(idApplication: string, data: string[]): AxiosPromise<string[]> {
        return this._performRequest("put", `resources/applications/${ idApplication }/visibilityTags`, data)
    }

    toggleApplicationStatus(idApplication: string, newStatus: "ACTIVE" | "INACTIVE"): AxiosPromise<void> {
        return this._performRequest("put", `resources/applications/${ idApplication }/status`, { status: newStatus })
    }

    getApplication(idApplication: string): AxiosPromise<IAPIApplication> {
        return this._performRequest("get", `resources/applications/${ idApplication }`)
    }

    removeApplicationUsage(idOrganization: string, idApplication: string): AxiosPromise<IAPIOrganization[]> {
        return this._performRequest("delete", `resources/organizations/${ idApplication }/applications/${ idApplication }`)
    }

    listOrganizations(paginatedRequest: IAPIPaginatedRequest, token?: CancelToken): AxiosPromise<IAPIOrganization[]> {
        return this._performRequest("get", "resources/organizations", paginatedRequest, token)
    }

    getOrganization(idOrganization: string, details: boolean): AxiosPromise<IAPIOrganization> {
        return this._performRequest("get", `resources/organizations/${ idOrganization }`, { details })
    }

    updateOrganization(idOrganization: string, data: IAPIOrganization): AxiosPromise<IAPIOrganization> {
        return this._performRequest("put", `resources/organizations/${ idOrganization }`, data)
    }

    patchOrganizationPermissions(idOrganization: string, data: { roles: string[], visibilityTags: string[] }): AxiosPromise<IAPIOrganization> {
        return this._performRequest("patch", `resources/organizations/${ idOrganization }?field_path=roles,tags`, data)
    }

    patchOrganizationInfo(idOrganization: string, data: { name: string, description: string }): AxiosPromise<IAPIOrganization> {
        return this._performRequest("patch", `resources/organizations/${ idOrganization }?field_path=name,description`, data)
    }

    toggleOrganizationStatus(idOrganization: string, newStatus: "ACTIVE" | "INACTIVE"): AxiosPromise<void> {
        return this._performRequest("put", `resources/organizations/${ idOrganization }/status`, { status: newStatus })
    }

    listOrganizationPermissions(idOrganization: string): AxiosPromise<IAPIPermission[]> {
        return this._performRequest("get", `resources/organizations/${ idOrganization }/permissions`)
    }

    removeUserOrganization(idOrganization: string, idUser: string): AxiosPromise<void> {
        return this._performRequest("delete", `resources/organizations/${ idOrganization }/users/${ idUser }`)
    }

    removeUser(idUser: string): AxiosPromise<void> {
        return this._performRequest("delete", `resources/users/${ idUser }`)
    }

    listUsers(paginatedRequest: IAPIPaginatedRequest, token?: CancelToken): AxiosPromise<IAPIUser[]> {
        return this._performRequest("get", "resources/users", paginatedRequest, token)
    }

    listUsersWithRoles(rolesIdsRequest: IGetUsersWithRolesRequest): AxiosPromise<IAPIUser[]> {
        return this._performRequest("get", "resources/users/list-by-roles", rolesIdsRequest)
    }

    getOrganizationUser(idOrganization: string, idUser: string): AxiosPromise<IAPIOrganizationUser> {
        return this._performRequest("get", `resources/organizations/${ idOrganization }/users/${ idUser }`)
    }

    getUser(userId: string): AxiosPromise<IAPIUser> {
        return this._performRequest("get", `resources/users/${ userId }`)
    }

    updateUser(userId: string, data: IAPICreateUserRequest): AxiosPromise<IAPIUser> {
        return this._performRequest("put", `resources/users/${ userId }`)
    }

    updateUserRoles(userId: string, data: IAPIUserRolesUpdate): AxiosPromise<IAPIUser> {
        return this._performRequest("put", `resources/users/${ userId }/roles`, data)
    }

    updateUserVisibilityTags(userId: string, data: string[]): AxiosPromise<IAPIUser> {
        return this._performRequest("put", `resources/users/${ userId }/visibilitytags`)
    }

    deleteUser(userId: string): AxiosPromise<void> {
        return this._performRequest("delete", `resources/users/${ userId }`)
    }

    listRoles(paginatedRequest: IAPIPaginatedRequest, idOrganization?: string, token?: CancelToken): AxiosPromise<IAPIRole[]> {
        return this._performRequest("get", "resources/roles", { ...paginatedRequest, ido: idOrganization }, token)
    }

    getRole(idRole: string): AxiosPromise<IAPIRole> {
        return this._performRequest("get", `resources/roles/${ idRole }`)
    }

    createRole(data: IAPICreateRole): AxiosPromise<IAPIRole> {
        return this._performRequest("post", "resources/roles", data)
    }

    updateRole(idRole: string, data: IAPIRole): AxiosPromise<IAPIRole> {
        return this._performRequest("put", `resources/roles/${ idRole }`, data)
    }

    updateRolePermissions(idRole: string, data: IAPIPermission[]): AxiosPromise<IAPIPermission[]> {
        return this._performRequest("put", `resources/roles/${ idRole }/permissions`, data)
    }

    deleteRole(idRole: string) {
        return this._performRequest("delete", `resources/roles/${ idRole }`)
    }

    listInvitations(paginatedRequest: IAPIPaginatedRequest, token: CancelToken): AxiosPromise<IAPIInvitation[]> {
        return this._performRequest("get", "resources/invitations", paginatedRequest, token)
    }

    inviteUser(data: IAPICreateUserRequest): AxiosPromise<IAPIInvitation> {
        return this._performRequest("post", "resources/invitations/user", data)
    }

    inviteOrganization(data: IAPICreateOrganizationRequest): AxiosPromise<IAPIOrganization> {
        return this._performRequest("post", "resources/invitations/organization", data)
    }

    getInvitation(idInvitation: string): AxiosPromise<IAPIInvitation> {
        return this._performRequest("get", `resources/invitations/${ idInvitation }`)
    }

    deleteInvitation(idInvitation: string): AxiosPromise<void> {
        return this._performRequest("delete", `resources/invitations/${ idInvitation }`)
    }

    listServiceAccounts(paginatedRequest: IAPIPaginatedRequest, token?: CancelToken): AxiosPromise<IAPIServiceAccount[]> {
        return this._performRequest("get", "resources/serviceaccounts", paginatedRequest, token)
    }

    createServiceAccount(data: IAPICreateServiceRequest): AxiosPromise<IAPIServiceAccount> {
        return this._performRequest("post", "resources/serviceaccounts", data)
    }

    updateServiceAccountRoles(userId: string, data: IAPIRole[]): AxiosPromise<IAPIServiceAccount> {
        return this._performRequest("put", `resources/users/${ userId }/roles`)
    }

    updateServiceAccountVisibilityTags(userId: string, data: string[]): AxiosPromise<IAPIServiceAccount> {
        return this._performRequest("put", `resources/users/${ userId }/visibilitytags`)
    }

    getServiceAccount(idServiceAccount: string): AxiosPromise<IAPIServiceAccount> {
        return this._performRequest("get", `resources/serviceaccounts/${ idServiceAccount }`)
    }

    getServiceAccountSecret(idUserAccount: string, password: string, idServiceAccount: string) {
        return this._performRequest("post", `resources/serviceaccounts/${ idServiceAccount }/client-secret`, {
            idUserAccount,
            password
        })
    }

    deleteServiceAccount(serviceAccountId: string): AxiosPromise<void> {
        return this._performRequest("delete", `resources/serviceaccounts/${ serviceAccountId }`)
    }

    toggleServiceAccountStatus(idServiceAccount: string, newStatus: "ACTIVE" | "INACTIVE"): AxiosPromise<void> {
        return this._performRequest("put", `resources/serviceaccounts/${ idServiceAccount }/status`, { status: newStatus })
    }

    newPassword(password: string): AxiosPromise<void> {
        return this._performRequest("post", "users/new-password", { password })
    }

    getMe() {
        return this._performRequest("get", "users/me")
    }

    refreshToken(): AxiosPromise<IAPIExchangeCodeResponse> {
        return this._performRequest("post", "oauth/rest/token:refresh", {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
        })
    }

    getAdminPermissions(): AxiosPromise<IAPIPermission[]> {
        return this._performRequest("get", `resources/permissions`, {all: true})
    }

    getOrganizationPermissions(idOrganization: string, paginatedRequest: IAPIPaginatedRequest): AxiosPromise<IAPIPermission[]> {
        return this._performRequest("get", `resources/organizations/${ idOrganization }/permissions`)
    }

    checkUserPermissions(permissionEndpointUri: string): AxiosPromise<IAPIPermissions>  {
        return this._performRequest("get", permissionEndpointUri.replace(process.env.REACT_APP_API_ENDPOINT || '', ''));
    }

    checkToken() {
        return this._performRequest("post", "oauth/rest/token:check", {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
        })
    }

    setJwt(jwt: string) {
        client.defaults.headers.common["Authorization"] = "Bearer " + jwt
    }

    _performRequest(
        method: Method,
        url: string,
        params?: object | string,
        token?: CancelToken,
        headers?: any
    ): AxiosPromise {
        const body = method === "get" ? "params" : "data"
        const requestConfig = {
            method,
            url,
            [body]: params || {},
            headers: {
                ...headers
            },
            paramsSerializer: (params: any) => {
                return qs.stringify(params, { arrayFormat: "comma" , encode: false})
            },
            cancelToken: token
        }

        return client.request(requestConfig)
    }
}

export default new ApiService()