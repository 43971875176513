import React, { FunctionComponent } from "react"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { Typography } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        marginTop: '16px',
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'center',
            marginTop: '0px',
        }
    },
    columnCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));


type NoMatchContentProps = {
    img: string
    title: string
}

const NoMatchContent: FunctionComponent<NoMatchContentProps> = (props) => {
    const classes = useStyles();

    return (
        <Container className={clsx(classes.root, classes.columnCenter)} component="main">
            <img alt="Not Found" width="350" src={require('../assets/'+props.img)} />
            <Box mt={2}>
                <Typography component="h1" variant="h5">
                    {props.title}
                </Typography>
            </Box>

        </Container >
    );
}

type NoMatchProps = {
}

export const NoMatch: FunctionComponent<NoMatchProps> = (props) => {
    const notFoundPages = [
        { img: "not_found.svg", title: "Page not found" },
        { img: "not_found_search.svg", title: "It seems we can't find what you are looking for" },
        { img: "not_found_taken.svg", title: "It seems the page, you are looking for, has been taken" },
    ]

    const classes = useStyles();

    const randomize = () => {
        return notFoundPages[Math.floor(Math.random() * notFoundPages.length)];
    }

    return (
        <Container className={clsx(classes.root, classes.columnCenter)} component="main">
            <NoMatchContent {...randomize()} />
        </Container >
    );
}