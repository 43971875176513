import React, { FunctionComponent } from "react"
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
import NoDataImg from "../assets/no_data.svg"
import ServerErrorImg from "../assets/server_error.svg"

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        maxWidth: 300,
        textAlign: "left"
    },
    media: {
        height: 200
    }
}))

type FeedbackCardProps = {
    type: "empty" | "error"
    title?: string
    callToAction?: string
}

export const FeedbackCard: FunctionComponent<FeedbackCardProps> = (props) => {
    const classes = useStyles()

    return (
        <Card className={ classes.card }>
            <CardMedia
                component="img"
                className={ classes.media }
                image={ props.type === "empty" ? NoDataImg : ServerErrorImg }
                title={ props.type === "empty" ? "No Data" : "Server Error" }
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    { props.title }
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    { props.callToAction }
                </Typography>
            </CardContent>
        </Card>
    )
}