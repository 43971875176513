import React, { FunctionComponent, useEffect, useState } from "react"
import PermissionsIcon from "@material-ui/icons/LockOpenOutlined"
import GeneralInfoIcon from "@material-ui/icons/AssignmentOutlined"
import ReviewIcon from "@material-ui/icons/AssignmentTurnedInOutlined"
import { Grid, Slide, TextField, Typography } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { useIntl } from "react-intl"
import { FormWizardDialog } from "../components/FormWizardDialog"
import { slugify, validateEmail } from "../utils"
import { enqueueSnackBar, startTutorial } from "../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { inviteOrgSteps } from "../tutorials"
import apiService from "../api"
import { IStoreState } from "../store/types"
import { IAPIApplication, IApplicationInvite } from "../api/types"
import { IInviteOrganizationValues, IOrganizationApp, IRole } from "./types"
import { findRoleOptions, findTagOptions, useFormStyles } from "./utils"
import { AutocompleteText } from "../components/AutocompleteText"
import { OrgPermissionsStep } from "./OrgPermissionsStep"
import { NOVAFUTUR } from "../utils/identityProviders"

export const useOrganizationFormRegister = (register: any, unregister: any, intl: any) => {
    useEffect(() => {
        register({ name: "emails" }, {
            validate: (value: string[]) => (value && value.length > 0) || intl({ id: "forms.errors.required" })
        })
        register({ name: "name" }, {
            required: intl({ id: "forms.errors.required" })
        })
        register({ name: "description" }, {})
        register({ name: "applications" }, {
            validate: (value: IOrganizationApp[]) => (value && value.length > 0) || intl({ id: "forms.errors.required" })
        })
        register({ name: "visibilityTags" }, {})
        register({ name: "roles" }, {
            validate: (value: IRole[]) => (value && value.length > 0) || intl({ id: "forms.errors.required" })
        })
        return () => unregister(["emails", "name", "applications", "visibilityTags", "roles"])
    }, [])

    return true
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ ref } { ...props } />
})

export type OrganizationsFormProps = {
    open: boolean
    onClose: (done: boolean) => void
}

export const InviteOrganizationsForm: FunctionComponent<OrganizationsFormProps> = (props) => {
    const dispatch = useDispatch()
    const apps = useSelector<IStoreState, IAPIApplication[]>(state => state.apps || [])

    useEffect(() => {
        setTimeout(() => {
            const elements = document.querySelectorAll("#___reactour button")
            elements.forEach((el: any) => (el.tabIndex = -1))
        }, 100)
    })

    const { handleSubmit, triggerValidation, register, unregister, errors, setValue, watch } = useForm<IInviteOrganizationValues>({
        defaultValues: {
            applications: apps.map(app => { return { ...app, available: false }}),
            roles: [],
            visibilityTags: []
        }
    })

    const fields = watch({ nest: true })
    const [tagOptions, setTagOptions] = useState(findTagOptions(fields.applications))
    const [roleOptions, setRoleOptions] = useState(findRoleOptions(fields.applications))
    const [loading, setLoading] = useState(false)
    const classes = useFormStyles()
    const { formatMessage: intl } = useIntl()

    const handleNewOrgAppsChange = (index: number) => () => {
        let availableApps = fields.applications
        availableApps[index] = { ...fields.applications[index], available: !availableApps[index].available }
        setValue("applications", availableApps)
        setValue("roles", fields.roles.filter(role => availableApps.findIndex(app => app.available && app.idApplication === role.idApplication) > -1))
        setValue("visibilityTags", fields.visibilityTags.filter(tag => availableApps.findIndex(app => app.available && app.idApplication === tag.idApplication) > -1))
        setTagOptions(findTagOptions(availableApps))
        setRoleOptions(findRoleOptions(availableApps))
    }

    const [activeStep, setActiveStep] = React.useState(0)

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = async () => {
        let result
        switch (activeStep) {
            case 0:
                await triggerValidation(["name", "emails", "description"])
                result = errors.name || errors.emails || errors.description
                break
            case 1:
                await triggerValidation(["roles", "applications", "visibilityTags"])
                result = errors.roles || errors.applications || errors.visibilityTags
                break
        }
        if (!result) setActiveStep(activeStep + 1)
    }

    const handleReset = () => {
        reset()
        setActiveStep(0)
    }

    const reset = () => {
        setValue("name", "")
        setValue("emails", [])
        setValue("description", "")
        setValue("applications", apps.map(app => { return { ...app, available: false }}))
        setValue("roles", [])
        setValue("visibilityTags", [])
    }

    useOrganizationFormRegister(register, unregister, intl)
    const onSubmit = async (data: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault()
        if (!loading) {
            setLoading(true)
            try {
                const { data } = await apiService.inviteOrganization({
                    organization: {
                        name: fields.name,
                        alias: slugify(fields.name),
                        description: fields.description
                    },
                    emailTarget: fields.emails,
                    applications: fields.applications.reduce<IApplicationInvite[]>((acc, app) => {
                        if (app.available) acc.push({
                            idApplication: app.idApplication,
                            idRoles: fields.roles.filter(role => role.idApplication === app.idApplication).map(role => role.idRole),
                            visibilityTags: fields.visibilityTags.filter(tag => tag.idApplication === app.idApplication).map(tag => tag.tag)
                        })
                        return acc
                    }, []),
                    identityProvider: NOVAFUTUR,
                    callbackUri: process.env.REACT_APP_REDIRECT_URI || ""
                })
                reset()
                dispatch(enqueueSnackBar({
                    key: data.idOrganization || "", message: "Data Correctly Saved!", options: {
                        preventDuplicate: false, variant: "success"
                    }
                }))
                props.onClose(true)
            } catch (e) {
                console.log("exception: ", e)
            }
        }
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={ 2 } className={ classes.stepContainer }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>
                                { intl({ id: "organizations.forms.first.description" }) }
                            </Typography>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                            <TextField
                                data-tut="organizations.invite.name"
                                color="primary"
                                variant="outlined"
                                label={ intl({ id: "organizations.forms.name" }) }
                                size="small"
                                required
                                placeholder={ intl({ id: "organizations.forms.name" }) }
                                fullWidth
                                name="name"
                                value={ fields.name || "" }
                                onChange={ event => {setValue("name", event.target.value, true)} }
                                error={ !!errors.name }
                                helperText={ (!!errors.name && (errors.name as any).message) || " " }
                            />
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                            <AutocompleteText
                                value={ fields.emails || [] }
                                onChange={ (e, value) => {
                                    if (value.length > 0 && !validateEmail(value[value?.length - 1])) {
                                        value.pop()
                                    } else {
                                        setValue("emails", value, true)
                                    }
                                    e.preventDefault()
                                } }
                                label="Emails (press Enter after each email)"
                                placeholder="Email"
                                name="emails"
                                error={ errors.emails }
                            />
                        </Grid>
                        <Grid item xs={ 12 }>
                            <TextField
                                data-tut="organizations.invite.description"
                                color="primary"
                                variant="outlined"
                                label={ intl({ id: "organizations.forms.description" }) }
                                size="small"
                                placeholder={ intl({ id: "organizations.forms.description" }) }
                                fullWidth
                                name="description"
                                value={ fields.description || "" }
                                onChange={ event => {setValue("description", event.target.value)} }
                                error={ !!errors.description }
                                helperText={ (!!errors.description && (errors.description as any).message) || " " }
                            />
                        </Grid>
                    </Grid>
                )
            case 1:
                return <OrgPermissionsStep onChange={ (field, value, validate) => setValue(field, value, validate) }
                                               fields={ fields } errors={ errors }
                                               handleNewOrgAppsChange={ handleNewOrgAppsChange }
                                               tagOptions={ tagOptions }
                                               roleOptions={ roleOptions }/>
            case 2:
                return (
                    <Grid container className={ classes.stepContainer } justify="center" spacing={ 2 }>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">You are going to invite the
                                organization: <strong>{ fields.name }</strong></Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">With the following admins:</Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6"><strong>{ fields.emails?.join(",") }</strong></Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">with the following roles: </Typography>
                        </Grid>
                        {
                            fields.roles.map(role => (
                                <Grid item xs={ 12 } key={ role.idRole }>
                                    <Typography variant="h6">
                                        <strong>{ role.name }</strong>
                                    </Typography>
                                </Grid>
                            ))
                        }
                        {
                            fields.visibilityTags && fields.visibilityTags.length > 0 &&
                            (<Grid item xs={ 12 }>
                                <Typography variant="h6">and the following visibility tags:</Typography>
                            </Grid>)
                        }
                        {
                            fields.visibilityTags && fields.visibilityTags.length > 0 && fields.visibilityTags.map(tag => (
                                <Grid item xs={ 12 } key={ tag.id }>
                                    <Typography variant="h6">
                                        <strong>{ tag.appName + ": " + tag.tag }</strong>
                                    </Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                )
            default:
                return "Unknown step"
        }
    }

    return (
        <>
            <FormWizardDialog Transition={ Transition }
                              title="Invite Organization"
                              open={ props.open }
                              fullscreen={ true }
                              onDialogClose={ () => {
                                  reset()
                                  props.onClose(false)
                              } }
                              onDialogCancel={ () => {
                                  reset()
                                  props.onClose(false)
                              } }
                              handleSubmit={ handleSubmit(onSubmit) }
                              steps={ [{ label: "Organization Admin", icon: <GeneralInfoIcon/> }, {
                                  label: "Applications, Roles & Visibility Tags", icon: <PermissionsIcon/>
                              }, { label: "Review", icon: <ReviewIcon/> }] }
                              activeStep={ activeStep }
                              hasTutorial={ activeStep <= 1 }
                              onOpenTutorial={ () => dispatch(startTutorial(inviteOrgSteps(intl, activeStep))) }
                              getStepContent={ getStepContent }
                              loading={ loading }
                              handleBack={ handleBack }
                              handleNext={ handleNext }
                              handleReset={ handleReset }>
            </FormWizardDialog>
        </>
    )
}