import { Avatar, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Divider, Typography } from "@material-ui/core"
import { formatDate, stringToColor } from "../utils"
import React, { FunctionComponent } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { IAPIOrganization } from "../api/types"
import { useHistory } from "react-router-dom"
import OrgDefaultImg from "../assets/office.svg"

const useStyles = makeStyles((theme: Theme) => ({
    orgCard: {
        minWidth: 275,
        textAlign: "left"
    },
    cardContent: {
        padding: theme.spacing(2),
        height: 200
    },
    cardText: {
        textAlign: "left"
    },
    cardMainContent: {
        alignItems: "center"
    },
    orgsCardActions: {
        padding: theme.spacing(1),
        justifyContent: "flex-end"
    },
    orgsPeopleNumAvatar: {
        fontSize: "14px",
        color: "#fff"
    },
    media: {
        height: 150,
        objectFit: "contain"
    }
}))

type OrgCardProps = {
    org: IAPIOrganization
    onDetails: (id: string) => void
}

export const OrgCard: FunctionComponent<OrgCardProps> = (props) => {
    const classes = useStyles()
    const { org } = props
    let history = useHistory()

    return (
        <Card className={ classes.orgCard } variant="outlined" data-tut={ "organizations.card" }>
            <CardActionArea onClick={ () => history.push(`/organizations/${ org.idOrganization }`) }>
                <CardHeader
                    className={ classes.cardMainContent }
                    data-tut={ "organizations.header" }
                    avatar={
                        <Avatar aria-label="recipe"
                                style={ { backgroundColor: stringToColor(org.name), color: "#fff" } }>
                            { org.name.charAt(0) }
                        </Avatar>
                    }
                    title={ org.name }
                    subheader={ formatDate(org.created || "") }
                />
                <CardMedia
                    className={ classes.media }
                    component="img"
                    onError={ (e: any) => e.target.src = OrgDefaultImg }
                    src={ org.imgUrl || OrgDefaultImg }
                    title={ org.name }
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        { org.description }
                    </Typography>
                </CardContent>
                <Divider/>
                <CardActions className={ classes.orgsCardActions } disableSpacing>
                    <Typography align="right"
                                style={ { color: org.status === "ACTIVE" ? "green" : "orange", fontWeight: "bold" } }>{ org.status }</Typography>
                </CardActions>
            </CardActionArea>
        </Card>)
}