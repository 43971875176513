import { useScrollTrigger } from "@material-ui/core"
import React from "react"

export default function ElevationScroll(props: { children: any; }) {
    const { children } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    })

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0
    })
}