import { applyMiddleware, createStore, StoreEnhancer } from "redux"
import { reducers } from "./reducers"
import { ActionTypes } from "./actions"
import { IStoreState } from "./types"
import thunk from "redux-thunk"
import { authInterceptor, jwtRefresher } from "./jwt"
import { composeWithDevTools } from "redux-devtools-extension"

//Typescript bug with createStore
export default createStore<IStoreState, ActionTypes, StoreEnhancer<any>, null>(reducers as any, {
    loading: false,
    settings: {
        locale: "en",
        preferredVisualization: "cards"
    },
    service: {
        id: "auth",
        name: "Nova Auth"
    },
    client: {
        clientId: process.env.REACT_APP_CLIENT_ID || "",
        clientSecret: process.env.REACT_APP_CLIENT_SECRET || ""
    },
    /*user: {
        created: "", email: "", emailVerified: false, firstName: "", idUserAccount: "", imgUrl: "", lastName: "", mobile: "", mobileVerified: false, status: "ACTIVE"
    },*/
    refreshingToken: false,
    theme: "light",
    tutorial: {
        open: false,
        steps: []
    },
    notifications: []
}, composeWithDevTools(applyMiddleware(jwtRefresher, authInterceptor, thunk)) as any)