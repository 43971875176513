import React, { FunctionComponent, useEffect, useState } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { useParams, useHistory } from "react-router-dom"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from "@material-ui/core"
import { enqueueSnackBar, setLoading, startTutorial } from "../store/actions"
import { appViewerSteps } from "../tutorials"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { DataTable } from "../components/DataTable"
import { IAPIApplication, IAPIOrganization, IAPIOrganizationApp, IAPIRole, IAPIServiceAccount, IAPIUser } from "../api/types"
import apiService from "../api"
import { IStoreState } from "../store/types"
import { checkPermissions } from "../utils/acl"
import { LoadingButton } from "../components/LoadingButton"
import { ToggleStatusButton } from "../components/ToggleStatusButton"
import { FormDialog } from "../components/FormDialog"
import { IInviteOrganizationValues, IRole, ITag } from "../forms/types"
import { findRoleOptions, findTagOptions } from "../forms/utils"
import { useForm } from "react-hook-form"
import { OrgPermissionsStep } from "../forms/OrgPermissionsStep"
import { useOrganizationFormRegister } from "../forms/InviteOrganizationsForm"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    mb: {
        marginBottom: theme.spacing(3)
    },
    caption: {},
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}))

const appsColumns = [
    { name: "name", title: "Name" },
    { name: "status", title: "Status" }
]

const AppsRowDetail = ({ row }: { row: IAPIApplication }) => {
    return row.description && (<Typography style={ { fontSize: "0.875rem" } }>
        <strong>Description: </strong> { row.description }
    </Typography>)
}


const rolesColumns = (org?: IAPIOrganization) => [
    {
        name: "idApplication", title: "Application", getCellValue: (row: IAPIRole) => {
            return row.idApplication && org ? org.applications.find(app => app.idApplication === row.idApplication)?.name : "n/a"
        }
    },
    { name: "name", title: "Name" },
    { name: "status", title: "Status" }
]

const RolesRowDetail = ({ row }: { row: IAPIRole }) => (
    <Typography style={ { fontSize: "0.875rem" } }>
        <strong>Description: </strong> { row.description }
    </Typography>
)

const usersColumns = [
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "email", title: "email" },
    { name: "status", title: "Status" }
]

const servicesColumns = [
    { name: "name", title: "Name" },
    { name: "clientId", title: "clientId" },
    {
        name: "frontend", title: "Frontend", getCellValue: (row: IAPIServiceAccount) => {
            return row.frontend ? "YES" : "NO"
        }
    }
]

type EditResources = "info" | "apps"

type OrganizationDetailsProps = {}

export const OrganizationDetails: FunctionComponent<OrganizationDetailsProps> = () => {
    const apps = useSelector<IStoreState, IAPIApplication[]>(state => state.apps || [])
    const classes = useStyles()
    const { id } = useParams()
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const canChangeOrgStatus = checkPermissions("changeOrganizationStatus", "function", permissions || [])
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    const [org, setOrg] = useState<IAPIOrganization | undefined>()
    const [openForm, setOpenForm] = useState(false)
    const [activeEdit, setActiveEdit] = useState<EditResources>("info")
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))
    const [openDialog, setOpenDialog] = useState(false)
    const [internalLoading, setInternalLoading] = useState(false)

    let history = useHistory()

    const [openUserDialog, setOpenUserDialog] = useState(false)
    const [user, setUser] = useState<IAPIUser | undefined>()

    const enableDisableOrg = async () => {
        try {
            setInternalLoading(true)
            const newStatus = org?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
            await apiService.toggleOrganizationStatus(org?.idOrganization || "", newStatus)
            if (org) setOrg({ ...org, status: newStatus })
        } catch {
            dispatch(enqueueSnackBar(
                { key: org?.idOrganization || "", message: "Impossible to change Organization's status", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        handleClose()
    }

    const removeUser = async () => {
        try {
            setInternalLoading(true)
            await apiService.removeUserOrganization(org?.idOrganization || "", user?.idUserAccount || "")
            dispatch(enqueueSnackBar(
                { key: org?.idOrganization || "", message: "User successfully removed!", options: { persist: false, preventDuplicate: false, variant: "success" } }
            ))
            if (org) org.userAccounts = org.userAccounts?.filter(account => account.idUserAccount !== user?.idUserAccount)
        } catch {
            dispatch(enqueueSnackBar(
                { key: org?.idOrganization || "", message: "Impossible to remove User from Organization", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        handleUserClose()
    }

    const handleClose = () => {
        setInternalLoading(false)
        setOpenDialog(false)
    }

    const handleUserClose = () => {
        setInternalLoading(false)
        setOpenUserDialog(false)
    }

    const loadOrganization = () => {
        if (id) {
            dispatch(setLoading(true))
            apiService.getOrganization(id, true).then((response) => {
                setOrg(response.data)
            }).catch(ex => console.log(ex)).finally(() => dispatch(setLoading(false)))
        }
    }

    useEffect(() => {
        loadOrganization()
    }, [])

    const organizationApps = apps.map(app => { return { ...app, available: (org?.applications && org?.applications.findIndex(application => application.idApplication === app.idApplication) > -1) || false }})

    const { triggerValidation, register, unregister, errors, setValue, watch } = useForm<IInviteOrganizationValues>({
        defaultValues: {
            applications: organizationApps,
            roles: org?.roles,
        }
    })
    const fields = watch({ nest: true })

    const selectActiveEdit = (resource: EditResources) => {
        setActiveEdit(resource)
        setOpenForm(true)
    }

    const findAppName = (id: string | undefined) => (apps.find(app => app.idApplication === id))?.name || ""

    const resetFields = () => {
        setValue("name", org?.name || "")
        setValue("description", org?.description)
        setValue("applications", organizationApps)
        setValue("roles", org?.roles.filter(role => role.idApplication).map(role => { return { ...role, idApplication: role.idApplication || "", app: findAppName(role.idApplication) }}) || [])
        setValue("visibilityTags",[])
        setTagOptions(findTagOptions(organizationApps))
        setRoleOptions(findRoleOptions(organizationApps))
    }

    useEffect(() => {
        resetFields()
    }, [org])

    const [tagOptions, setTagOptions] = useState<ITag[]>([])
    const [roleOptions, setRoleOptions] = useState<IRole[]>([])
    useOrganizationFormRegister(register, unregister, intl)

    const handleNewOrgAppsChange = (index: number) => () => {
        let availableApps = fields.applications
        availableApps[index] = { ...fields.applications[index], available: !availableApps[index].available }
        setValue("applications", availableApps)
        setValue("roles", fields.roles.filter(role => availableApps.findIndex(app => app.available && app.idApplication === role.idApplication) > -1))
        setValue("visibilityTags", fields.visibilityTags.filter(tag => availableApps.findIndex(app => app.available && app.idApplication === tag.idApplication) > -1))
        setTagOptions(findTagOptions(availableApps))
        setRoleOptions(findRoleOptions(availableApps))
    }

    const getFormContent = (resource: EditResources) => {
        switch (resource) {
            case "apps":
                return <OrgPermissionsStep handleNewOrgAppsChange={ handleNewOrgAppsChange }
                                               tagOptions={ tagOptions }
                                               roleOptions={ roleOptions } fields={ fields }
                                               errors={ errors }
                                               onChange={ (field, value, validate) => setValue(field, value, validate) }/>
            case "info":
                return <></>
        }
    }

    const getFormTitle = (resource: EditResources) => {
        switch (resource) {
            case "apps":
                return "Edit Applications"
            default:
                return "Edit General Info"
        }
    }

    const handleSubmit = async () => {
        try {
            setInternalLoading(true)
            let validation = true
            switch (activeEdit) {
                case "apps":
                    await triggerValidation(["roles", "applications", "visibilityTags"])
                    if (!errors.roles && !errors.applications && !errors.visibilityTags) {
                        await apiService.patchOrganizationPermissions(org?.idOrganization || "", {
                            roles: fields.roles.map(role => role.idRole),
                            visibilityTags: fields.visibilityTags.map(tag => tag.tag)
                        })
                    } else validation = false
                    break
                case "info":
                    await triggerValidation(["name", "description"])
                    if (!errors.name && !errors.description) {
                        await apiService.patchOrganizationInfo(org?.idOrganization || "", {
                            name: fields.name,
                            description: fields.description || ""
                        })
                    } else validation = false
                    break
            }
            if (validation) {
                setOpenForm(false)
                resetFields()
                dispatch(enqueueSnackBar(
                    { key: org?.idOrganization || "", message: intl({ id: "messages.general.updateSuccessful" }), options: { persist: false, preventDuplicate: false, variant: "success" } }
                ))
            }
        } catch {
            dispatch(enqueueSnackBar(
                { key: org?.idOrganization || "", message: intl({ id: "messages.general.updateFailed" }), options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        setInternalLoading(false)
    }

    return (
        <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
            { loading ? (<Typography>Loading data...</Typography>) : (<>
                <Typography className={ classes.mt } variant="h4">{ org?.name } </Typography>
                <Grid container justify="center" className={ classes.mt }>
                    <Grid item>
                        { canChangeOrgStatus && (
                            <ToggleStatusButton style={ { marginLeft: 10 } } data-tut="organizations.toggleStatus"
                                                action={ org?.status === "ACTIVE" ? "disable" : "enable" }
                                                onClick={ () => setOpenDialog(true) }>{ org?.status === "ACTIVE" ? "Disable" : "Enable" }</ToggleStatusButton>) }
                        <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                                onClick={ () => dispatch(startTutorial(appViewerSteps(intl))) }>{ intl({ id: "functions.help" }) }</Button>
                    </Grid>
                </Grid>
                <Typography variant="subtitle1" className={ classes.mt }>{ org?.description }</Typography>
                <DataTable<IAPIOrganizationApp> columns={ appsColumns }
                                                rows={ org?.applications }
                                                title="Applications"
                                                idField="idApplication"
                                                pageSize={ 10 }
                                                loading={ loading }
                                                tableActions={
                                                    <Button variant="contained" color="primary" size="small"
                                                            onClick={ () => selectActiveEdit("apps") }>
                                                        { intl({ id: "functions.edit" }) }
                                                    </Button> }
                                                showDetailsButton
                                                onDetailRow={ (app) => history.push(`/applications/${ app.idApplication }`) }
                                                totalCount={ org?.applications.length }
                                                detailComponent={ AppsRowDetail }
                                                defaultSorting={ [{ columnName: "name", direction: "asc" }] }/>
                <br/>
                <DataTable<IAPIRole> columns={ rolesColumns(org) }
                                     rows={ org?.roles }
                                     title="Roles"
                                     pageSize={ 10 }
                                     idField="idRole"
                                     loading={ loading }
                                     totalCount={ org?.roles.length }
                                     detailComponent={ RolesRowDetail }
                                     showDetailsButton
                                     onDetailRow={ (role) => history.push(`/roles/${ role.idRole }`) }
                                     defaultSorting={ [{ columnName: "idApplication", direction: "asc" }] }/>
                <br/>
                <DataTable<IAPIUser> columns={ usersColumns }
                                     rows={ org?.userAccounts }
                                     title="Users"
                                     pageSize={ 10 }
                                     idField="idUserAccount"
                                     loading={ loading }
                                     totalCount={ org?.userAccounts?.length }
                                     showDetailsButton
                                     onDetailRow={ (user) => history.push(`/users/${ user.idUserAccount }`) }
                                     showDeleteButton
                                     onDeleteRow={ (row) => {
                                         setUser(row)
                                         setOpenUserDialog(true)
                                     } }
                                     defaultSorting={ [{ columnName: "email", direction: "asc" }] }/>
                <br/>
                <DataTable<IAPIServiceAccount> columns={ servicesColumns }
                                               rows={ org?.serviceAccounts }
                                               title="Services"
                                               idField="idServiceAccount"
                                               pageSize={ 10 }
                                               loading={ loading }
                                               totalCount={ org?.serviceAccounts?.length }
                                               showDetailsButton
                                               onDetailRow={ (service) => history.push(`/services/${ service.idServiceAccount }`) }
                                               defaultSorting={ [{ columnName: "name", direction: "asc" }] }/></>) }
            <Dialog
                fullScreen={ fullScreen }
                open={ openDialog }
                onClose={ handleClose }
                aria-labelledby="enable-disable-org-dialog-title"
            >
                <DialogTitle id="enable-disable-org-dialog-title">Do you want
                    to { org?.status === "ACTIVE" ? "disable" : "enable" } { org?.name }?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that the
                        organization { org?.status === "ACTIVE" ? "won't" : "will" } be able
                        to authenticate with your services.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleClose }>
                        Cancel
                    </Button>
                    <LoadingButton type="button" onClick={ enableDisableOrg } loading={ internalLoading }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen={ fullScreen }
                open={ openUserDialog }
                onClose={ handleUserClose }
                aria-labelledby="remove-user-dialog-title"
            >
                <DialogTitle id="remove-user-dialog-title">
                    Do you want to delete { user?.firstName } { user?.lastName } from { org?.name }?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that the
                        user won't be able
                        to login with { org?.name }.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleUserClose }>
                        Cancel
                    </Button>
                    <LoadingButton type="button" onClick={ removeUser } loading={ internalLoading }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <FormDialog fullscreen={ activeEdit !== "info" }
                        onDialogCancel={ () => {
                            resetFields()
                            setOpenForm(false)
                        } }
                        open={ openForm }
                        handleSubmit={ handleSubmit }
                        onDialogClose={ () => {
                            resetFields()
                            setOpenForm(false)
                        } }
                        loading={ internalLoading }
                        title={ getFormTitle(activeEdit) }>
                { getFormContent(activeEdit) }
            </FormDialog>
        </Container>
    )
}