import axios from "axios"
import { IAPIError } from "./types"

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const getCookies = (): { [id: string]: any } =>
    document.cookie.split(";").reduce((cookies: { [id: string]: any }, item) => {
        const [name, value] = item.split("=")
        cookies[name] = value
        return cookies
    }, {})

const ajax = axios.create({
    baseURL,
    timeout: 40000,
    //withCredentials: true
})

// Add a request interceptor
ajax.interceptors.request.use(function (config) {
    const xsrfToken = getCookies()["xsrfToken"]
    // CSRF Token.
    if (xsrfToken) config.headers["X-XSRF-TOKEN"] = xsrfToken
    return config
})

// Add a response interceptor
ajax.interceptors.response.use((response) => {
    return response
}, function (error) {
    // Do something with response error
    if (axios.isCancel(error)) {
        return Promise.reject({
            statusCode: 0,
            message: "Canceled By user"
        } as IAPIError)
    } else if (error.response && error.response.status === 401) {
        console.log("needs to logout")

        //console.log("unauthorized, logging out ...")
        return Promise.reject({
            statusCode: error.response.status
        } as IAPIError)
    } else if (error.response && error.response.data) {
        return Promise.reject({
            statusCode: error.response.status,
            ...error.response.data.error
        } as IAPIError)
    } else return Promise.reject({
        statusCode: -1
    } as IAPIError)
})

export default ajax