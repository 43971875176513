import React, { FunctionComponent, useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { Button, Grid, Typography } from "@material-ui/core"
import { homeCards } from "../utils/config"
import { useIntl } from "react-intl"
import { ApplicationsForm } from "../forms/ApplicationsForm"
import { setLoading, startTutorial } from "../store/actions"
import { appViewerSteps } from "../tutorials"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { AppCard } from "../components/AppCard"
import { IStoreState } from "../store/types"
import apiService from "../api"
import axios, { CancelTokenSource } from "axios"
import { FeedbackCard } from "../components/FeedbackCard"
import { IAPIApplication } from "../api/types"
import { checkPermissions } from "../utils/acl"

const CancelToken = axios.CancelToken

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    profileCard: {
        width: "100%"
    },
    profileList: {
        textAlign: "left"
    },
    avatarContainer: {
        paddingBottom: theme.spacing(2)
    },
    fieldHeader: {
        color: "#404040",
        flexGrow: 0,
        flexBasis: 156,
        paddingRight: 10
    },
    deleteIcon: {
        padding: 8
    },
    grow: {
        flexGrow: 1
    },
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    hidden: {
        display: "none"
    },
    buttonLink: {
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: 600
    },
    mb: {
        marginBottom: theme.spacing(3)
    },
    formControl: {
        margin: theme.spacing(0)
    },
    featuresContainer: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    }
}))

type ApplicationsProps = {}

export const Applications: FunctionComponent<ApplicationsProps> = () => {
    const classes = useStyles()
    const [openForm, setOpenForm] = useState(false)
    const [apps, setApps] = useState<IAPIApplication[]>([])
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    let history = useHistory()
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const [error, setError] = useState()
    const source = CancelToken.source()

    async function listApplications(source: CancelTokenSource) {
        try {
            setError(false)
            dispatch(setLoading(true))
            const result = await apiService.listApplications({ page: 0, perPage: 0 }, source.token)
            setApps(result.data)
        } catch (e) {
            //if (e.statusCode !== 0) setError(true)
        }
        dispatch(setLoading(false))
    }

    useEffect(() => {
        listApplications(source)
        return () => {
            source.cancel("Operation Canceled By User")
            dispatch(setLoading(false))
        }
    }, [])

    const handleOnDetails = (id?: string) => {
        history.push("/applications/" + id)
    }

    const renderAppList = () => {
        if (loading) {
            return null
        } else if (error) {
            return (
                <FeedbackCard title="Ops! We have an error!"
                              callToAction="Try to refresh, if the error persist contact us!" type="error"/>
            )
        } else if (apps.length > 0) {
            return apps.map((app) => (
                <Grid key={ app.idApplication } item md={ 6 } xs={ 12 }>
                    <AppCard app={ app } onDetails={ handleOnDetails }/>
                </Grid>
            ))
        } else if (apps.length === 0) {
            return (
                <FeedbackCard title="No Applications!" callToAction="Create a new application to help your customers!"
                              type="empty"/>
            )
        }
    }

    return (
        <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
            <Typography variant="h4">{ intl({ id: homeCards[2].intlId + ".title" }) }</Typography>
            <Typography variant="subtitle1"
                        className={ classes.caption }>{ intl({ id: homeCards[2].intlId + ".description" }) }</Typography>
            <Grid container justify="center" className={ classes.mb }>
                <Grid item>
                    { checkPermissions("createApplication", "function", permissions || []) && (
                        <Button variant="contained" color="primary"
                                onClick={ () => {
                                    setOpenForm(true)
                                } }>{ intl({ id: "functions.addNew" }) }</Button>) }
                    <Button style={ { marginLeft: 10 } } variant="contained" color="secondary" disabled={ loading }
                            onClick={ () => listApplications(source) }>Refresh</Button>
                    <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                            onClick={ () => dispatch(startTutorial(appViewerSteps(intl))) }>{ intl({ id: "functions.help" }) }</Button>
                </Grid>
            </Grid>
            <Grid container justify="center" spacing={ 3 }>
                { renderAppList() }
            </Grid>
            <ApplicationsForm open={ openForm } onClose={ (done: boolean) => {
                done && listApplications(source)
                setOpenForm(false)
            } }/>
        </Container>
    )
}