import React, { FunctionComponent, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import { Drawer } from "../components/Drawer"
import { drawerWidth, pages } from "../utils/config"
import { AppBar } from "../components/AppBar"
import { Fade, LinearProgress, Toolbar } from "@material-ui/core"
import { Home } from "../pages/Home"
import { Profile } from "../pages/Profile"
import { Applications } from "../pages/Applications"
import { Organizations } from "../pages/Organizations"
import { Users } from "../pages/Users"
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { ServiceAccounts } from "../pages/ServiceAccounts"
import { MaterialTour } from "../components/MaterialTour"
import { stopTutorial } from "../store/actions"
import { ApplicationDetails } from "../pages/ApplicationDetails"
import { OrganizationDetails } from "../pages/OrganizationDetails"
import ScrollToTop from "../components/ScrollToTop"
import { Roles } from "../pages/Roles"
import { Invitations } from "../pages/Invitations"
import { RoleDetails } from "../pages/RoleDetails"
import { UserOrganizationDetails } from "../pages/UserOrganizationDetails"
import { UserDetails } from "../pages/UserDetails"
import { ServiceAccountDetails } from "../pages/ServiceAccountDetails"
import { SignIn } from "../components/SignIn"


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.common.white
    },
    content: {
        flexGrow: 1,
        marginBottom: 40,
        padding: theme.spacing(3, 5, 3, 5),
        [theme.breakpoints.up("md")]: {
            marginLeft: drawerWidth,
            marginTop: theme.spacing(0)
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            paddingTop: 60
        }
    },
    progress: {
        top: 0,
        position: "absolute",
        width: "100%",
        borderRadius: "8px",
        zIndex: 9999
    }
}))

type DashboardContainerProps = {}

export const DashboardContainer: FunctionComponent<DashboardContainerProps> = () => {
    const [fullWidth, setFullWidth] = useState(false)
    const location = useLocation()
    const loading = useSelector((state: IStoreState) => state.loading)
    const user = useSelector((state: IStoreState) => state.user)
    const tutorial = useSelector((state: IStoreState) => state.tutorial)
    let history = useHistory()
    const dispatch = useDispatch()

    const classes = useStyles()

    const onChangeIndex = (newIndex: number) => {
        switch (newIndex) {
            case 0:
                history.push("/")
                break
            case 1:
                history.push("/profile")
                break
            case 2:
                history.push("/organizations")
                break
            case 3:
                history.push("/applications")
                break
            case 4:
                history.push("/roles")
                break
            case 5:
                history.push("/users")
                break
            case 6:
                history.push("/services")
                break
            case 7:
                history.push("/invitations")
                break
            default:
                history.push("/")
        }
        if (newIndex === 4 || newIndex === 5) {
            setFullWidth(true)
        } else {
            setFullWidth(false)
        }
    }

    return !user ? <SignIn/>
        :
        (<>
            <Fade
                in={ loading }
                style={ {
                    transitionDelay: loading ? "50ms" : "0ms"
                } }
            >
                <LinearProgress variant="indeterminate" className={ classes.progress }/>
            </Fade>
            <Container className={ classes.root } component="main"
                       style={ { maxWidth: fullWidth ? "100%" : "1280px" } }>
                <CssBaseline/>
                <AppBar onChangeIndex={ onChangeIndex } intro={ false } location={ location.pathname }/>
                <Drawer onChangeIndex={ onChangeIndex } pages={ pages } intro={ false } location={ location.pathname }/>
                <main className={ classes.content }>
                    <Toolbar/>
                    <ScrollToTop/>
                    <Switch location={ location }>
                        <Route exact path="/">
                            <Home onChangeIndex={ onChangeIndex }/>
                        </Route>
                        <Route path="/profile">
                            <Profile/>
                        </Route>
                        <Route exact path="/organizations">
                            <Organizations/>
                        </Route>
                        <Route exact path="/organizations/:id">
                            <OrganizationDetails/>
                        </Route>
                        <Route exact path="/applications">
                            <Applications/>
                        </Route>
                        <Route path="/applications/:id">
                            <ApplicationDetails/>
                        </Route>
                        <Route exact path="/roles">
                            <Roles/>
                        </Route>
                        <Route path="/roles/:id">
                            <RoleDetails/>
                        </Route>
                        <Route exact path="/users">
                            <Users/>
                        </Route>
                        <Route exact path="/users/:idUser">
                            <UserDetails/>
                        </Route>
                        <Route exact path="/organizations/:idOrganization/users/:idUser">
                            <UserOrganizationDetails/>
                        </Route>
                        <Route exact path="/services">
                            <ServiceAccounts/>
                        </Route>
                        <Route path="/services/:id">
                            <ServiceAccountDetails/>
                        </Route>
                        <Route exact path="/invitations">
                            <Invitations/>
                        </Route>
                    </Switch>
                </main>
            </Container>
            <MaterialTour isOpen={ tutorial.open } steps={ tutorial.steps }
                          onRequestClose={ () => dispatch(stopTutorial()) }/>
        </>)

}