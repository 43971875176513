import { enqueueSnackBar, IApiRequestFailure, IEnqueueSnackbar, ILogout, ISetApplications, ISetLoading, ISetUser, logout, setApiRequestPending, setApplications, setUser } from "./actions"
import apiService from "../api"
import { Dispatch } from "redux"
import jwtDecode from "jwt-decode"
import { IJwtPayload, IStoreState } from "./types"

export function asyncExchangeCode(code: string): (dispatch: Dispatch<ISetUser | ISetApplications | IApiRequestFailure | ISetLoading | IEnqueueSnackbar>, getState: () => IStoreState) => void {
    return async (dispatch, getState) => {
        dispatch(setApiRequestPending(true))
        let idUser = "";
        try {
            const response = await apiService.exchangeCode({ code, grant_type: "authorization_code", client_id: process.env.REACT_APP_CLIENT_ID || "", client_secret: process.env.REACT_APP_CLIENT_SECRET || "", redirect_uri: process.env.REACT_APP_REDIRECT_URI })
            const data = response.data
            const jwt = data.access_token
            apiService.setJwt(jwt)

            if(process.env.REACT_APP_ENV === "dev"){
                localStorage.setItem("jwt", jwt)
            }

            let jwtPayload = jwtDecode<IJwtPayload>(jwt) || ""
            const userResponse = await apiService.getUser(jwtPayload.sub)
            const user = userResponse.data
            idUser = user.idUserAccount
            const perms = await apiService.checkUserPermissions(jwtPayload!.permissions_uri || "")
            jwtPayload = perms.data.permissions ? {...jwtPayload, permissions: perms.data.permissions} : jwtPayload

            const applicationsResponse = await apiService.listApplications({ orderBy: "", page: 0, perPage: 0 })

            const apps = applicationsResponse.data
            dispatch(setApplications(apps))
            dispatch(setUser({ ...user, jwt, jwtPayload }))
 
        } catch (error) {
            apiService.logout(idUser).then(() => {
                dispatch(enqueueSnackBar({
                    key: "refreshToken-error",
                    options: {
                        variant: "error"
                    },
                    message: "You don't have permissions to view this page, please try to re-login or contact: " + process.env.REACT_APP_SUPPORT_EMAIL
                }))
                setTimeout(() => {  
                    window.location.href = process.env.REACT_APP_DEFAULT_REDIRECT || "/";
                 }, 5000);
            })
        }
        dispatch(setApiRequestPending(false))
    }
}

export function asyncLogout(): (dispatch: Dispatch<ILogout | IApiRequestFailure | ISetLoading | IEnqueueSnackbar>, getState: () => IStoreState) => void {
    return async (dispatch, getState) => {
        try {
            const userId = getState().user?.idUserAccount
            if (userId) {
                apiService.logout(userId).then(() => {
                    dispatch(logout())
                })
            }

        } catch (error) {
            if (error.statusCode) {

            } else {

            }
            dispatch(enqueueSnackBar({
                key: "refreshToken-error",
                options: {
                    variant: "error"
                },
                message: "Something very bad happened, cannot get the login URL, please contact us at " + process.env.REACT_APP_SUPPORT_EMAIL
            }))
        }
        dispatch(setApiRequestPending(false))
    }
}