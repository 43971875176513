import React, { FunctionComponent, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { Button, Grid, Hidden, Typography } from "@material-ui/core"
import { homeCards } from "../utils/config"
import { useIntl } from "react-intl"
import { TableCardsSwitch } from "../components/TableCardsSwitch"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { InviteOrganizationsForm } from "../forms/InviteOrganizationsForm"
import { setLoading, startTutorial } from "../store/actions"
import { orgViewerSteps } from "../tutorials"
import { IAPIInvitation, IAPIOrganization } from "../api/types"
import { DataTable } from "../components/DataTable"
import { useHistory } from "react-router-dom"
import { OrgCard } from "../components/OrgCard"
import axios, { CancelTokenSource } from "axios"
import apiService from "../api"
import { formatDate } from "../utils"
import { checkPermissions } from "../utils/acl"

const CancelToken = axios.CancelToken

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        paddingBottom: theme.spacing(1)
    },
    orgCard: {
        minWidth: 275,
        textAlign: "left"
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        color: "#606060"
    },
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    formControl: {
        width: "100%",
        margin: theme.spacing(0)
    },
    form: {
        padding: 1,
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    cardContent: {
        padding: theme.spacing(2),
        height: 200
    },
    cardText: {
        textAlign: "left"
    },
    cardMainContent: {
        alignItems: "center"
    },
    orgsCardActions: {
        padding: theme.spacing(1)
    },
    orgsPeopleNumAvatar: {
        fontSize: "14px",
        color: "#fff"
    },
    media: {
        height: 0,
        paddingTop: "56.25%" // 16:9
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.common.white
    }
}))

const columns = [
    { name: "name", title: "Name" },
    {
        name: "created", title: "Created", getCellValue: (row: IAPIInvitation) => {
            return formatDate(row.created || "")
        }
    },
    { name: "status", title: "Status" }
]

type OrganizationsProps = {}

export const Organizations: FunctionComponent<OrganizationsProps> = () => {
    const [openForm, setOpenForm] = useState(false)
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const preferredVisualization = useSelector<IStoreState, "cards" | "table">(state => state.settings.preferredVisualization)
    const dispatch = useDispatch()
    const { formatMessage: intl } = useIntl()
    const classes = useStyles()
    let history = useHistory()
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const canInvite = checkPermissions("inviteOrganization", "function", permissions || [])
    const [orgs, setOrgs] = useState<IAPIOrganization[]>([])
    const source = CancelToken.source()

    async function listOrganizations(source: CancelTokenSource) {
        try {
            console.log(false)
            dispatch(setLoading(true))
            const result = await apiService.listOrganizations({ page: 0, perPage: 0 }, source.token)
            setOrgs(result.data)
        } catch (e) {
            //if (e.statusCode !== 0) setError(true)
        }
        dispatch(setLoading(false))
    }

    useEffect(() => {
        listOrganizations(source)
        return () => {
            source.cancel("Operation Canceled By User")
            dispatch(setLoading(false))
        }
    }, [])

    return (
        <Container className={ clsx(classes.root) } component="main">
            <Typography variant="h4">{ intl({ id: homeCards[1].intlId + ".title" }) }</Typography>
            <Typography variant="subtitle1"
                        className={ classes.caption }>{ intl({ id: homeCards[1].intlId + ".description" }) }</Typography>
            <Container className={ classes.root }>
                <Grid container justify="space-around">
                    <Grid item>
                        { canInvite && (
                            <Button data-tut="organizations.invite" variant="contained" color="primary"
                                    onClick={ () => setOpenForm(true) }>{ intl({ id: "functions.invite" }) }</Button>) }
                        <Button style={ { marginLeft: canInvite ? 10 : 0 } } disabled={ loading } variant="contained"
                                color="secondary"
                                onClick={ () => listOrganizations(source) }>Refresh</Button>
                        <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                                onClick={ () => dispatch(startTutorial(orgViewerSteps(intl, preferredVisualization))) }>{ intl({ id: "functions.help" }) }</Button>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item>
                            <TableCardsSwitch dataTut="organizations.visualizationSwitch"/>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
            { preferredVisualization === "cards" ?
                (<Grid container spacing={ 1 }>
                    { orgs.map(organization => (
                        <Grid key={ organization.idOrganization } item md={ 6 } xs={ 12 }>
                            <OrgCard org={ organization }
                                     onDetails={ (id: string) => history.push("/organizations/" + id) }/>
                        </Grid>
                    )) }
                </Grid>) :
                (<DataTable<IAPIOrganization> loading={ loading }
                                              totalCount={ orgs.length }
                                              columns={ columns }
                                              rows={ orgs }
                                              idField="idOrganization"
                                              pageSize={ 10 }
                                              pageSizes={ [2, 10, 15] }
                                              defaultSorting={ [{ columnName: "name", direction: "asc" }] }
                                              showDetailsButton
                                              onDetailRow={ (row) => history.push("/organizations/" + row.idOrganization) }/>) }
            <InviteOrganizationsForm open={ openForm } onClose={ (done: boolean) => {
                done && listOrganizations(source)
                setOpenForm(false)
            } }/>
        </Container>
    )
}