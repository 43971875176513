import React, { ReactElement, useState } from "react"
import Paper from "@material-ui/core/Paper"
import MuiGrid from "@material-ui/core/Grid"
import { EditingState, FilteringState, GetCellValueFn, GroupingState, IntegratedFiltering, IntegratedGrouping, IntegratedPaging, PagingState, RowDetailState, Sorting, SortingState } from "@devexpress/dx-react-grid"
import {
    DragDropProvider, Grid, GroupingPanel, PagingPanel, Table, TableBandHeader, TableColumnReordering, TableColumnResizing, TableEditColumn, TableFilterRow, TableGroupRow, TableHeaderRow, TableRowDetail, Toolbar, ColumnChooser, TableColumnVisibility
} from "@devexpress/dx-react-grid-material-ui"
import { Checkbox, Fade, IconButton, LinearProgress, ListItem, ListItemText, MenuItem, Select, TableCell, Theme, Typography, useTheme, withStyles } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import FindInPageIcon from "@material-ui/icons/FindInPage"
import { Getter } from "@devexpress/dx-react-core"
import "./DataTableLoading.css"

const statusFilterStyles = (theme: Theme) => ({
    cell: {
        padding: theme.spacing(1)
    },
    input: {
        fontSize: "14px",
        width: "100%"
    },
    selectEmpty: {}
})

type DataTableColumn = {
    name: string;
    /** Specifies the column title. */
    title?: string;
    /** Specifies the function used to get the column value for a given row. */
    getCellValue?: GetCellValueFn;
    width?: "auto" | number
}

type DataTableProps<T> = {
    title?: string
    columns: DataTableColumn[]
    rows?: T[]
    pageSize: number
    tableActions?: any
    idField?: string
    descriptionField?: string
    pageSizes?: number[]
    currentPage?: number
    columnBands?: TableBandHeader.ColumnBands[]
    onPageChange?: (page: number) => void
    loading: boolean
    totalCount?: number
    defaultSorting: Sorting[]
    detailComponent?: any
    showDetailsButton?: boolean | ((row: T) => boolean)
    showDeleteButton?: boolean | ((row: T) => boolean)
    onDeleteRow?: (row: T) => void
    onDetailRow?: (row: T) => void
    customRowActions?: (row: T) => ReactElement<any, any>
    onCustomButtonRow?: (row: T) => void
    onFiltersChange?: (filters: any, sorting: any, grouping: any) => void
}

export const DataTable: <T>(p: DataTableProps<T>) => React.ReactElement<DataTableProps<T>> = (props) => {
    const theme = useTheme()

    //const [sorting, setSorting] = useState(props.defaultSorting)

    const [columnOrder, setColumnOrder] = useState(props.columns.map(col => col.name))
    const onColumnOrderChange = (order: any) => setColumnOrder(order)

    const [editingRowIds, setEditingRowIds] = useState([])
    const onEditingRowIdsChange = (editingRowIds: any) => {
        setEditingRowIds(expandedRowIds)
        /*editingRowIds.map((idx: number) => props.rows && props.onEditRow && props.onEditRow(props.rows[idx]))*/
    }
    const onCommitChange = (data: any) => {
        if (data.deleted) {
            data.deleted.map((idx: number) => (props.rows && props.onDeleteRow && props.onDeleteRow(props.rows[idx])) || undefined)
        }
    }
    const [expandedRowIds, setExpandedRowIds] = useState([])
    const onExpandedRowIdsChange = (expandedRowIds: any) => setExpandedRowIds(expandedRowIds)

    const [grouping, setGrouping] = useState([])
    const onGroupingChange = (grouping: any) => {
        setGrouping(grouping)
    }

    const [expandedGroups, setExpandedGroups] = useState([])
    const onExpandedGroupsChange = (expandedGroups: any) => {
        setExpandedGroups(expandedGroups)
    }

    const statusPredicate = (value: string, filter: any) => value === filter.value

    const [integratedFilteringColumnExtensions] = useState([
        { columnName: "status", predicate: statusPredicate },
        { columnName: "created", filteringEnabled: false },
        { columnName: "updated", filteringEnabled: false },
        { columnName: "deleted", filteringEnabled: false }
    ])

    const StatusFilterCellBase = ({ filter, onFilter, classes }: any) => (
        <TableCell className={ classes.cell }>
            <Select value={ filter && filter.value ? filter.value : "" }
                    fullWidth
                    onChange={ e => onFilter(e.target.value ? { value: e.target.value } : null) }
                    displayEmpty className={ classes.selectEmpty }>
                <MenuItem value={ "" }><em>Any</em></MenuItem>
                <MenuItem value={ "ACTIVE" }>Active</MenuItem>
                <MenuItem value={ "INACTIVE" }>Inactive</MenuItem>
            </Select>
        </TableCell>
    )
    const StatusFilterCell = withStyles(statusFilterStyles, { name: "StatusFilterCell" })(StatusFilterCellBase)

    const FilterCell = (props: any) => {
        const { column } = props
        if (column.name === "status") {
            return <StatusFilterCell { ...props } />
        }
        return <TableFilterRow.Cell { ...props } />
    }

    return (
        <Paper style={ { position: "relative", minWidth: "100%", marginTop: theme.spacing(2) } }>
            <input type="hidden" value="something"/>
            { props.title && (<MuiGrid container style={ { padding: 10 } } justify="space-between">
                <MuiGrid item>
                    <Typography variant="h5">{ props.title }</Typography>
                </MuiGrid>
                { props.tableActions && (<MuiGrid item>
                    { props.tableActions }
                </MuiGrid>) }
            </MuiGrid>) }
            <Grid
                rows={ props.rows || [] }
                columns={ props.columns }
            >
                {/*<FilteringState
                    filters={ filters }
                    onFiltersChange={ onFiltersChange }
                />*/ }

                <SortingState
                    /*sorting={ sorting }
                    onSortingChange={ setSorting }*/
                    defaultSorting={ props.defaultSorting }
                />

                <FilteringState defaultFilters={ [] }/>
                <IntegratedFiltering columnExtensions={ integratedFilteringColumnExtensions }/>

                <GroupingState
                    grouping={ grouping }
                    onGroupingChange={ onGroupingChange }
                    expandedGroups={ expandedGroups }
                    onExpandedGroupsChange={ onExpandedGroupsChange }
                />

                {/*<PagingState
                    currentPage={ props.currentPage }
                    onCurrentPageChange={ props.onPageChange }
                    pageSize={ props.pageSize }
                    onPageSizeChange={ changePageSize }
                />

                <CustomPaging
                    totalCount={ props.totalCount }
                />*/ }

                <PagingState
                    defaultCurrentPage={ 0 }
                    defaultPageSize={ (props.pageSizes && props.pageSizes[1]) || 10 }
                />
                <IntegratedPaging/>

                <RowDetailState
                    expandedRowIds={ expandedRowIds }
                    onExpandedRowIdsChange={ onExpandedRowIdsChange }
                />

                <EditingState
                    editingRowIds={ editingRowIds }
                    onEditingRowIdsChange={ onEditingRowIdsChange }
                    onCommitChanges={ onCommitChange }
                />

                <IntegratedFiltering/>
                {/*<IntegratedSorting/>*/ }
                <IntegratedGrouping/>

                <DragDropProvider/>

                <Table/>
                <TableColumnResizing resizingMode="nextColumn"
                                     defaultColumnWidths={ props.columns.map((col) => { return { columnName: col.name, width: col.width || "auto" } }) }/>

                <TableEditColumn
                    cellComponent={ (params, context) => props.customRowActions ? props.customRowActions(params.row) :
                        <TableCell style={ { textAlign: "center" } }>
                            { props.showDetailsButton && 
                                ((typeof props.showDetailsButton == "function" && props.showDetailsButton(params.row))
                                || typeof props.showDetailsButton == "boolean") && 
                                    <IconButton 
                                        style={ { padding: 2 } }
                                        onClick={ () => props.onDetailRow && props.onDetailRow(params.row) }
                                        title="View details row">
                                        <FindInPageIcon/>
                                    </IconButton> }
                            { props.showDeleteButton && 
                                ((typeof props.showDeleteButton == "function" && props.showDeleteButton(params.row))
                                || typeof props.showDeleteButton == "boolean") && 
                                    <IconButton style={ { padding: 2 } }
                                        onClick={ () => props.onDeleteRow && props.onDeleteRow(params.row) }
                                        title="Remove row">
                                        <DeleteIcon/>
                                    </IconButton> }
                        </TableCell>
                    }
                />

                {/*<TableHeaderRow showSortingControls/>*/ }
                <TableHeaderRow/>

                <TableColumnVisibility emptyMessageComponent={ () => (<DeleteIcon/>) }/>

                <TableColumnReordering
                    order={ columnOrder }
                    onOrderChange={ onColumnOrderChange }
                />

                <TableFilterRow cellComponent={ FilterCell }/>

                { (props.detailComponent || props.idField) && <TableRowDetail contentComponent={ ({ row }: any) => (<>
                    { props.idField && (<Typography style={ { fontSize: "0.875rem" } }>
                        <strong>Id: </strong> { row[props.idField] }
                    </Typography>) }
                    { props.descriptionField && (<Typography style={ { fontSize: "0.875rem" } }>
                        <strong>Description: </strong> { row[props.descriptionField] }
                    </Typography>) }

                    { props.detailComponent && props.detailComponent({ row }) }
                </>) }/> }

                {/*<TableRowDetail
                    contentComponent={ ({ row }: any) => (<div>
                        <>
                            <h5>
                                { row.firstName }
                                { " " }
                                { row.lastName }
                                &apos;s Roles:
                            </h5>
                        </>
                        <Paper>
                            <Grid
                                rows={ [{ subject: "cipolla" }, { subject: "cipolla" }, { subject: "cipolla" }, { subject: "cipolla" }] }
                                columns={ detailColumns }
                            >
                                <Table
                                    columnExtensions={ tableDetailColumnExtensions }
                                />
                                <TableHeaderRow/>
                            </Grid>
                        </Paper>
                    </div>) }
                />*/ }
                <TableGroupRow/>

                <Toolbar/>
                <ColumnChooser itemComponent={ ({ item: { column, hidden }, onToggle }) => {
                    const handleClick = () => onToggle()
                    return (
                        <ListItem
                            key={ column.name }
                            button
                            onClick={ handleClick }
                        >
                            <Checkbox
                                color="primary"
                                checked={ !hidden }
                                tabIndex={ -1 }
                                disableRipple
                            />
                            <ListItemText primary={ column.title || column.name }/>
                        </ListItem>
                    )
                } }/>
                <GroupingPanel showSortingControls/>
                <PagingPanel
                    pageSizes={ props.pageSizes }
                />
                <TableBandHeader
                    columnBands={ props.columnBands || [] }
                />
                <Getter
                    name="tableColumns"
                    computed={ ({ tableColumns }) => {
                        return [
                            ...tableColumns.filter((c: any) => c.type !== TableEditColumn.COLUMN_TYPE),
                            { key: "editCommand", type: TableEditColumn.COLUMN_TYPE, width: 140 }
                        ]
                    }
                    }
                />
            </Grid>
            <Fade
                in={ props.loading }
                style={ {
                    transitionDelay: props.loading ? "50ms" : "0ms"
                } }
            >
                <LinearProgress variant="indeterminate" style={ {
                    top: 0,
                    position: "absolute",
                    width: "100%",
                    borderRadius: "8px"
                } }/>
            </Fade>
        </Paper>
    )
}