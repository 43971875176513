import React, { BaseSyntheticEvent, ChangeEvent, forwardRef, FunctionComponent, useEffect, useState } from "react"
import PermissionsIcon from "@material-ui/icons/LockOpenOutlined"
import GeneralInfoIcon from "@material-ui/icons/AssignmentOutlined"
import ReviewIcon from "@material-ui/icons/AssignmentTurnedInOutlined"
import { Grid, Slide, Typography } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { FormWizardDialog } from "../components/FormWizardDialog"
import { validateEmail } from "../utils"
import { IAPIOrganization, IAPIRole } from "../api/types"
import apiService from "../api"
import { useDispatch } from "react-redux"
import { IInviteUserValues, ITag } from "./types"
import { useFormStyles } from "./utils"
import { enqueueSnackBar } from "../store/actions"
import { AutocompleteOrganization } from "../components/AutocompleteOrganization"
import { AutocompleteRoles } from "../components/AutocompleteRoles"
import { AutocompleteTags } from "../components/AutocompleteTags"
import { AutocompleteText } from "../components/AutocompleteText"
import { NOVAFUTUR } from "../utils/identityProviders"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ ref } { ...props } />
})

type UsersFormProps = {
    open: boolean
    onClose: Function
}

export const UsersForm: FunctionComponent<UsersFormProps> = (props) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const { handleSubmit, triggerValidation, register, unregister, errors, setValue, watch } = useForm<IInviteUserValues>({
        reValidateMode: "onChange",
        defaultValues: {
            emails: [],
            organization: {},
            selectedTags: [],
            roles: []
        }
    })
    const fields = watch({ nest: true })

    const classes = useFormStyles()

    const [activeStep, setActiveStep] = useState(0)
    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }
    const handleNext = async () => {
        switch (activeStep) {
            case 0:
                await triggerValidation(["emails", "organization"])
                break
            case 1:
                await triggerValidation(["roles", "applications", "selectedTags"])
        }
        if (!errors.organization && !errors.emails) setActiveStep(activeStep + 1)
    }
    const handleReset = () => {
        setActiveStep(0)
    }

    useEffect(() => {
        setTimeout(() => {
            const elements = document.querySelectorAll("#___reactour button")
            elements.forEach((el: any) => (el.tabIndex = -1))
        }, 100)
        register({ name: "emails" }, {
            validate: value => (value && value.length > 0) || "Required"
        })
        register({ name: "organization" }, {
            required: "Required"
        })
        register({ name: "selectedTags" }, {})
        register({ name: "roles" }, {
            validate: value => (value && value.length > 0) || "Required"
        })
        return () => unregister(["emails", "organization", "applications", "selectedTags", "roles"])
    }, [register])

    const reset = () => {
        setValue("emails", [])
        setValue("applications", [])
        setValue("selectedTags", [])
        setValue("roles", [])
    }

    const onSubmit = async (data: IInviteUserValues, e?: BaseSyntheticEvent) => {
        e?.preventDefault()
        if (!loading) {
            setLoading(true)
            try {
                await apiService.inviteUser({
                    emailTargets: data.emails,
                    idOrganization: data.organization.idOrganization,
                    idRoles: data.roles.map(role => role.idRole), 
                    vTags: data.selectedTags.map(tag => tag.tag),
                    identityProvider: NOVAFUTUR,
                    callbackUri: process.env.REACT_APP_REDIRECT_URI || ""
                })
                dispatch(enqueueSnackBar({
                    key: "created_invitation" || "", message: "Data Correctly Saved!", options: {
                        preventDuplicate: false, variant: "success"
                    }
                }))
                reset()
                props.onClose()
            } catch (ex) {
                dispatch(enqueueSnackBar({
                    key: ex.code || "", message: "Ops! Something went wrong", options: {
                        preventDuplicate: false, variant: "error"
                    }
                }))
            }
        }
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={ 2 } className={ classes.stepContainer }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>Insert the users' emails and
                                the organization they will join</Typography>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                            <AutocompleteText
                                value={ fields.emails }
                                onChange={ (e, value) => {
                                    if (value.length > 0 && !validateEmail(value[value?.length - 1])) {
                                        value.pop()
                                    } else {
                                        setValue("emails", value, true)
                                    }
                                    e.preventDefault()
                                } }
                                label="Emails (press Enter after each email)"
                                placeholder="Email"
                                name="emails"
                                error={ errors.emails }
                            />
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                            <AutocompleteOrganization
                                value={ fields.organization }
                                onChange={ (e: ChangeEvent<{}>, value: IAPIOrganization | null) => {
                                    if (value) setValue("organization", value, true)
                                } }
                                error={ errors.organization }
                            />
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container className={ classes.stepContainer } justify="center" spacing={ 2 }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>Insert users' roles and
                                visibility tags</Typography>
                        </Grid>
                        <Grid item className={ classes.grow }>
                            <AutocompleteTags
                                value={ fields.selectedTags }
                                onChange={ (e: ChangeEvent<{}>, value: ITag[]) => {
                                    setValue("selectedTags", value)
                                } }
                                error={ !!errors.selectedTags }
                            />
                        </Grid>
                        <Grid item xs={ 12 }>
                            <AutocompleteRoles
                                value={ fields.roles }
                                onChange={ (e: ChangeEvent<{}>, value: IAPIRole[] | null) => {
                                    if (value) setValue("roles", value, true)
                                } }
                                error={ errors.roles }
                            />
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container className={ classes.stepContainer } justify="center" spacing={ 2 }>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">You're going to invite:</Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6"><strong>{ fields.emails.join(",") }</strong></Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">to the Organization: <strong>{ fields.organization?.name }</strong></Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">with the following roles and permissions:</Typography>
                        </Grid>
                        {
                            fields.roles.map(role => (
                                <Grid item xs={ 12 } key={ role.idRole }>
                                    <Typography
                                        variant="h6">{ role.idApplication ? role.application?.name : "Custom role: " }<strong>{ role.name }</strong>
                                    </Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                )
            default:
                return "Unknown step"
        }
    }

    return (
        <>
            <FormWizardDialog Transition={ Transition } open={ props.open } fullscreen={ true }
                              onDialogClose={ () => {
                                  reset()
                                  props.onClose()
                              } }
                              onDialogCancel={ () => {
                                  reset()
                                  props.onClose()
                              } }
                              handleSubmit={ handleSubmit(onSubmit) } title="Invite Users"
                              steps={ [{ label: "Users Info", icon: <GeneralInfoIcon/> }, {
                                  label: "Applications, Roles & Visibility Tags", icon: <PermissionsIcon/>
                              }, { label: "Review", icon: <ReviewIcon/> }] }
                              activeStep={ activeStep }
                              getStepContent={ getStepContent }
                              handleBack={ handleBack }
                              handleNext={ handleNext }
                              handleReset={ handleReset }>
            </FormWizardDialog>
        </>
    )

}