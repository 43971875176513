import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { asyncExchangeCode } from "../store/thunks";
import { Loader } from "./Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    position: "absolute",
    width: 120,
    height: 120,
  },
}));

type SignInProps = {};

export const SignIn: FunctionComponent<SignInProps> = () => {
  const silentSubmitButton = useRef<HTMLButtonElement>(null);
  const loginFormButton = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const handler = (event: any) => {
      try {
        if (
          event.data &&
          (typeof event.data === "string" || event.data instanceof String)
        ) {
          const codeResponse: string = "#?code=";
          const errorResponse: string = "#?error=";

          if (event.data.includes(codeResponse)) {
            dispatch(asyncExchangeCode(event.data.replace("#?code=", "")));
          }
          if (event.data.includes(errorResponse)) {
            if (loginFormButton && loginFormButton.current) {
              loginFormButton.current.click();
            }
          }
        }
      } catch (e) {
        console.log("There was an error during silent login process: ", e);
      }
    };

    window.addEventListener("message", handler);

    return () => window.removeEventListener("message", handler);
  }, []);

  useEffect(() => {
    if (silentSubmitButton && silentSubmitButton.current) {
      silentSubmitButton.current.click();
    }
  }, [silentSubmitButton]);

  const oauthURL = process.env.REACT_APP_OAUTH_URL;

  return (
    <>
      <form action={oauthURL} target="silent-login-iframe" method="post">
        <input
          type="hidden"
          name="client_id"
          value={process.env.REACT_APP_CLIENT_ID}
        />
        <input
          type="hidden"
          name="client_secret"
          value={process.env.REACT_APP_CLIENT_SECRET}
        />
        <input type="hidden" name="response_type" value="code" />
        <input
          type="hidden"
          name="redirect_uri"
          value={process.env.REACT_APP_REDIRECT_URI_SILENT}
        />
        <input type="hidden" name="scopes" value="nova_auth" />
        <input type="hidden" name="prompt" value="none" />
        <button
          type="submit"
          style={{ display: "none" }}
          ref={silentSubmitButton}
        />
      </form>
      <iframe title={"login"} name="silent-login-iframe" width={0} height={0} />

      <form action={oauthURL} method="post">
        <input
          type="hidden"
          name="client_id"
          value={process.env.REACT_APP_CLIENT_ID}
        />
        <input
          type="hidden"
          name="client_secret"
          value={process.env.REACT_APP_CLIENT_SECRET}
        />
        <input type="hidden" name="response_type" value="code" />
        <input
          type="hidden"
          name="redirect_uri"
          value={process.env.REACT_APP_REDIRECT_URI}
        />
        <input type="hidden" name="scopes" value="nova_auth" />
        <button style={{ display: "none" }} ref={loginFormButton} />
      </form>

      <Container className={classes.root} component="main">
        <Loader />
      </Container>
    </>
  );
};
