import React, { FunctionComponent } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, ButtonGroup } from "@material-ui/core"
import DashboardIcon from "@material-ui/icons/Dashboard"
import WebIcon from "@material-ui/icons/Web"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { switchVisualization } from "../store/actions"
import { useIntl } from "react-intl"


const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(3),
    },
}));

type TableCardsSwitchProps = {
    dataTut?: string
}

export const TableCardsSwitch: FunctionComponent<TableCardsSwitchProps> = (props) => {
    const preferredVisualization = useSelector((state: IStoreState) => state.settings.preferredVisualization)
    const classes = useStyles()
    const {formatMessage: intl } = useIntl();
    const dispatch = useDispatch()

    return (
        <ButtonGroup data-tut={props.dataTut} className={classes.root} aria-label="cards/table button group">
            <Button onClick={() => dispatch(switchVisualization('cards'))} variant="outlined" color={preferredVisualization === 'cards' ? 'primary': 'default' } startIcon={<DashboardIcon />}>{intl({id: 'preferredVisualization.cards'})}</Button>
            <Button onClick={() => dispatch(switchVisualization('table'))} variant="outlined" color={preferredVisualization === 'cards' ? 'default': 'primary' } startIcon={<WebIcon />}>{intl({id: 'preferredVisualization.table'})}</Button>
        </ButtonGroup>
    );
}