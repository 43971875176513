import React, { FunctionComponent } from "react"
import { Card, CardActionArea, CardActions, CardContent, CardHeader, Divider, Grid, Typography } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
import defaultAppImg from "../assets/ideas.svg"
import { IAPIApplication } from "../api/types"
import { IFeature, IRole } from "../forms/types"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) => ({
    cardActions: {
        padding: theme.spacing(1),
        justifyContent: "flex-end"
    },
    cardContent: {
        padding: theme.spacing(2),
        height: 200
    },
    cardText: {
        textAlign: "left"
    },
    cardMainContent: {
        alignItems: "center",
        height: "100%"
    },
    card: {
        minWidth: 275,
        textAlign: "left"
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    }
}))

export type IApplication = {
    idApplication?: string
    name: string
    enabled: boolean
    supportEmail?: string
    description?: string
    imgUrl?: string
    url?: string
    termsURL?: string
    policyURL?: string
    visTags?: string[]
    roles: IRole[]
    features: IFeature[]
}

type AppCardProps = {
    app: IAPIApplication
    onDetails: (id?: string) => void
}

export const AppCard: FunctionComponent<AppCardProps> = (props) => {
    const classes = useStyles()
    const { app } = props
    let history = useHistory()

    return (
        <Card className={ classes.card } variant="outlined">
            <CardActionArea onClick={ () => history.push("/applications/" + app.idApplication) }>
                <CardHeader
                    className={ classes.cardMainContent }
                    data-tut={ "organizations.header" }
                    title={ app.name }
                />
                <CardContent className={ classes.cardContent }>
                    <Grid container className={ classes.cardMainContent } spacing={ 1 }>
                        <Grid className={ classes.cardText } item xs={ 8 }>
                            <Typography variant="subtitle1"
                                        className={ classes.caption }>{ app.description }</Typography>
                        </Grid>
                        <Grid item xs={ 4 } style={ { textAlign: "center" } }>
                            <img width="100" alt="App Logo" src={ app.imgUrl || defaultAppImg } onError={ (ev) => {
                                (ev.target as any).src = defaultAppImg
                            } }/>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider/>
                <CardActions className={ classes.cardActions } disableSpacing>
                    <Typography align="right"
                                style={ { color: app.status === "ACTIVE" ? "green" : "orange", fontWeight: "bold" } }>
                        { app.status }
                    </Typography>
                </CardActions>
            </CardActionArea>
        </Card>
    )
}