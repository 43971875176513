import {
  Avatar,
  Button,
  Grid,
  Icon,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Tab,
  Tabs,
} from "@material-ui/core";
import MuiAppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { AccountCircle } from "@material-ui/icons";
import AppsIcon from "@material-ui/icons/Apps";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MoreIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import TranslateIcon from "@material-ui/icons/Translate";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { IAPIApplication } from "../api/types";
import defaultAppImg from "../assets/ideas.svg";
import { setLocale, startTutorial, switchTheme } from "../store/actions";
import { asyncLogout } from "../store/thunks";
import { IStoreState, IUser } from "../store/types";
import { overviewSteps } from "../tutorials";
import { stringToColor } from "../utils";
import { checkPermissions } from "../utils/acl";
import { locales, pages } from "../utils/config";
import ElevationScroll from "../utils/ElevationScroll";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageIcon: {
      height: "36.5px",
      width: "180px",
    },
    iconRoot: {
      height: "32px",
      width: "185px",
      marginRight: theme.spacing(2),
      textAlign: "center",
    },
    topBarIcon: {
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1),
    },
    logo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    translateBtn: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        width: 140,
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    flexGrow: {
      flexGrow: 1,
    },
    avatar: {
      cursor: "pointer",
      fontSize: "14px",
      marginLeft: theme.spacing(2),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    paper: {
      cursor: "pointer",
      padding: theme.spacing(0),
      textAlign: "center",
      "&:hover p": {
        overflow: "normal",
        whiteSpace: "normal",
        textOverflow: "normal",
        overflowWrap: "break-word",
      },
    },
    appsRoot: {
      width: 350,
      overflow: "hidden",
      padding: theme.spacing(2),
    },
    mobileDrawer: {
      borderBottom: "1px solid #ccc",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

export const useTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(1),
    },
    indicator: {
      height: 3,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
    },
  })
);

export const useTabItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "initial",
      margin: theme.spacing(0, 2),
      minWidth: 0,
      [theme.breakpoints.up("md")]: {
        minWidth: 0,
      },
    },
    wrapper: {
      fontFamily: "Open sans",
      fontWeight: 550,
      letterSpacing: 0.5,
    },
  })
);

enum TourType {
  Overview = 0,
  App,
  Org,
  Client,
  User,
}

type AppBarProps = {
  onChangeIndex: (index: number) => void;
  location: string;
  intro: boolean;
};

export const AppBar: FunctionComponent<AppBarProps> = (props) => {
  const classes = useStyles();
  const tabsClasses = useTabsStyles();
  const tabItemsClasses = useTabItemStyles();
  const user = useSelector<IStoreState, IUser | undefined>(
    (state) => state.user
  );
  const themeOption = useSelector<IStoreState, "light" | "dark">(
    (state) => state.theme
  );
  const locale = useSelector<IStoreState, "en" | "it">(
    (state) => state.settings.locale
  );
  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const permissions = useSelector<IStoreState, string[]>(
    (state) => state.user?.jwtPayload?.permissions || []
  );
  const applications = useSelector<IStoreState, IAPIApplication[]>(
    (state) => state.apps || []
  );

  const [anchorAppsMenuEl, setAnchorAppsMenuEl] =
    React.useState<null | HTMLElement>(null);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    props.onChangeIndex(newValue);
  };
  const handleAppsMenuClick = (event: any) => {
    setAnchorAppsMenuEl(event.currentTarget);
  };
  const handleAppsMenuClose = () => {
    setAnchorAppsMenuEl(null);
  };

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const [anchorLanguageMenuEl, setAnchorLanguageMenuEl] =
    React.useState<null | HTMLElement>(null);
  const isLanguageMenuOpen = Boolean(anchorLanguageMenuEl);
  const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLanguageMenuEl(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setAnchorLanguageMenuEl(null);
    handleMobileMenuClose();
  };
  const changeLanguage = (locale: "en" | "it") => {
    dispatch(setLocale(locale));
    handleLanguageMenuClose();
  };
  const languageMenuId = "language-menu";
  const renderLanguageMenu = (
    <Menu
      anchorEl={anchorLanguageMenuEl}
      id={languageMenuId}
      open={isLanguageMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem onClick={() => changeLanguage("en")}>
        <img alt="Uk flag" width="30" src={require("../assets/uk.svg")} />
        <Typography style={{ marginLeft: 10 }}>English</Typography>
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("it")}>
        <img
          alt="Italian flag"
          width="30"
          src={require("../assets/italy.svg")}
        />
        <Typography style={{ marginLeft: 10 }}>Italiano</Typography>
      </MenuItem>
    </Menu>
  );

  const [anchorProfileMenuEl, setProfileMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isProfileMenuOpen = Boolean(anchorProfileMenuEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const profileMenuId = "profile-menu";
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorProfileMenuEl}
      id={profileMenuId}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem
        onClick={() => {
          dispatch(asyncLogout());
          handleProfileMenuClose();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const [anchorHelpMenuEl, setHelpMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isHelpMenuOpen = Boolean(anchorHelpMenuEl);

  const handleHelpMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHelpMenuAnchorEl(event.currentTarget);
  };
  const handleHelpMenuClose = () => {
    setHelpMenuAnchorEl(null);
    handleMobileMenuClose();
  };

  const startTour = (type: TourType) => {
    handleHelpMenuClose();
    switch (type) {
      case TourType.Overview:
        dispatch(startTutorial(overviewSteps(intl)));
        break;
      default:
        break;
    }
  };
  const helpMenuId = "primary-search-account-menu";
  const renderHelpMenu = (
    <Menu
      anchorEl={anchorHelpMenuEl}
      id={helpMenuId}
      open={isHelpMenuOpen}
      onClose={handleHelpMenuClose}
    >
      <MenuItem onClick={() => startTour(TourType.Overview)}>
        <Typography>Overview</Typography>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "mobile-menu";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => dispatch(switchTheme())}>
        <IconButton aria-label="show 4 new mails" color="inherit">
          {themeOption === "light" ? (
            <Brightness4Icon />
          ) : (
            <BrightnessHighIcon />
          )}
        </IconButton>
        <Typography>
          {themeOption === "light" ? "Dark theme" : "Light theme"}
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleLanguageMenuOpen}>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <TranslateIcon />
        </IconButton>
        <Typography>Change Locale</Typography>
      </MenuItem>
      {user ? (
        <MenuItem onClick={handleHelpMenuOpen}>
          <IconButton aria-label="show tutorials" color="inherit">
            <HelpOutlineIcon />
          </IconButton>
          <Typography>How To...</Typography>
        </MenuItem>
      ) : undefined}
      {user ? (
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Typography>Profile</Typography>
        </MenuItem>
      ) : (
        <MenuItem>
          <IconButton aria-label="show tutorials" color="inherit">
            <AccountCircle />
          </IconButton>
          <Typography>Sign In</Typography>
        </MenuItem>
      )}
    </Menu>
  );

  const openPopover = Boolean(anchorAppsMenuEl);
  const id = openPopover ? "simple-popover" : undefined;

  function currentSelectedTab(location: string) {
    switch (location) {
      case "/":
      case "/intro":
        return 0;
      case "/profile":
      case "/intro/profile":
        return 1;
      case "/organizations":
      case "/intro/organizations":
        return 2;
      case "/applications":
      case "/intro/applications":
        return 3;
      case "/roles":
      case "/intro/roles":
        return 4;
      case "/users":
      case "/intro/users":
        return 5;
      case "/services":
      case "/intro/services":
        return 6;
      case "/invitations":
      case "/intro/invitations":
        return 7;
      default:
        return 0;
    }
  }

  return (
    <>
      <ElevationScroll {...props}>
        <MuiAppBar
          color="default"
          elevation={0}
          position="fixed"
          className={classes.appBar}
        >
          <Toolbar>
            <div className={classes.logo}>
              <Icon classes={{ root: classes.iconRoot }}>
                <img
                  className={classes.imageIcon}
                  alt="NovaFutur Logo"
                  src={require("../assets/nova_icon.svg")}
                />
              </Icon>
              <Typography variant="h6" noWrap color="primary">
                Logifuture Auth
              </Typography>
            </div>
            <div className={classes.flexGrow} />
            <div className={classes.sectionDesktop}>
              {
                //user ? (<AutocompleteSearch/>) : null
              }
              <Button
                className={classes.translateBtn}
                startIcon={<TranslateIcon />}
                endIcon={<ExpandMoreIcon />}
                onClick={handleLanguageMenuOpen}
              >
                {locales[locale]}
              </Button>
              <IconButton
                aria-describedby={id}
                className={classes.topBarIcon}
                onClick={handleAppsMenuClick}
              >
                <AppsIcon />
              </IconButton>
              <IconButton
                aria-describedby={id}
                className={classes.topBarIcon}
                onClick={() => dispatch(switchTheme())}
              >
                {themeOption === "light" ? (
                  <Brightness4Icon />
                ) : (
                  <BrightnessHighIcon />
                )}
              </IconButton>
              {user ? (
                <IconButton
                  aria-describedby={id}
                  className={classes.topBarIcon}
                  onClick={handleHelpMenuOpen}
                >
                  <HelpOutlineIcon />
                </IconButton>
              ) : undefined}
              {user && (
                <Avatar
                  onClick={handleProfileMenuOpen}
                  className={classes.avatar}
                  style={{ backgroundColor: stringToColor(user?.email || "") }}
                >
                  {(user &&
                    user?.firstName &&
                    user?.firstName.charAt(0).toUpperCase()) ||
                    ""}
                </Avatar>
              )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-describedby={id}
                className={classes.topBarIcon}
                onClick={handleAppsMenuClick}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                aria-describedby={id}
                className={classes.topBarIcon}
                onClick={handleAppsMenuClick}
              >
                <AppsIcon />
              </IconButton>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
          <Toolbar variant="dense" className={classes.mobileDrawer}>
            <Tabs
              value={currentSelectedTab(props.location)}
              onChange={handleChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="off"
              classes={tabsClasses}
              aria-label="scrollable auto tabs"
            >
              {pages.map((page, index) =>
                props.intro ||
                checkPermissions(page.value, "page", permissions) ? (
                  <Tab
                    key={page.id}
                    classes={tabItemsClasses}
                    label={intl({ id: page.nameId })}
                    {...a11yProps(index)}
                  />
                ) : undefined
              )}
            </Tabs>
          </Toolbar>
        </MuiAppBar>
      </ElevationScroll>
      {renderMobileMenu}
      {renderProfileMenu}
      {renderLanguageMenu}
      {renderHelpMenu}
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorAppsMenuEl}
        onClose={handleAppsMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid container spacing={3} className={classes.appsRoot}>
          {applications.map((application) => (
            <Grid key={application.idApplication} item xs={4}>
              <Paper square={true} elevation={0} className={classes.paper}>
                <img
                  alt="Application Icon"
                  width="36"
                  onError={(ev: any) => (ev.target.src = defaultAppImg)}
                  src={application.imgUrl || defaultAppImg}
                />
                <Typography noWrap>{application.name}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};
