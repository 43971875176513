export const SET_USER = 'SET_USER'
export const LOGOUT = 'LOGOUT'
export const SET_LOADING = 'SET_LOADING'
export const SET_API_SUCCESS = 'SET_API_SUCCESS'
export const SET_API_FAILURE = 'SET_API_FAILURE'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_SERVICE = 'SET_SERVICE'
export const START_TUTORIAL = 'START_OPEN'
export const STOP_TUTORIAL = 'STOP_OPEN'
export const SWITCH_THEME = 'SWITCH_THEME'
export const SWITCH_VISUALIZATION = 'SWITCH_VISUALIZATION'
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'
export const SET_REFRESHING_TOKEN = 'SET_REFRESHING_TOKEN'
export const SET_APPLICATIONS = 'SET_APPLICATIONS'

export enum types {
  SET_USER = 'SET_USER',
  LOGOUT = 'LOGOUT',
  SET_LOADING = 'SET_LOADING',
  SET_API_SUCCESS = 'SET_API_SUCCESS',
  SET_API_FAILURE = 'SET_API_FAILURE',
  SET_LOCALE = 'SET_LOCALE',
  SET_SERVICE = 'SET_SERVICE',
  START_TUTORIAL = 'START_OPEN',
  STOP_TUTORIAL = 'STOP_OPEN',
  SWITCH_THEME = 'SWITCH_THEME',
  SWITCH_VISUALIZATION = 'SWITCH_VISUALIZATION',
  ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR = 'REMOVE_SNACKBAR',
  SET_REFRESHING_TOKEN = 'SET_REFRESHING_TOKEN',
  SET_APPLICATIONS = 'SET_APPLICATIONS'
}
