import React, { FunctionComponent } from "react"
import { Uppload, en, Camera, Rotate, Crop, Local } from "uppload"
import "uppload/dist/uppload.css"
import "uppload/dist/themes/light.css"
import Blur from "uppload/dist/effects/filter/blur"

const uppload = new Uppload({
    lang: en,
    uploader: (file, updateProgress) =>
        new Promise(resolve => {
            setTimeout(() => resolve(window.URL.createObjectURL(file)), 4000)
            let progress = 0
            const interval = setInterval(() => {
                if (progress > 99) clearInterval(interval)
                if (updateProgress) {updateProgress(progress++)}
            }, 30)
        })
})

type UploadDialogProps = {
    onClose: () => void
    onUpload?: () => void
}

export const UploadDialog: FunctionComponent<UploadDialogProps> = (props) => {
    const camera = new Camera()
    // @ts-ignore
    uppload.use([new Local(), camera, new Crop({ aspectRatio: 1 }), new Rotate(), new Blur()])

    uppload.open()

    uppload.on("upload", () => {
    })

    uppload.on("close", () => {
        camera.stop()
        uppload.remove("camera")
        uppload.close()
        props.onClose()
    })
    return (
        <></>
    )
}
