import React from "react"
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined"
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined"
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined"
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined"
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined"
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined"
import AppsIcon from "@material-ui/icons/Apps"
import DevicesIcon from "@material-ui/icons/Devices"
import { IPage } from "../pages/types"

export const drawerWidth = 240
export const iconSide = 28

export const pages: IPage[] = [
    {
        id: 1, dataTut: "overview.home", regex: /(\/|\/intro)$/, location: "/", value: "home", nameId: "pages.home", icon:
            <HomeOutlinedIcon/>
    },
    {
        id: 2, dataTut: "overview.profile", regex: /\/profile/, location: "/profile", value: "profile", nameId: "pages.profile", icon:
            <AccountCircleOutlinedIcon/>
    },
    {
        id: 3, dataTut: "overview.organizations", regex: /(\/organizations|\/organizations\/[a-zA-Z0-9-]*)$/, location: "/organizations", value: "organizations", nameId: "pages.organizations", icon:
            <AccountTreeOutlinedIcon/>
    },
    {
        id: 4, dataTut: "overview.applications", regex: /\/applications/, location: "/applications", value: "applications", nameId: "pages.applications", icon:
            <AppsIcon/>
    },
    {
        id: 5, dataTut: "overview.roles", regex: /\/roles/, location: "/roles", value: "roles", nameId: "pages.roles", icon:
            <AssignmentIndOutlinedIcon/>
    },
    {
        id: 6, dataTut: "overview.users", regex: /\/users/, location: "/users", value: "users", nameId: "pages.users", icon:
            <PeopleAltOutlinedIcon/>
    },
    {
        id: 7, dataTut: "overview.services", regex: /\/services/, location: "/services", value: "services", nameId: "pages.services", icon:
            <DevicesIcon/>
    },
    {
        id: 8, dataTut: "overview.invitations", regex: /\/invitations/, location: "/invitations", value: "invitations", nameId: "pages.invitations", icon:
            <DraftsOutlinedIcon/>
    }
]

export const homeCards = [
    { id: 1, intlId: "home.profile", value: "profile", img: "auth.svg", width: "150", acl: "*" },
    { id: 2, intlId: "home.organizations", value: "organizations", img: "organizations.svg", width: "130", acl: "organizations"  },
    { id: 3, intlId: "home.applications", value: "applications", img: "services.svg", width: "130", acl: "applications"  },
    { id: 4, intlId: "home.roles", value: "roles", img: "roles.svg", width: "130", acl: "roles"  },
    { id: 5, intlId: "home.users", value: "users", img: "users.svg", width: "140", acl: "users"  },
    { id: 6, intlId: "home.services", value: "services", img: "server_cluster.svg", width: "140", acl: "services"  },
    { id: 7, intlId: "home.invitations", value: "invitations", img: "invitations.svg", width: "140", acl: "invitations"  }
]

export const intros = [
    { id: 1, exact: true, path: "/intro", titleId: "intros.home.title", img: "fingerprint.svg", height: 150, descriptionId: "intros.home.description" },
    { id: 2, path: "/intro/profile", titleId: "intros.profile.title", img: "auth.svg", height: 150, descriptionId: "intros.profile.description" },
    { id: 3, path: "/intro/organizations", titleId: "intros.organizations.title", img: "organizations.svg", height: 150, descriptionId: "intros.organizations.description" },
    { id: 4, path: "/intro/applications", titleId: "intros.applications.title", img: "services.svg", height: 150, descriptionId: "intros.applications.description" },
    { id: 5, path: "/intro/roles", titleId: "intros.roles.title", img: "roles.svg", height: 150, descriptionId: "intros.roles.description" },
    { id: 6, path: "/intro/users", titleId: "intros.users.title", img: "users.svg", height: 150, descriptionId: "intros.users.description" },
    { id: 7, path: "/intro/services", titleId: "intros.services.title", img: "services.svg", height: 150, descriptionId: "intros.services.description" }
]

export const locales = {
    en: "English",
    it: "Italian"
}