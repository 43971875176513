import React, { FunctionComponent, useEffect, useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableCell, TextField, Typography, useMediaQuery } from "@material-ui/core"
import { homeCards } from "../utils/config"
import { DataTable } from "../components/DataTable"
import { useIntl } from "react-intl"
import { ServicesForm } from "../forms/ServicesForm"
import apiService from "../api"
import { IAPIInvitation, IAPIServiceAccount } from "../api/types"
import { formatDate } from "../utils"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { checkPermissions } from "../utils/acl"
import { useForm } from "react-hook-form"
import { LoadingButton } from "../components/LoadingButton"
import FindInPageIcon from "@material-ui/icons/FindInPage"
import VpnKeyIcon from "@material-ui/icons/VpnKey"
import { useHistory } from "react-router-dom"
import axios, { CancelTokenSource } from "axios"
import { setLoading } from "../store/actions"

const CancelToken = axios.CancelToken

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    mb: {
        marginBottom: theme.spacing(3)
    }
}))

const columns = [
    { name: "name", title: "Name" },
    { name: "clientId", title: "clientId" },
    {
        name: "created", title: "Created", getCellValue: (row: IAPIInvitation) => {
            return formatDate(row.created || "")
        }
    },
    {
        name: "status", title: "Status", getCellValue: (row: IAPIServiceAccount) => {
            return row.status || "";
        }
    }
]

type ServicesProps = {}

export const ServiceAccounts: FunctionComponent<ServicesProps> = () => {
    const classes = useStyles()
    const { formatMessage: intl } = useIntl()
    const [openForm, setOpenForm] = useState(false)
    const idUserAccount = useSelector<IStoreState, string | undefined>(state => state.user?.idUserAccount)
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const canCreate = checkPermissions("createServiceAccount", "function", permissions || [])
    const [services, setServices] = useState<IAPIServiceAccount[]>([])
    const [serviceSecret, setServiceSecret] = useState<string | undefined>()
    const { register, errors, watch, triggerValidation } = useForm()
    const data = watch({ nest: true })
    const [serviceAccount, setServiceAccount] = useState<IAPIServiceAccount | undefined>()
    const [error, setError] = useState<string | undefined>()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))
    const [openDialog, setOpenDialog] = useState(false)
    const [internalLoading, setInternalLoading] = useState(false)
    const dispatch = useDispatch()
    let history = useHistory()
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const source = CancelToken.source()

    const handleClose = () => {
        setServiceSecret(undefined)
        setOpenDialog(false)
    }

    const getSecret = async () => {
        await triggerValidation(["passwordSecret"], true)
        console.log(data)
        if (!errors.passwordSecret) {
            try {
                setInternalLoading(true)
                const response = await apiService.getServiceAccountSecret(idUserAccount || "", data.passwordSecret, serviceAccount?.idServiceAccount || "")
                if (response.data && (response.data.clientSecret || response.data.clientSecrret)) setServiceSecret(response.data.clientSecret || response.data.clientSecrret)
                else setError("Secret not available right now")
            } catch {
                setError("Secret not available right now")
            }
            setInternalLoading(false)
        }
    }

    const renderError = () => {
        if (!!errors.passwordSecret) return (errors.passwordSecret as any).message
        else if (error) return error
        else return " "
    }

    async function listServiceAccounts(source: CancelTokenSource) {
        try {
            dispatch(setLoading(true))
            const result = await apiService.listServiceAccounts({ orderBy: "created desc" }, source.token)
            setServices(result.data)
        } catch (e) {
            //if (e.statusCode !== 0) setError(true)
        }
        dispatch(setLoading(false))
    }

    useEffect(() => {
        listServiceAccounts(source)
        return () => {
            source.cancel("Operation Canceled By User")
            dispatch(setLoading(false))
        }
    }, [])

    return (
        <Container className={ classes.root } component="main" style={ { maxWidth: "100%" } }>
            <Typography variant="h4">{ intl({ id: homeCards[5].intlId + ".title" }) }</Typography>
            <Typography variant="subtitle1"
                        className={ classes.caption }>{ intl({ id: homeCards[5].intlId + ".description" }) }</Typography>
            { canCreate && <Button className={ classes.mb } variant="contained" color="primary"
                                   onClick={ () => setOpenForm(true) }>{ intl({ id: "functions.addNew" }) }</Button> }
            <DataTable<IAPIServiceAccount> loading={ loading }
                                           totalCount={ services.length }
                                           columns={ columns }
                                           rows={ services }
                                           idField="idServiceAccount"
                                           pageSize={ 10 }
                                           pageSizes={ [10, 25, 50] }
                                           defaultSorting={ [{ columnName: "created", direction: "desc" }] }
                                           customRowActions={ (row) =>
                                               <TableCell style={ { textAlign: "center" } }>
                                                   <IconButton style={ { padding: 2 } }
                                                               onClick={ () => {
                                                                   history.push(`/services/${ row.idServiceAccount }`)
                                                               } }
                                                               title="View details row">
                                                       <FindInPageIcon/>
                                                   </IconButton>
                                                   <IconButton style={ { padding: 2 } }
                                                               onClick={ () => {
                                                                   setServiceAccount(row)
                                                                   setOpenDialog(true)
                                                               } }
                                                               title="Show secret">
                                                       <VpnKeyIcon/>
                                                   </IconButton>
                                               </TableCell>
                                           }/>
            <ServicesForm open={ openForm } onSuccess={ () => {
                //setCreated(id)
                setOpenForm(false)
            } } onClose={ (done: boolean) => {
                done && listServiceAccounts(source)
                setOpenForm(false)
            } }/>
            <Dialog
                fullScreen={ fullScreen }
                open={ openDialog }
                onClose={ handleClose }
                aria-labelledby="show-service-secret-label"
            >
                <DialogTitle id="show-service-secret-label">Do you want to get the service client secret? Please insert
                    your password</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        label="Password"
                        name="passwordSecret"
                        className={ classes.mb }
                        inputRef={ register({
                            required: "Required"
                        }) }
                        error={ !!errors.passwordSecret || !!error }
                        type="password"
                        autoComplete={ "new-password" }
                        helperText={ renderError() }
                    />
                    { serviceSecret && (<Typography><strong>Client Secret:</strong> { serviceSecret }</Typography>) }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleClose }>
                        Cancel
                    </Button>
                    <LoadingButton loading={ internalLoading } onClick={ !serviceSecret ? getSecret : handleClose }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    )
}