import React, { FunctionComponent } from "react"
import { Button, Chip, Grid, IconButton, TextField, Typography } from "@material-ui/core"
import { useIntl } from "react-intl"
import { Autocomplete } from "@material-ui/lab"
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add"
import { useFormStyles } from "./utils"
import { ICreateApplicationPermissionOption, ICreateApplicationRole, IFeature, IRoleError } from "./types"
import { slugify } from "../utils"

type RolesStepProps = {
    onChange: (permissions: any) => void
    roles: ICreateApplicationRole[]
    features: IFeature[]
    error: IRoleError[]
}

export const RolesStep: FunctionComponent<RolesStepProps> = (props) => {
    const classes = useFormStyles()
    const { formatMessage: intl } = useIntl()

    const updateRoles = (value: any, idx: number, field: string) => {
        switch (field) {
            case "name":
                props.onChange([...props.roles.slice(0, idx), { ...props.roles[idx], name: value }, ...props.roles.slice(idx + 1)])
                break
            case "description":
                props.onChange([...props.roles.slice(0, idx), { ...props.roles[idx], description: value }, ...props.roles.slice(idx + 1)])
                break
            case "permissions":
                const hasAllPermissions = value.find((permission: ICreateApplicationPermissionOption) => permission.macroFunction === "All" && permission.permission === "All")
                if (hasAllPermissions) {
                    value = [{ macroFunction: "All", permission: "All" }]
                } else {
                    const allPermissions = value.filter((permission: any) => permission.permission === "All")
                    value = value.filter((permission: any) => {
                        const isInAllPermission = allPermissions.find((allPermission: any) => allPermission.macroFunction === permission.macroFunction)
                        return isInAllPermission ? permission.permission === "All" : true
                    })
                }
                props.onChange([...props.roles.slice(0, idx), { ...props.roles[idx], permissions: value }, ...props.roles.slice(idx + 1)])
                break
        }
    }

    const removeRole = (index: number) => {
        props.onChange([...props.roles.slice(0, index), ...props.roles.slice(index + 1)])
    }

    const addRole = () => {
        const name = `role${ props.roles.length }`
        props.onChange([...props.roles, { name: name, permissions: [], description: "" }])
    }

    return (
        <>
            <Grid className={ classes.smallBottomMargin } item container justify="space-between">
                { props.roles.map((role, index) => {
                    const fieldName = `roles[${ index }]`
                    return (
                        <Grid key={ fieldName } item container className={ classes.roleContainer }
                              alignItems="center">
                            <Grid item container xs={ 11 }>
                                <Grid item xs={ 12 } sm={ 2 }>
                                    <TextField
                                        data-tut="app.create.roleName"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        label={ intl({ id: "applications.forms.role.name" }) }
                                        fullWidth
                                        name="roleName"
                                        onChange={ (e) => {
                                            updateRoles(e.target.value, index, "name")
                                        } }
                                        value={ props.roles[index].name }
                                        error={ props.error && !!props.error[index] && props.error[index].name }
                                        helperText={ (props.error && !!props.error[index] && props.error[index].name && intl({ id: "forms.errors.required" })) || " " }
                                    />
                                </Grid>
                                <Grid item xs={ 12 } sm={ 10 } className={ classes.inputDistance }>
                                    <TextField
                                        data-tut="app.create.roleName"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        label={ intl({ id: "applications.forms.role.description" }) }
                                        fullWidth
                                        name="roleName"
                                        onChange={ (e) => {
                                            updateRoles(e.target.value, index, "description")
                                        } }
                                        value={ props.roles[index].description || "" }
                                        helperText=" "
                                    />
                                </Grid>
                                <Grid item xs={ 12 } sm={ 12 }>
                                    <Autocomplete<ICreateApplicationPermissionOption>
                                        multiple
                                        filterSelectedOptions
                                        value={ role.permissions as ICreateApplicationPermissionOption[] }
                                        onChange={ (e, value) => {
                                            updateRoles(value, index, "permissions")
                                        } }
                                        getOptionSelected={ (option, value) => value.macroFunction === option.macroFunction && value.permission === option.permission }
                                        getOptionLabel={ (option) => option.permission }
                                        groupBy={ option => option.macroFunction }
                                        options={ props.features.reduce((acc: ICreateApplicationPermissionOption[], feature: any) => {
                                            console.log("features", props.features)
                                            feature.functions.forEach((value: any) => {
                                                if (!role.permissions.find(permission => (feature.macroFunction === permission.macroFunction && (permission.permission === value || permission.permission === "All")) || (permission.macroFunction === "All" && permission.permission === "All"))) {
                                                    acc = acc.concat({ macroFunction: feature.macroFunction, permission: value })
                                                }
                                            })
                                            return acc
                                        }, [] as ICreateApplicationPermissionOption[]) }
                                        renderTags={ (value: ICreateApplicationPermissionOption[], getTagProps) =>
                                            value.map((option: ICreateApplicationPermissionOption, idx: number) => (
                                                <Chip  { ...getTagProps({ index: idx }) }
                                                       variant="outlined"
                                                       size="small"
                                                       label={ `${ slugify(option.macroFunction) }.${ slugify(option.permission) }` }/>
                                            ))
                                        }
                                        renderInput={ params => (
                                            <TextField
                                                data-tut="app.create.rolePermissions"
                                                { ...params }
                                                color="primary"
                                                variant="outlined"
                                                label={ intl({ id: "applications.forms.role.permissions" }) }
                                                size="small"
                                                placeholder={ intl({ id: "applications.forms.role.permissions" }) }
                                                fullWidth
                                                error={ props.error && !!props.error[index] && props.error[index].permissions }
                                                helperText={ (props.error && !!props.error[index] && props.error[index].permissions && intl({ id: "forms.errors.required" })) || " " }
                                            />
                                        ) }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    data-tut="app.create.deleteRole"
                                    aria-label="delete"
                                    onClick={ () => removeRole(index) }>
                                    <DeleteIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    )
                }) }
                <Grid item container alignItems="center">
                    <Button
                        data-tut="app.create.addRole"
                        onClick={ () => addRole() }
                        variant="contained"
                        color="primary"
                        startIcon={ <AddIcon/> }
                    >
                        { intl({ id: "applications.forms.role.new" }) }
                    </Button>
                    { props.error && props.error[0] && props.error[0].required &&
                    <Typography
                        className={ classes.atLeastOneError }>{ intl({ id: "forms.errors.noRole" }) }</Typography> }
                </Grid>
            </Grid>
        </>
    )
}