import React, { FunctionComponent, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { Button, Typography } from "@material-ui/core"
import { homeCards } from "../utils/config"
import { DataTable } from "../components/DataTable"
import { useIntl } from "react-intl"
import { UsersForm } from "../forms/UsersForm"
import { IStoreState, IUser } from "../store/types"
import apiService from "../api"
import { IAPIUser } from "../api/types"
import { useDispatch, useSelector } from "react-redux"
import { checkPermissions } from "../utils/acl"
import axios, { CancelTokenSource } from "axios"
import { setLoading } from "../store/actions"
import { useHistory } from "react-router-dom"

const CancelToken = axios.CancelToken

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    mb: {
        marginBottom: theme.spacing(3)
    }
}))

const columns = [
    {
        name: "organizationName", title: "Organization", getCellValue: (row: IAPIUser) => {
            return row.organizations.map(o => o.idOrganization).join(", ");
        }
    },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "email", title: "Email" },
    { name: "status", title: "Status" }
]

type UsersProps = {}

export const Users: FunctionComponent<UsersProps> = (props) => {
    const classes = useStyles()
    const { formatMessage: intl } = useIntl()
    const [openForm, setOpenForm] = useState(false)
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const canInvite = checkPermissions("inviteUser", "function", permissions || [])
    const [users, setUsers] = useState<IAPIUser[]>([])
    const dispatch = useDispatch()
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const source = CancelToken.source()
    let history = useHistory()

    async function listUsers(source: CancelTokenSource) {
        try {
            dispatch(setLoading(true))
            const result = await apiService.listUsers({ page: 0, perPage: 0 }, source.token)
            setUsers(result.data)
        } catch (e) {
            //if (e.statusCode !== 0) setError(true)
        }
        dispatch(setLoading(false))
    }

    useEffect(() => {
        listUsers(source)
        return () => {
            source.cancel("Operation Canceled By User")
            dispatch(setLoading(false))
        }
    }, [])

    return (
        <Container className={ classes.root } component="main" style={ { maxWidth: "100%" } }>
            <Typography variant="h4">{ intl({ id: homeCards[4].intlId + ".title" }) }</Typography>
            <Typography variant="subtitle1"
                        className={ classes.caption }>{ intl({ id: homeCards[4].intlId + ".description" }) }</Typography>
            { canInvite && <Button className={ classes.mb } variant="contained" color="primary"
                                   onClick={ () => setOpenForm(true) }>{ intl({ id: "functions.invite" }) }</Button> }
            <DataTable<IUser> loading={ loading }
                              totalCount={ users.length }
                              columns={ columns }
                              rows={ users }
                              idField="idUserAccount"
                              pageSize={ 10 }
                              pageSizes={ [5, 10, 15] }
                              columnBands={ [
                                  {
                                      title: "Full Name",
                                      children: [
                                          { columnName: "firstName" },
                                          { columnName: "lastName" }
                                      ]
                                  }
                              ] }
                              defaultSorting={ [{ columnName: "email", direction: "asc" }] }
                              showDetailsButton
                              onDetailRow={ (user) => history.push(`/users/${ user.idUserAccount }`) }/>
            <UsersForm open={ openForm } onClose={ (done: boolean) => {
                done && listUsers(source)
                setOpenForm(false)
            } }/>
        </Container>
    )
}