import React, { FunctionComponent } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import clsx from "clsx"
import Tour, { ReactourProps } from "reactour"
import { useSelector } from "react-redux"
import { IStoreState } from "../store/types"

const useStyles = makeStyles(theme => ({
    helper: {
        backgroundColor: theme.palette.background.paper,
        '& [data-tour-elem="controls"]': {
            justifyContent: "center"
        }
    },
    dark: {
        '&& [data-tour-elem$="arrow"]': {
            color: "#bfbfbf"
        },
        '&& [data-tour-elem$="arrow"]:hover': {
            color: "#fff"
        },
    },
    light: {
    },
}))


export const MaterialTour: FunctionComponent<ReactourProps> = (props) => {
    const classes = useStyles()
    const themeOption = useSelector((state: IStoreState) => state.theme)
    const theme = useTheme()

    return (
        <Tour
            {...props}
            rounded={16}
            accentColor={theme.palette.primary.main}
            className={clsx({ [classes.helper]: true, [classes.dark] : themeOption === 'dark', [classes.light]: themeOption === 'light' })}
            startAt={0}
            disableInteraction={true} />
    )
}

MaterialTour.defaultProps = {
}