import { ActionTypes } from "./actions"
import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, LOGOUT, REMOVE_SNACKBAR, SET_API_FAILURE, SET_API_SUCCESS, SET_APPLICATIONS, SET_LOADING, SET_LOCALE, SET_REFRESHING_TOKEN, SET_SERVICE, SET_USER, START_TUTORIAL, STOP_TUTORIAL, SWITCH_THEME, SWITCH_VISUALIZATION } from "./constants"
import { IStoreState } from "./types"

export function reducers(state: IStoreState, action: ActionTypes): IStoreState {
    switch (action.type) {
        case SET_USER:
            return { ...state, user: action.user }
        case LOGOUT:
            delete state.user
            return { ...state }
        case SET_LOCALE:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    locale: action.locale
                }
            }
        case SWITCH_VISUALIZATION:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    preferredVisualization: action.visualization
                }
            }
        case SWITCH_THEME:
            return {
                ...state,
                theme: state.theme === "light" ? "dark" : "light"
            }
        case START_TUTORIAL:
            return {
                ...state,
                tutorial: {
                    open: true,
                    steps: action.steps
                }
            }
        case SET_REFRESHING_TOKEN:
            return state.user ? {
                ...state,
                refreshingToken: action.refreshing
            } : state
        case STOP_TUTORIAL:
            return {
                ...state,
                tutorial: {
                    ...state.tutorial,
                    open: false
                }
            }
        case SET_SERVICE:
            delete state.error
            return {
                ...state,
                service: action.service
            }
        case SET_APPLICATIONS:
            return {
                ...state,
                apps: action.apps
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.pending
            }
        case SET_API_SUCCESS:
            delete state.error
            return {
                ...state,
                data: action.data
            }
        case SET_API_FAILURE:
            delete state.data
            return {
                ...state,
                error: action.error
            }
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...action.notification
                    }
                ]
            }
        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ))
            }
        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key
                )
            }
    }
    return state
}