import React, { FunctionComponent } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { Avatar, Grid, Typography } from "@material-ui/core"
import { homeCards } from "../utils/config"
import { useIntl } from "react-intl"
import { HomeCard } from "../components/HomeCard"
import { useSelector } from "react-redux"
import { IStoreState, IUser } from "../store/types"
import { stringToColor } from "../utils"
import { checkPermissions } from "../utils/acl"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    },
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        fontSize: "44px",
        marginBottom: theme.spacing(1),
        width: theme.spacing(12),
        height: theme.spacing(12)
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    }
}))

type HomeProps = {
    onChangeIndex: (index: number) => void
}

export const Home: FunctionComponent<HomeProps> = (props) => {
    const classes = useStyles()
    const { formatMessage: intl } = useIntl()
    const user = useSelector<IStoreState, IUser | undefined>((state) => state.user)
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    

    return (
        <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
            <Avatar className={ classes.avatar } style={ { backgroundColor: stringToColor(user?.email || "") } }>
                { (user && user?.firstName && user?.firstName.charAt(0).toUpperCase()) || "" }
            </Avatar>
            <Typography variant="h4">
                { intl({ id: "home.title" }, { user: `${ user?.firstName } ${ user?.lastName }` }) }
            </Typography>
            <Typography variant="h6" className={ classes.caption }>
                { intl({ id: "home.subtitle" }, { orgName: user?.jwtPayload?.org }) }
            </Typography>
            <Grid container justify="space-between" spacing={ 1 }>
                { homeCards.filter(card => checkPermissions(card.acl, "page", permissions || [])).map(homeCard => (
                        <Grid key={ homeCard.id } item md={ 6 } xs={ 12 }>
                            <HomeCard onChangeIndex={ props.onChangeIndex } homeCard={ homeCard }/>
                        </Grid>
                    )
                ) }
            </Grid>
        </Container>

    )
}