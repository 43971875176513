export const overviewSteps = (intl: any) => [
    {
        content: intl({ id: "help.overview.welcome" })
    },
    {
        selector: "[data-tut=\"overview.home\"]",
        content: intl({ id: "help.overview.home" })
    },
    {
        selector: "[data-tut=\"overview.profile\"]",
        content: intl({ id: "help.overview.profile" })
    },
    {
        selector: "[data-tut=\"overview.organizations\"]",
        content: intl({ id: "help.overview.organizations" })
    },
    {
        selector: "[data-tut=\"overview.applications\"]",
        content: intl({ id: "help.overview.applications" })
    },
    {
        selector: "[data-tut=\"overview.roles\"]",
        content: intl({ id: "help.overview.roles" })
    },
    {
        selector: "[data-tut=\"overview.users\"]",
        content: intl({ id: "help.overview.users" })
    },
    {
        selector: "[data-tut=\"overview.services\"]",
        content: intl({ id: "help.overview.services" })
    },
    {
        content: intl({ id: "help.overview.final" })
    }
]

export const orgViewerSteps = (intl: any, visualizationType: "cards" | "table") =>
    visualizationType === "cards" ?
        [
            {
                content: intl({ id: "help.orgOverview.welcome" })
            },
            {
                selector: "[data-tut=\"organizations.visualizationSwitch\"]",
                content: intl({ id: "help.orgOverview.changeVis" }),
                stepInteraction: true
            },
            {
                selector: "[data-tut=\"organizations.invite\"]",
                content: intl({ id: "help.orgOverview.invite" })
            },
            {
                selector: "[data-tut=\"organizations.header\"]",
                content: intl({ id: "help.orgOverview.info.one" })
            },
            {
                selector: "[data-tut=\"organizations.card\"]",
                content: intl({ id: "help.orgOverview.details" })
            },
            {
                selector: "[data-tut=\"organizations.card\"]",
                content: intl({ id: "help.orgOverview.edit" })
            },
            {
                selector: "[data-tut=\"organizations.card\"]",
                content: intl({ id: "help.orgOverview.disable" })
            },
            {
                content: intl({ id: "help.orgOverview.final" })
            }
        ] : [
            {
                content: intl({ id: "help.orgOverview.welcome" })
            },
            {
                selector: "[data-tut=\"organizations.visualizationSwitch\"]",
                content: intl({ id: "help.orgOverview.changeVis" }),
                stepInteraction: true
            },
            {
                selector: "[data-tut=\"organizations.invite\"]",
                content: intl({ id: "help.orgOverview.invite" })
            },
            {
                selector: "tbody > .MuiTableRow-root",
                content: intl({ id: "help.orgOverview.info.one" })
            },
            {
                selector: "[title=\"View details row\"]",
                content: intl({ id: "help.orgOverview.details" })
            },
            {
                selector: "[title=\"View details row\"]",
                content: intl({ id: "help.orgOverview.edit" })
            },
            {
                content: intl({ id: "help.orgOverview.final" })
            }
        ]

export const inviteOrgSteps = (intl: any, activeStep: number) => {
    switch (activeStep) {
        case 0:
            return [
                {
                    content: intl({ id: "help.orgInvite.welcome" })
                },
                {
                    selector: "[data-tut=\"organizations.invite.name\"]",
                    content: intl({ id: "help.orgInvite.name" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"organizations.invite.emails\"]",
                    content: intl({ id: "help.orgInvite.emails" }),
                    stepInteraction: true
                },

                {
                    content: intl({ id: "help.forms.nextIfReady" })
                }]
        case 1:
            return [
                {
                    selector: "[data-tut=\"organizations.invite.apps\"]",
                    content: intl({ id: "help.orgInvite.apps" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"organizations.invite.tags\"]",
                    content: intl({ id: "help.orgInvite.tags" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"organizations.invite.roles\"]",
                    content: intl({ id: "help.orgInvite.roles" }),
                    stepInteraction: true
                },
                {
                    content: intl({ id: "help.forms.nextIfReady" })
                }
            ]
        default:
            return []
    }
}

export const updateOrgSteps = (intl: any, activeStep: number) => {
    switch (activeStep) {
        case 0:
            return [
                {
                    content: intl({ id: "help.orgInvite.welcome" })
                },
                {
                    selector: "[data-tut=\"organizations.invite.name\"]",
                    content: intl({ id: "help.orgInvite.name" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"organizations.invite.emails\"]",
                    content: intl({ id: "help.orgInvite.emails" }),
                    stepInteraction: true
                },

                {
                    content: intl({ id: "help.forms.nextIfReady" })
                }]
        case 1:
            return [
                {
                    selector: "[data-tut=\"organizations.invite.apps\"]",
                    content: intl({ id: "help.orgInvite.apps" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"organizations.invite.tags\"]",
                    content: intl({ id: "help.orgInvite.tags" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"organizations.invite.roles\"]",
                    content: intl({ id: "help.orgInvite.roles" }),
                    stepInteraction: true
                },
                {
                    content: intl({ id: "help.forms.nextIfReady" })
                }
            ]
        default:
            return []
    }
}

export const appViewerSteps = (intl: any) => [{
        content: intl({ id: "help.appViewer.welcome" })
    },
    {
        selector: "[data-tut=\"applications.detailsBtn\"]",
        content: intl({ id: "help.appViewer.details" })
    },
    {
        selector: "[data-tut=\"applications.editBtn\"]",
        content: intl({ id: "help.appViewer.edit" })
    },
    {
        selector: "[data-tut=\"applications.disableBtn\"]",
        content: intl({ id: "help.appViewer.disable" })
    },
    {
        selector: "[data-tut=\"applications.goToBtn\"]",
        content: intl({ id: "help.appViewer.goTo" })
    },
    {
        content: intl({ id: "help.appViewer.final" })
    }]

export const appCreatorSteps = (intl: any, activeStep: number) => {
    switch (activeStep) {
        case 0:
            return [
                {
                    content: intl({ id: "help.appCreate.welcome" })
                },
                {
                    selector: "[data-tut=\"app.create.name\"]",
                    content: intl({ id: "help.appCreate.name" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"app.create.appUrl\"]",
                    content: intl({ id: "help.appCreate.appUrl" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"app.create.supportEmail\"]",
                    content: intl({ id: "help.appCreate.supportEmail" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"app.create.termsURL\"]",
                    content: intl({ id: "help.appCreate.termsUrl" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"app.create.policyURL\"]",
                    content: intl({ id: "help.appCreate.policyUrl" }),
                    stepInteraction: true
                },
                {
                    content: intl({ id: "help.forms.nextIfReady" })
                }]
        case 1:
            return [
                {
                    selector: "[data-tut=\"app.create.visTags\"]",
                    content: intl({ id: "help.appCreate.visTags" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"app.create.macroFunction\"]",
                    content: intl({ id: "help.appCreate.macroFunction" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"app.create.permissions\"]",
                    content: intl({ id: "help.appCreate.permissions" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"app.create.addFunction\"]",
                    content: intl({ id: "help.appCreate.addFunction" }),
                    stepInteraction: true
                },
                {
                    content: intl({ id: "help.forms.nextIfReady" })
                }
            ]
        case 2:
            return [
                {
                    selector: "[data-tut=\"app.create.roleName\"]",
                    content: intl({ id: "help.appCreate.roleName" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"app.create.rolePermissions\"]",
                    content: intl({ id: "help.appCreate.rolePermissions" }),
                    stepInteraction: true
                },
                {
                    selector: "[data-tut=\"app.create.addRole\"]",
                    content: intl({ id: "help.appCreate.addRole" }),
                    stepInteraction: true
                },
                {
                    content: intl({ id: "help.forms.nextIfReady" })
                }
            ]
        default:
            return []
    }
}

export const userViewerSteps = []

export const userCreatorSteps = []

export const clientViewerSteps = []

export const clientCreatorSteps = []