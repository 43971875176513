import React, { BaseSyntheticEvent, ChangeEvent, forwardRef, FunctionComponent, useEffect, useState } from "react"
import PermissionsIcon from "@material-ui/icons/LockOpenOutlined"
import GeneralInfoIcon from "@material-ui/icons/AssignmentOutlined"
import ReviewIcon from "@material-ui/icons/AssignmentTurnedInOutlined"
import { Grid, Slide, TextField, Typography } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { useIntl } from "react-intl"
import { FormWizardDialog } from "../components/FormWizardDialog"
import { useFormStyles } from "./utils"
import { useDispatch, useSelector } from "react-redux"
import { enqueueSnackBar, setLoading, startTutorial } from "../store/actions"
import { appCreatorSteps } from "../tutorials"
import { IStoreState } from "../store/types"
import { IAPIError, IAPIPermission } from "../api/types"
import { ICreateRoleValues } from "./types"
import apiService from "../api"
import { AutocompletePermissions } from "../components/AutocompletePermissions"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ ref } { ...props } />
})

type RolesFormProps = {
    open: boolean
    onClose: (done: boolean) => void
}

export const RolesForm: FunctionComponent<RolesFormProps> = (props) => {
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const idOrganization = useSelector<IStoreState, string | undefined>(state => state.user?.jwtPayload?.ido)

    useEffect(() => {
        setTimeout(() => {
            const elements = document.querySelectorAll("#___reactour button")
            elements.forEach((el: any) => (el.tabIndex = -1))
        }, 100)
    })
    const { register, unregister, handleSubmit, errors, watch, setValue, triggerValidation } = useForm<ICreateRoleValues>({
        defaultValues: {
            permissions: []
        }
    })
    const dispatch = useDispatch()
    const { formatMessage: intl } = useIntl()
    const fields = watch({ nest: true })
    const classes = useFormStyles()
    const [activeStep, setActiveStep] = useState(0)

    useEffect(() => {
        register({ name: "name" }, {
            required: "Required"
        })
        register({ name: "description" }, { required: "Required" })
        register({ name: "permissions" }, {
            validate: permissions => permissions.length > 0 || "Required"
        })
        return () => unregister(["name", "description", "permissions"])
    }, [register, unregister])

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = async () => {
        let result
        switch (activeStep) {
            case 0:
                await triggerValidation(["name", "description"])
                result = errors.name || errors.description
                break
            case 1:
                await triggerValidation(["permissions"])
                result = errors.permissions
                break
        }
        if (!result) setActiveStep(activeStep + 1)
    }

    const handleReset = () => {
        reset()
        setActiveStep(0)
    }

    const reset = () => {
        setValue("name", "")
        setValue("description", "")
        setValue("permissions", [])
    }

    const onSubmit = async (data: ICreateRoleValues, e?: BaseSyntheticEvent) => {

        //This is a hotfix after the 17th of May release, ultimately the underlying problem should be taken care of at the BE,
        // remove this loop after that happens
        data.permissions.forEach(p => {
            delete p["created"]
        })
        
        e?.preventDefault()
        if (idOrganization) {
            dispatch(setLoading(true))
            apiService.createRole({
                idOrganization: idOrganization,
                description: data.description, name: data.name, permissions: data.permissions
            }).then((response) => {
                    const data = response.data
                    reset()
                    dispatch(enqueueSnackBar({
                        key: data.idRole || "", message: "Data Correctly Saved!", options: {
                            preventDuplicate: false, variant: "success"
                        }
                    }))
                    props.onClose(true)
                }
            ).catch((ex: IAPIError) => {
                    dispatch(enqueueSnackBar({
                        key: ex.code || "", message: "Ops! Something went wrong", options: {
                            preventDuplicate: false, variant: "error"
                        }
                    }))
                }
            ).finally(() => dispatch(setLoading(false)))
        }

    }

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={ 2 } className={ classes.stepContainer }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>Insert your application's
                                general information</Typography>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                            <TextField
                                data-tut="role.create.name"
                                color="primary"
                                variant="outlined"
                                label="Role's Name"
                                size="small"
                                placeholder="Role's Name"
                                fullWidth
                                value={ fields.name || ""}
                                onChange={ e => {
                                    setValue("name", e.target.value)
                                } }
                                error={ !!errors.name }
                                helperText={ (!!errors.name && (errors.name as any).message) || " " }
                            />
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 8 }>
                            <TextField
                                data-tut="role.create.description"
                                variant="outlined"
                                fullWidth
                                required
                                color="primary"
                                size="small"
                                label="Description"
                                onChange={ (e) => {
                                    setValue("description", e.target.value)
                                } }
                                value={ fields.description || ""}
                                error={ !!errors.description }
                                helperText={ (!!errors.description && (errors.description as any).message) || " " }
                            />
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container className={ classes.stepContainer } spacing={ 0 }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>Insert your application's
                                permissions</Typography>
                        </Grid>
                        <Grid item className={ classes.smallBottomMargin } xs={ 12 }>
                            <AutocompletePermissions
                                value={ fields.permissions }
                                onChange={ (e: ChangeEvent<{}>, value: IAPIPermission[]) => {
                                    setValue("permissions", value)
                                } }
                                error={ errors.permissions }
                            />
                        </Grid>
                    </Grid>)
            case 2:
                return (
                    <Grid container className={ classes.stepContainer } spacing={ 2 }>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">You are going to create the
                                role: <strong>{ fields.name }</strong></Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography
                                variant="h6">Description:<strong>{ fields.description || "n/a" }</strong></Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6"><strong>{ fields.name }</strong> will have the following
                                Visibility
                                Tags:</Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">The following permissions:</Typography>
                        </Grid>
                        {
                            fields.permissions.map((permission, index) => {
                                return (
                                    <Grid key={ permission.path + "_" + index } item xs={ 12 }>
                                        <Typography
                                            variant="h6"><strong>{ permission.path }</strong>
                                        </Typography>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            default:
                return "Unknown step"
        }
    }

    return (
        <>
            <FormWizardDialog Transition={ Transition } open={ props.open } fullscreen
                              onDialogClose={ () => {
                                  reset()
                                  props.onClose(false)
                              } }
                              onDialogCancel={ () => {
                                  reset()
                                  props.onClose(false)
                              } }
                              handleSubmit={ handleSubmit(onSubmit) } title="Create a new Role"
                              steps={ [{ label: "General Info", icon: <GeneralInfoIcon/> }, {
                                  label: "Permissions & Visibility Tags", icon: <PermissionsIcon/>
                              }, { label: "Review", icon: <ReviewIcon/> }] }
                              getStepContent={ getStepContent }
                              hasTutorial={ activeStep <= 2 }
                              loading={ loading }
                              onOpenTutorial={ () => dispatch(startTutorial(appCreatorSteps(intl, activeStep))) }
                              activeStep={ activeStep }
                              handleBack={ handleBack }
                              handleNext={ handleNext }
                              handleReset={ handleReset }>
            </FormWizardDialog>
        </>
    )
}
