import React, {FunctionComponent, useEffect, useState } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { useHistory, useParams } from "react-router-dom"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from "@material-ui/core"
import { enqueueSnackBar, setLoading, startTutorial } from "../store/actions"
import { appViewerSteps } from "../tutorials"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { IAPIOrganization, IAPIUser } from "../api/types"
import apiService from "../api"
import { IStoreState, IUser } from "../store/types"
import { checkPermissions } from "../utils/acl"
import { LoadingButton } from "../components/LoadingButton"
import { ToggleStatusButton } from "../components/ToggleStatusButton"
import { DataTable } from "../components/DataTable"


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    mb: {
        marginBottom: theme.spacing(3)
    },
    caption: {},
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}))

const organizationsColumns = [
    { name: "name", title: "Name" },
    { name: "alias", title: "alias" }
]

type UserDetailsProps = {}

export const UserDetails: FunctionComponent<UserDetailsProps> = () => {
    const classes = useStyles()
    const { idUser } = useParams()
    const [user, setUser] = useState<IAPIUser | undefined>()
    const loggedInUser = useSelector<IStoreState, IUser | undefined>(state => state.user)
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const canEdit = checkPermissions("editOrganization", "function", permissions || [])
    const isCompanyAdmin =  useSelector<IStoreState, IUser | undefined>(state => state.user)?.jwtPayload?.admin
    const canDeleteUser = checkPermissions("deleteUser", "function", permissions || []) && idUser != loggedInUser?.idUserAccount;
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))
    const [openRemoveUserFromOrgDialog, setOpenRemoveUserFromOrgDialog] = useState(false)
    const [openRemoveUserDialog, setOpenRemoveUserDialog] = useState(false)
    const [internalLoading, setInternalLoading] = useState(false)
    const [selectedOrg, setSelectedOrg] = useState<IAPIOrganization | undefined>()

    let history = useHistory()

    const removeUserOrganization = async () => {
        try {
            setInternalLoading(true)
            await apiService.removeUserOrganization( selectedOrg?.idOrganization || "", user?.idUserAccount || "" )
            dispatch(enqueueSnackBar(
                { key: user?.idUserAccount || "", message: "User Succesfully removed from the Organization", options: { persist: false, preventDuplicate: false, variant: "success" } }
            ))
            history.push("/users")
        } catch {
            dispatch(enqueueSnackBar(
                { key: user?.idUserAccount || "", message: "Impossible to remove User from Organization", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        handleClose()
    }

    const removeUser = async () => {
        try {
            setInternalLoading(true)
            await apiService.removeUser( user?.idUserAccount || "" )
            dispatch(enqueueSnackBar(
                { key: user?.idUserAccount || "", message: "User succesfully removed", options: { persist: false, preventDuplicate: false, variant: "success" } }
            ))
            history.push("/users")
        } catch {
            dispatch(enqueueSnackBar(
                { key: user?.idUserAccount || "", message: "Impossible to remove User", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        handleClose()
    }

    const handleClose = () => {
        setInternalLoading(false)
        setOpenRemoveUserFromOrgDialog(false)
    }

    const loadUser = () => {
        if (idUser) {
            dispatch(setLoading(true))
            apiService.getUser(idUser).then((response) => {
                setUser(response.data)
            }).catch(ex => console.log(ex)).finally(() => dispatch(setLoading(false)))
        }
    }

    useEffect(() => {
        loadUser()
    }, [])

    return (
        <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
            { loading ? (<Typography>Loading data...</Typography>) : (<>
                <Typography className={ classes.mt }
                            variant="h4">{ user?.firstName } { user?.lastName }</Typography>
                <Typography className={ classes.mt } variant="subtitle1">{ user?.email }</Typography>
                <Grid container justify="center" className={ classes.mt }>
                    <Grid item>
                        { canDeleteUser && (
                            <ToggleStatusButton style={ { marginLeft: 10 } } data-tut="organizations.toggleStatus"
                                                action="disable"
                                                onClick={ () => setOpenRemoveUserDialog(true) }>Remove</ToggleStatusButton>) }
                        <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                                onClick={ () => dispatch(startTutorial(appViewerSteps(intl))) }>{ intl({ id: "functions.help" }) }</Button>
                    </Grid>
                </Grid></>) }
                <DataTable<IAPIOrganization> columns={ organizationsColumns }
                                rows={ user?.organizations }
                                title="User's Organizations"
                                idField="idOrganization"
                                pageSize={ 10 }
                                loading={ loading }
                                totalCount={ user?.organizations?.length }
                                showDeleteButton={
                                    (row: IAPIOrganization) => isCompanyAdmin || (canEdit && row.idOrganization === loggedInUser?.jwtPayload?.ido)
                                }
                                showDetailsButton= {
                                    (row: IAPIOrganization) => isCompanyAdmin || (row.idOrganization === loggedInUser?.jwtPayload?.ido)
                                }
                                onDeleteRow={ (org) => {
                                    setSelectedOrg(org)
                                    setOpenRemoveUserFromOrgDialog(true)
                                } }
                                // onDetailRow={ (org) => history.push(`/organizations/${ org.idOrganization }`) }
                                onDetailRow={ (org) => history.push(`/organizations/${ org.idOrganization }/users/${ idUser }?organizationName=${ org.name}`) }
                                defaultSorting={ [{ columnName: "name", direction: "asc" }] }/>
            
            <br/>
            <Dialog
            //Remove user from organisation dialog
                fullScreen={ fullScreen }
                open={ openRemoveUserFromOrgDialog }
                onClose={ handleClose }
                aria-labelledby="remove-user-from-org-dialog-title"
            >
                <DialogTitle id="remove-user-from-org-dialog-title">Do you want
                    to remove the user from the organization?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that the
                        user won't be able
                        to authenticate with this organization.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleClose }>
                        Cancel
                    </Button>
                    <LoadingButton type="button" onClick={ removeUserOrganization } loading={ internalLoading }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
            //Remove user altogether
                fullScreen={ fullScreen }
                open={ openRemoveUserDialog }
                onClose={ handleClose }
                aria-labelledby="remove-user-altogether-dialog-title"
            >
                <DialogTitle id="remove-user-altogether-dialog-title">Do you want
                    to remove the user altogether?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that the user will be removed from the system.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleClose }>
                        Cancel
                    </Button>
                    <LoadingButton type="button" onClick={ removeUser } loading={ internalLoading }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    )
}