import React, { ChangeEvent, FunctionComponent } from "react"
import { Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, TextField, Typography } from "@material-ui/core"
import { useIntl } from "react-intl"
import { IInviteOrganizationValues, IRole, ITag } from "./types"
import { useFormStyles } from "./utils"
import { Autocomplete } from "@material-ui/lab"
import { FieldErrors } from "react-hook-form"

type OrgPermissionsStepProps = {
    onChange: (field: string, value: IRole[] | ITag[], validate?: boolean) => void
    fields: IInviteOrganizationValues
    errors: FieldErrors<IInviteOrganizationValues>
    handleNewOrgAppsChange: (index: number) => () => void
    tagOptions: ITag[] | undefined
    roleOptions: IRole[] | undefined
}


export const OrgPermissionsStep: FunctionComponent<OrgPermissionsStepProps> = (props) => {
    const { formatMessage: intl } = useIntl()
    const classes = useFormStyles()
    const { fields, errors, handleNewOrgAppsChange, tagOptions, roleOptions, onChange } = props

    return (
        <Grid container spacing={ 2 } className={ classes.stepContainer }>
            <Grid item container justify="center" spacing={ 2 }>
                <Typography variant="h5" className={ classes.verticalMargin }>
                    { intl({ id: "organizations.forms.second.description" }) }
                </Typography>
            </Grid>
            <Grid item xs={ 12 }>
                <Typography variant="h6">
                    { intl({ id: "organizations.forms.apps" }) }
                </Typography>
            </Grid>
            <Grid item xs={ 12 }>
                <FormControl className={ classes.formControl } data-tut="organizations.invite.apps">
                    <FormGroup row>
                        { fields.applications?.map((app, idx) => (
                            <FormControlLabel
                                key={ app.idApplication }
                                control={ <Checkbox checked={ app.available }
                                                    color="primary"
                                                    disabled={ app.name === "Auth" }
                                                    onChange={ handleNewOrgAppsChange(idx) }
                                                    value={ app.idApplication }/> }
                                label={ app.name }
                            />
                        )) }
                    </FormGroup>
                    <FormHelperText> { intl({ id: "organizations.forms.appsDisclaimer" }, { apps: fields.applications?.filter(app => app.available).map(app => app.name).join(",") }) } </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item className={ classes.grow }>
                <Autocomplete<ITag>
                    data-tut="organizations.invite.tags"
                    multiple
                    id="tags-outlined"
                    options={ tagOptions ? tagOptions?.filter(tag => {
                        // only not selected
                        return fields.visibilityTags?.findIndex(selectedTag => selectedTag.id === tag.id) === -1
                    }) : [] }
                    groupBy={ option => option.appName }
                    value={ fields.visibilityTags || [] }
                    getOptionLabel={ option => option.tag }
                    filterSelectedOptions
                    renderTags={ (value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                label={ option.appName + ": " + option.tag } { ...getTagProps({ index }) }/>
                        ))
                    }
                    onChange={ (e: ChangeEvent<{}>, value: ITag[]) => {
                        onChange("visibilityTags", value)
                    } }
                    className={ classes.formControl }
                    renderInput={ params => (
                        <TextField
                            color="primary"
                            { ...params }
                            variant="outlined"
                            label={ intl({ id: "organizations.forms.visTags" }) }
                            fullWidth
                            name="tags"
                            error={ !!errors.visibilityTags }
                            helperText={ (!!errors.visibilityTags && (errors.visibilityTags as any).message) || " " }
                        />
                    ) }
                />
            </Grid>
            <Grid item xs={ 12 }>
                <FormControl className={ classes.formControl }>
                    <FormGroup row>
                        <FormControlLabel
                            className={ classes.formControl }
                            control={ <Autocomplete<IRole>
                                data-tut="organizations.invite.roles"
                                multiple
                                id="roles-outlined"
                                options={ roleOptions ? roleOptions?.filter(role => {
                                    // only not selected
                                    return fields.roles?.findIndex(selectedRole => selectedRole.idRole === role.idRole) === -1
                                }) : [] }
                                groupBy={ option => option.app }
                                value={ fields.roles || [] }
                                onChange={ (e: ChangeEvent<{}>, value: IRole[]) => {
                                    onChange("roles", value)
                                } }
                                getOptionLabel={ option => option.name }
                                disableClearable
                                filterSelectedOptions
                                renderTags={ (value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={ option.name } { ...getTagProps({ index }) }/>
                                    ))
                                }
                                className={ classes.formControl }
                                renderInput={ params => (
                                    <TextField
                                        color="primary"
                                        { ...params }
                                        variant="outlined"
                                        label={ intl({ id: "organizations.forms.roles" }) }
                                        fullWidth
                                        name="roles"
                                        error={ !!errors.roles }
                                        helperText={ (!!errors.roles && (errors.roles as any).message) || intl({ id: "organizations.forms.roles.predefined" }) }
                                    />
                                ) }
                            /> }
                            label=""
                        />
                    </FormGroup>
                    <FormHelperText>{ intl({ id: "organizations.forms.roleDisclaimer" }) }</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    )
}