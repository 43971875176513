import React, { FunctionComponent, useEffect, useState } from "react"
import PermissionsIcon from "@material-ui/icons/LockOpenOutlined"
import GeneralInfoIcon from "@material-ui/icons/AssignmentOutlined"
import RolesIcon from "@material-ui/icons/PersonOutlineOutlined"
import ReviewIcon from "@material-ui/icons/AssignmentTurnedInOutlined"
import { Grid, Slide, Theme, Typography } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { slugify } from "../utils"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { makeStyles } from "@material-ui/core/styles"
import { FormWizardDialog } from "../components/FormWizardDialog"
import { enqueueSnackBar, startTutorial } from "../store/actions"
import { appCreatorSteps } from "../tutorials"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import apiService from "../api"
import { ICreateApplicationRole, ICreateApplicationValues, IFeature, IFeatureError, IRoleError } from "./types"
import { PermissionsStep } from "./PermissionsStep"
import { RolesStep } from "./RolesStep"
import { AutocompleteText } from "../components/AutocompleteText"
import { createPermissionPath, generateAPIPermissions } from "./utils"
import { AppInfoStep } from "./AppInfoStep"
import { AppReviewStep } from "./AppReviewStep"

const useStyles = makeStyles((theme: Theme) => ({
    grow: {
        flexGrow: 1
    },
    stepContainer: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    verticalMargin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    inputDistance: {
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(0)
        }
    },
    roleContainer: {
        marginBottom: theme.spacing(2)
    },
    smallBottomMargin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0)
    },
    atLeastOneError: {
        color: "red",
        marginLeft: theme.spacing(1)
    }
}))

export const useApplicationFormRegister = (register: any, unregister: any, intl: any, setFeatureErrors: any, setRoleErrors: any) => {
    useEffect(() => {
        register({ name: "name" }, {
            required: intl({ id: "forms.errors.required" })
        })
        register({ name: "supportEmail" }, {
            validate: (email: string) => {
                if (email) {
                    const pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
                    return pattern.test(email) || intl({ id: "forms.errors.email" })
                } else return true

            }
        })
        register({ name: "appUrl" }, {
            validate: (appUrl: string) => {
                if (appUrl) {
                    const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
                    return pattern.test(appUrl) || intl({ id: "forms.errors.url" })
                } else return true
            }
        })
        register({ name: "termsUrl" }, {
            validate: (termsUrl: string) => {
                if (termsUrl) {
                    const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
                    return pattern.test(termsUrl) || intl({ id: "forms.errors.url" })
                } else return true
            }
        })
        register({ name: "policyUrl" }, {
            validate: (policyUrl: string) => {
                if (policyUrl) {
                    const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
                    return pattern.test(policyUrl) || intl({ id: "forms.errors.url" })
                } else return true
            }
        })
        register({ name: "description" }, {})
        register({ name: "visTags" }, {})
        register({ name: "features" }, {
            validate: (features: any[]) => {
                const errors = features.reduce((acc: any, feature: IFeature, idx: number) => {
                    if (feature.macroFunction === "") acc[idx] = { macroFunction: true }
                    if (feature.functions.length === 0) acc[idx] = { ...acc[idx], functions: true }
                    return acc
                }, {})
                setFeatureErrors(errors)
                return Object.keys(errors).length === 0
            }
        })
        register({ name: "roles" }, {
            validate: (roles: any[]) => {
                let errors
                if (roles.length === 0) {
                    errors = [{ required: true }]
                } else {
                    errors = roles.reduce((acc: any, role: ICreateApplicationRole, idx: number) => {
                        if (role.name === "") acc[idx] = { name: true }
                        if (role.permissions.length === 0) acc[idx] = { ...acc[idx], permissions: true }
                        return acc
                    }, [])
                }
                setRoleErrors(errors)
                return Object.keys(errors).length === 0
            }
        })
        return () => unregister(["name", "appUrl", "supportEmail", "termsUrl", "policyUrl", "description", "visTags", "features", "roles"])
    }, [])

    return true
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ ref } { ...props } />
})

type ApplicationsFormProps = {
    open: boolean
    onClose: (done: boolean) => void
}

export const ApplicationsForm: FunctionComponent<ApplicationsFormProps> = (props) => {
    useEffect(() => {
        setTimeout(() => {
            const elements = document.querySelectorAll("#___reactour button")
            elements.forEach((el: any) => (el.tabIndex = -1))
        }, 100)
    })

    const { register, unregister, handleSubmit, errors, watch, setValue, triggerValidation } = useForm<ICreateApplicationValues>({
        defaultValues: {
            roles: [{ name: "Admin", description: "With this role you should do anything", permissions: [{ macroFunction: "All", permission: "All" }] }],
            features: [{ macroFunction: "All", functions: ["All"] }]
        }
    })
    const dispatch = useDispatch()
    const { formatMessage: intl } = useIntl()
    const fields = watch({ nest: true })
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [featureErrors, setFeatureErrors] = useState<IFeatureError[]>([])
    const [roleErrors, setRoleErrors] = useState<IRoleError[]>([])

    const updateVisTags = (value: any) => {
        setValue("visTags", value)
    }

    const onSubmit = async (data: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault()
        if (!loading) {
            setLoading(true)
            try {
                const { data } = await apiService.createApplication({
                    description: fields.description,
                    name: fields.name,
                    permissions: generateAPIPermissions(fields.name, fields.features),
                    predefinedRoles: fields.roles.map((role) => {
                        return {
                            ...role,
                            permissions: role.permissions.map(permission => {
                                return { path: createPermissionPath(fields.name, permission.macroFunction, permission.permission) }
                            })
                        }
                    }),
                    privacyUrl: fields.policyUrl,
                    slug: slugify(fields.name),
                    supportEmail: fields.supportEmail,
                    termUrl: fields.termsUrl,
                    visibilityTags: fields.visTags || []
                })
                dispatch(enqueueSnackBar({
                    key: data.idApplication || "", message: "Data Correctly Saved!", options: {
                        preventDuplicate: false, variant: "success"
                    }
                }))
                reset()
                props.onClose(true)
            } catch (e) {
                dispatch(enqueueSnackBar({
                    key: e.code || "", message: "Ops! Something went wrong", options: {
                        preventDuplicate: false, variant: "error"
                    }
                }))

            }
            setLoading(false)
        }
    }

    const reset = () => {
        setValue("name", "")
        setValue("description", "")
        setValue("privacyUrl", "")
        setValue("supportEmail", "")
        setValue("appUrl", "")
        setValue("termUrl", "")
        setValue("features", [{ macroFunction: "All", functions: ["All"] }])
        setValue("roles", [{ name: "Admin", description: "With this role you should do anything", permissions: [{ macroFunction: "All", permission: "All" }] }])
        setValue("visTags", [])
    }

    useApplicationFormRegister(register, unregister, intl, setFeatureErrors, setRoleErrors)

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = async () => {
        let result
        switch (activeStep) {
            case 0:
                await triggerValidation(["name", "supportEmail", "appUrl", "termsUrl", "policyUrl", "description"])
                result = errors.name || errors.appUrl || errors.supportEmail || errors.termsUrl || errors.policyUrl || errors.description
                break
            case 1:
                await triggerValidation(["visTags", "features"])
                result = errors.visTags || errors.features
                break
            case 2:
                await triggerValidation(["roles"])
                result = errors.roles
                break
        }
        if (!result) setActiveStep(activeStep + 1)
    }

    const handleReset = () => {
        setActiveStep(0)
        reset()
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={ 2 } className={ classes.stepContainer }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>
                                { intl({ id: "applications.forms.first.title" }) }
                            </Typography>
                        </Grid>
                        <AppInfoStep fields={ fields }
                                     errors={ errors }
                                     onChange={ (field, value, validate) => setValue(field, value, validate) }/>
                    </Grid>
                )

            case 1:
                return (
                    <Grid container className={ classes.stepContainer } spacing={ 0 }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>
                                { intl({ id: "applications.forms.second.description" }) }
                            </Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <AutocompleteText
                                value={ fields.visTags || [] }
                                onChange={ (e, value) => {
                                    updateVisTags(value)
                                } }
                                label={ intl({ id: "applications.forms.visTags" }) }
                                placeholder="Tag"
                                name="tags"
                                error={ errors.visTags }
                            />
                        </Grid>
                        <PermissionsStep onChange={ (features) => setValue("features", features) }
                                         features={ fields.features } error={ featureErrors }/>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container className={ classes.stepContainer } justify="center" spacing={ 2 }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>
                                { intl({ id: "applications.forms.third.description" }) }
                            </Typography>
                        </Grid>
                        <RolesStep onChange={ (roles) => setValue("roles", roles) } roles={ fields.roles }
                                   features={ fields.features } error={ roleErrors }/>
                    </Grid>
                )
            case 3:
                return <AppReviewStep fields={ fields }/>
            default:
                return "Unknown step"
        }
    }

    return (
        <>
            <FormWizardDialog Transition={ Transition }
                              open={ props.open }
                              fullscreen={ true }
                              onDialogClose={ () => {
                                  reset()
                                  props.onClose(false)
                              } }
                              onDialogCancel={ () => {
                                  reset()
                                  props.onClose(false)
                              } }
                              handleSubmit={ handleSubmit(onSubmit) } title="Create a new Application"
                              steps={ [{
                                  label: intl({ id: "applications.forms.first.title" }), icon: <GeneralInfoIcon/>
                              }, {
                                  label: intl({ id: "applications.forms.second.title" }), icon: <PermissionsIcon/>
                              }, {
                                  label: intl({ id: "applications.forms.third.title" }), icon: <RolesIcon/>
                              }, { label: intl({ id: "forms.review" }), icon: <ReviewIcon/> }] }
                              getStepContent={ getStepContent }
                              hasTutorial={ activeStep <= 2 }
                              loading={ loading }
                              onOpenTutorial={ () => dispatch(startTutorial(appCreatorSteps(intl, activeStep))) }
                              activeStep={ activeStep }
                              handleBack={ handleBack }
                              handleNext={ handleNext }
                              handleReset={ handleReset }>
            </FormWizardDialog>
        </>
    )
}
