import React, { FunctionComponent, useEffect, useState, ChangeEvent } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { useParams, useHistory } from "react-router-dom"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { checkPermissions } from "../utils/acl"
import { IAPIPermission, IAPIRole, IAPIUser } from "../api/types"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from "@material-ui/core"
import apiService from "../api"
import { enqueueSnackBar, setLoading, startTutorial } from "../store/actions"
import { ToggleStatusButton } from "../components/ToggleStatusButton"
import { appViewerSteps } from "../tutorials"
import { DataTable } from "../components/DataTable"
import { LoadingButton } from "../components/LoadingButton"
import { FormDialog } from "../components/FormDialog"
import { useForm } from "react-hook-form"
import { AutocompletePermissions } from "../components/AutocompletePermissions"
import { useFormStyles } from "../forms/utils"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    mb: {
        marginBottom: theme.spacing(1)
    },
    caption: {},
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}))

const permissionsColumns = [
    { name: "macroFunction", title: "MacroFunction" },
    { name: "function", title: "Function" },
    { name: "path", title: "Path" },
    { name: "status", title: "Status", width: 100 }
]

const usersColumns = [
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "email", title: "email" },
    { name: "status", title: "Status" }
]

type RoleDetailsProps = {}

export const RoleDetails: FunctionComponent<RoleDetailsProps> = () => {
    const classes = useStyles()
    const formClasses = useFormStyles()
    const { id } = useParams()
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const canEdit = checkPermissions("editRole", "function", permissions || [])
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    const [role, setRole] = useState<IAPIRole | undefined>()
    const [rolesUsers, setRolesUsers] = useState<IAPIUser[] | undefined>()
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))
    const [openDialog, setOpenDialog] = useState(false)
    const [openEditRolesPermissionsDialog, setOpenEditRolesPermissionsDialog] = useState(false)
    const [internalLoading, setInternalLoading] = useState(false)
    
    let history = useHistory()

    const deleteRole = async () => {
        try {
            setInternalLoading(true)
            await apiService.deleteRole(role?.idRole || "")
            dispatch(enqueueSnackBar(
                { key: role?.idRole || "", message: intl({ id: "messages.roles.updateSuccessful" }), options: { persist: false, preventDuplicate: false, variant: "success" } }
            ))
            history.push("/roles")
        } catch {
            dispatch(enqueueSnackBar(
                { key: role?.idRole || "", message: intl({ id: "messages.roles.updateFailed" }), options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        setInternalLoading(false)
        handleClose()
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    const { triggerValidation, register, unregister, errors, setValue, watch } = useForm<IAPIRole>({
        defaultValues: {
            ...role,
            permissions: role?.permissions
        }
    })
    const fields = watch({ nest: true })

    const resetFields = () => {
        setValue("permissions", role?.permissions || [])
    }

    const handleSubmit = async () => {
        try {
            setInternalLoading(true)
            let validation = true
            await triggerValidation(["roles", "applications", "visibilityTags"])
            if (!errors.permissions) {
                await apiService.updateRolePermissions(id, fields.permissions || [])
            } 
            resetFields()
            dispatch(enqueueSnackBar(
                { key: id || "", message: intl({ id: "messages.general.updateSuccessful" }), options: { persist: false, preventDuplicate: false, variant: "success" } }
            ))
            setInternalLoading(false)
            setOpenEditRolesPermissionsDialog(false)
            loadRole();
        } catch {
            dispatch(enqueueSnackBar(
                { key: id || "", message: intl({ id: "messages.general.updateFailed" }), options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        setInternalLoading(false)
    }

    const loadRole = () => {
        if (id) {
            dispatch(setLoading(true))
            apiService.getRole(id).then((response) => {
                setRole(response.data)
                setValue("permissions", response.data.permissions)
            }).catch(ex => console.log(ex)).finally(() => dispatch(setLoading(false)))
        }
    }

    const loadRolesUsers = () => {
        if (id) {
            dispatch(setLoading(true))
            apiService.listUsersWithRoles({rolesIds: [id, id]}).then((response) => {
                setRolesUsers(response.data)
            }).catch(ex => console.log(ex)).finally(() => dispatch(setLoading(false)))
        }
    }

    useEffect(() => {
        loadRole()
        loadRolesUsers()
    }, [])

    useEffect(() => {
        register({ name: "permissions" }, {
            validate: (value: IAPIPermission[]) => (value && value.length > 0) || intl({ id: "forms.errors.required" })
        })
        return () => unregister(["permissions"])
    }, [])

    return (
        <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
            { loading ? (<Typography>Loading data...</Typography>) : (
            <>
                <Typography className={ classes.mt } variant="h4">{ role?.name } </Typography>
                <Grid container justify="center" className={ classes.mt }>
                    <Grid item>
                        { canEdit && (
                            <ToggleStatusButton style={ { marginLeft: 10 } } data-tut="organizations.toggleStatus"
                                                action="disable"
                                                disabled={role?.status === "DELETED"}
                                                onClick={ () => setOpenDialog(true) }>Delete</ToggleStatusButton>) }
                        <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                                onClick={ () => dispatch(startTutorial(appViewerSteps(intl))) }>{ intl({ id: "functions.help" }) }</Button>
                    </Grid>
                </Grid>
                <Typography variant="subtitle1" className={ classes.mt }>{ role?.description }</Typography>
                <DataTable<IAPIPermission> columns={ permissionsColumns }
                                           rows={ role?.permissions }
                                           idField="idPermission"
                                           descriptionField="description"
                                           title="Permissions"
                                           tableActions={
                                               <Button className={ classes.mb } variant="contained"
                                                       color="primary" size="small"
                                                       onClick={ () => setOpenEditRolesPermissionsDialog(true) }
                                                       disabled={role?.status === "DELETED"}
                                                       >
                                                   Edit Permissions
                                               </Button>
                                           }
                                           pageSize={ 10 }
                                           loading={ loading }
                                           totalCount={ role?.permissions.length }
                                           defaultSorting={ [{ columnName: "name", direction: "asc" }] }/>
                                           
                <DataTable<IAPIUser> columns={ usersColumns }
                                     rows={ rolesUsers }
                                     title="Users"
                                     pageSize={ 10 }
                                     idField="idUserAccount"
                                     loading={ loading }
                                     totalCount={ rolesUsers?.length }
                                     showDetailsButton
                                     onDetailRow={ (user) => history.push(`/users/${ user.idUserAccount }`) }
                                     defaultSorting={ [{ columnName: "email", direction: "asc" }] }/>   
            </>)}
            
            <Dialog
                fullScreen={ fullScreen }
                open={ openDialog }
                onClose={ handleClose }
                aria-labelledby="enable-disable-org-dialog-title"
            >
                <DialogTitle id="enable-disable-org-dialog-title">Are you sure
                    to delete { role?.name } Role?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that the
                        role will be deleted from all users and service accounts that use it.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleClose }>
                        Cancel
                    </Button>
                    <LoadingButton type="button" onClick={ deleteRole } loading={ internalLoading }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <FormDialog fullscreen
                        onDialogCancel={ () => {
                            resetFields()
                            setOpenEditRolesPermissionsDialog(false)
                        } }
                        open={ openEditRolesPermissionsDialog }
                        handleSubmit={ handleSubmit }
                        onDialogClose={ () => {
                            resetFields()
                            setOpenEditRolesPermissionsDialog(false)
                        } }
                        loading={ internalLoading }
                        title={ intl({ id: "roles.forms.editPermissions.title" }) }>
                <Grid container className={ formClasses.stepContainer } justify="center" spacing={ 2 }>
                    <Grid item container justify="center" spacing={ 2 }>
                        <Typography variant="h5" className={ formClasses.verticalMargin }>
                            { intl({ id: "roles.forms.editPermissions.description" }) }
                        </Typography>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <AutocompletePermissions
                            value={ fields.permissions }
                            onChange={ (e: ChangeEvent<{}>, value: IAPIPermission[] | null) => {
                                if (value) setValue("permissions", value, true)
                            } }
                            error={ errors.permissions }
                        />
                    </Grid>
                </Grid>
            </FormDialog>
        </Container>)
}