import React, { FunctionComponent, ReactNode, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { homeCards } from "../utils/config"
import EditIcon from "@material-ui/icons/Edit"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { UploadDialog } from "../components/UploadDialog"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState, IUser } from "../store/types"
import apiService from "../api"
import axios  from "axios"
import { LoadingButton } from "../components/LoadingButton"
import { enqueueSnackBar, setUser } from "../store/actions"

const CancelToken = axios.CancelToken

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    },
    profileCard: {
        width: "100%"
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    profileList: {
        textAlign: "left"
    },
    formControl: {
        minWidth: "100%"
    },
    fieldHeader: {
        flexGrow: 0,
        flexBasis: 156,
        paddingRight: 10
    },
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    hidden: {
        display: "none"
    },
    action: {
        cursor: "pointer"
    },
    mb: {
        marginBottom: theme.spacing(2)
    }
}))

interface IProfileField {
    id: string,
    intlId: string,
    primary?: (user: IUser | undefined) => string | ReactNode
    secondary?: (user: IUser | undefined) => string | ReactNode
    action?: (user: IUser | undefined) => string | ReactNode
    label?: string
}

type ProfileProps = {}

export const Profile: FunctionComponent<ProfileProps> = () => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const [uploadOpenDialog, setUploadOpenDialog] = useState(false)
    const [profileOpenDialog, setProfileOpenDialog] = useState(false)
    const [fieldId, setFieldId] = useState()
    const [loading, setLoading] = useState<boolean>(false)
    const { formatMessage: intl } = useIntl()
    const profile = useSelector<IStoreState, IUser | undefined>((state) => state.user)
    const { register, errors, watch, triggerValidation } = useForm()
    const data = watch({ nest: true })
    const dispatch = useDispatch()
    const source = CancelToken.source()

    const classes = useStyles()

    const handleProfileDialogClose = async (id: string, acceptedModification: boolean) => {
        if (acceptedModification) {
            switch (fieldId) {
                case "displayName":
                    await triggerValidation(["firstName", "lastName"], true)
                    if (!errors.firstName && !errors.lastName) {
                        updateUser({
                            ...profile,
                            firstName: data.firstName,
                            lastName: data.lastName
                        })
                    }
                    break
                case "password":
                    await triggerValidation(["oldPassword", "newPassword", "newPasswordRetype"], true)
                    if (!errors.oldPassword && !errors.newPassword && !errors.newPasswordRetype) {
                        updatePassword({
                            idUser: profile?.idUserAccount,
                            oldPassword: data.oldPassword,
                            newPassword: data.newPassword,
                            newPasswordRetype: data.newPasswordRetype
                            // organizationId: profile?.jwtPayload?.ido
                        })
                    }
            }
        } else {
            setProfileOpenDialog(false)
        }
    }

    const updateUser = (data: any) => {
        if (profile?.idUserAccount) {
            setLoading(true)
            apiService.updateProfile(profile?.idUserAccount, {
                firstName: data.firstName.trim(),
                lastName: data.lastName.trim()
            }, source.token).then(resp => {
                dispatch(enqueueSnackBar(
                    { key: profile?.idUserAccount, message: "Success. Profile Updated!", options: { persist: false, preventDuplicate: false, variant: "success" } }
                ))
                dispatch((setUser({ ...profile, firstName: resp.data.firstName, lastName: resp.data.lastName })))
            }).catch(ex => {
                dispatch(enqueueSnackBar(
                    { key: profile?.idUserAccount, message: "Update failed. If the problem persists, contact us", options: { persist: false, preventDuplicate: false, variant: "error" } }
                ))
            }).finally(() => {
                setLoading(false)
                setProfileOpenDialog(false)
            })
        }
    }

    const updatePassword = (data: any) => {
        if (profile?.idUserAccount) {
            setLoading(true)
            apiService.changePassword(profile?.idUserAccount, data, source.token).then(() => {
                dispatch(enqueueSnackBar(
                    { key: profile?.idUserAccount, message: "Success. Password Updated!", options: { persist: false, preventDuplicate: false, variant: "success" } }
                ))
            }).catch((ex) => {
                dispatch(enqueueSnackBar(
                    { key: profile?.idUserAccount, message: "Password change failed. If the problem persists, contact us", options: { persist: false, preventDuplicate: false, variant: "error" } }
                ))
            }).finally(() => {
                setLoading(false)
                setProfileOpenDialog(false)
            })
        }
    }

    useEffect(() => {
    }, [fieldId])

    const handleProfileDialogOpen = (id: string) => {
        setFieldId(id)
        if (id === "photo") {
            setUploadOpenDialog(true)
        } else setProfileOpenDialog(true)
    }

    const fields: IProfileField[] = [
        /*{
            id: "photo",
            intlId: "photo",
            secondary: () => "Add a photo to personalise your account",
            action: (user) => (<Avatar
                style={ { fontSize: 18, padding: 10, backgroundColor: stringToColor(user?.email || ""), color: "#fff", marginLeft: "auto" } }>{ (user && user?.firstName && user?.firstName.charAt(0).toUpperCase()) || "" }</Avatar>)
        },*/
        {
            id: "email",
            intlId: "email",
            primary: (user) => user?.email || "",
            secondary: (user) => "This address identifies your account and cannot be changed"
        },
        {
            id: "displayName",
            intlId: "name",
            primary: (user) => `${ user?.firstName } ${ user?.lastName }`,
            label: "Add"
        },
        {
            id: "mobile",
            primary: (user) => user?.mobile || "",
            intlId: "mobile"
        },
        {
            id: "password",
            intlId: "password",
            primary: () => "********"
        }
    ]

    const getDialogContent = () => {
        switch (fieldId) {
            case "displayName":
                return (<>
                    <DialogTitle id="form-dialog-title">Name</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please insert your first name and last name. This information will be shared to other
                            applications.
                        </DialogContentText>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="firstName"
                            size="small"
                            label="First Name"
                            name="firstName"
                            className={classes.mb}
                            defaultValue={ profile?.firstName }
                            inputRef={ register({
                                required: "Required"
                            }) }
                            error={ !!errors.firstName }
                            helperText={ (!!errors.firstName && (errors.firstName as any).message) || " " }
                        />
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="lastName"
                            size="small"
                            label="Last Name"
                            name="lastName"
                            className={classes.mb}
                            defaultValue={ profile?.lastName }
                            inputRef={ register({
                                required: "Required"
                            }) }
                            error={ !!errors.lastName }
                            helperText={ (!!errors.lastName && (errors.lastName as any).message) || " " }
                        />
                    </DialogContent>
                </>)
            case "password":
                return (<>
                    <DialogTitle id="form-dialog-title">Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Keep in mind that if you change password we could revoke all your access tokens and you'll
                            need to login with the new password to use your favorite nova's services.
                        </DialogContentText>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="oldPassword"
                            size="small"
                            label="Old Password"
                            name="oldPassword"
                            type="password"
                            className={classes.mb}
                            inputRef={ register({
                                required: "Required"
                            }) }
                            error={ !!errors.oldPassword }
                            helperText={ (!!errors.oldPassword && (errors.oldPassword as any).message) || " " }
                        />
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="newPassword"
                            size="small"
                            label="New Password"
                            name="newPassword"
                            type="password"
                            className={classes.mb}
                            inputRef={ register({
                                required: "Required"
                            }) }
                            error={ !!errors.newPassword }
                            helperText={ (!!errors.newPassword && (errors.newPassword as any).message) || " " }
                        />
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="newPasswordRetype"
                            size="small"
                            label="Confirm Password"
                            name="newPasswordRetype"
                            type="password"
                            className={classes.mb}
                            inputRef={ register({
                                required: "Required",
                                validate: retypePassword => {
                                    if (retypePassword) {
                                        return retypePassword === data.newPassword || intl({ id: "forms.errors.mismatchNewPassword" })
                                    } else return true
                                }
                            }) }
                            error={ !!errors.newPasswordRetype }
                            helperText={ (!!errors.newPasswordRetype && (errors.newPasswordRetype as any).message) || " " }
                        />
                    </DialogContent>
                </>)
            default:
                return (<></>)
        }
    }

    return (
        <>
            <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
                <Typography variant="h4">{ intl({ id: homeCards[0].intlId + ".title" }) }</Typography>
                <Typography variant="subtitle1"
                            className={ classes.caption }>{ intl({ id: homeCards[0].intlId + ".description" }) }</Typography>
                <Card className={ classes.profileCard } variant="outlined">
                    <CardContent style={ { textAlign: "left", padding: 0 } }>
                        <Typography style={ { padding: theme.spacing(2) } } variant="h5" component="h6">
                            { intl({ id: "profile.card.title" }) }
                        </Typography>
                        <Typography variant="subtitle1"
                                    style={ { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) } }>{ intl({ id: "profile.card.subtitle" }) }</Typography>
                        <List className={ classes.profileList }>
                            { fields.map((field, idx) => (
                                <ListItem key={ idx } divider={ idx !== fields.length - 1 }>
                                    <ListItemText
                                        className={ clsx({ [classes.fieldHeader]: true, [classes.hidden]: fullScreen }) }>{ intl({ id: "profile.fields." + field.intlId }) }</ListItemText>
                                    <ListItemText
                                        primary={ field.primary && field.primary(profile) }
                                        secondary={ field.secondary ? field.secondary(profile) : null }
                                    />
                                    { (field.id !== "email" && field.id !== "mobile") ?
                                        (<ListItemSecondaryAction className={ classes.action }
                                                                  onClick={ () => handleProfileDialogOpen(field.id) }>
                                            { field.action ? field.action(profile) : (
                                                <IconButton edge="end" aria-label="edit">
                                                    <EditIcon/>
                                                </IconButton>) }
                                        </ListItemSecondaryAction>) : undefined }
                                </ListItem>
                            )) }
                        </List>
                    </CardContent>
                </Card>
            </Container>
            { uploadOpenDialog && <UploadDialog onClose={ () => setUploadOpenDialog(false) }/> }
            <Dialog open={ profileOpenDialog } fullScreen={ fullScreen }
                    aria-labelledby="form-dialog-title">
                { getDialogContent() }
                <DialogActions>
                    <Button autoFocus onClick={ () => handleProfileDialogClose(fieldId, false) } color="primary">
                        Cancel
                    </Button>
                    <LoadingButton loading={ loading } type="button"
                                   onClick={ () => handleProfileDialogClose(fieldId, true) }>
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}