import React, { FunctionComponent, useEffect, useState } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { useHistory, useParams } from "react-router-dom"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from "@material-ui/core"
import { enqueueSnackBar, setLoading, startTutorial } from "../store/actions"
import { appViewerSteps } from "../tutorials"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { IAPIApplication, IAPIPermission, IAPIRole, IAPIServiceAccount, IAPIUser } from "../api/types"
import apiService from "../api"
import { IStoreState } from "../store/types"
import { checkPermissions } from "../utils/acl"
import { LoadingButton } from "../components/LoadingButton"
import { ToggleStatusButton } from "../components/ToggleStatusButton"
import { DataTable } from "../components/DataTable"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    mb: {
        marginBottom: theme.spacing(3)
    },
    caption: {},
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}))

const rolesColumns = (applications: IAPIApplication[]) => [
    { name: "name", title: "Name" },
    { name: "predefined", title: "Predefined?" },
    {
        name: "appName", title: "Application", getCellValue: (row: IAPIRole) => {
            return (row.idApplication && applications.find(app => app.idApplication === row.idApplication)?.name) || "n/a"
        }
    },
    { name: "status", title: "Status" }
]

const permissionsColumns = [
    { name: "macroFunction", title: "MacroFunction" },
    { name: "function", title: "Function" },
    { name: "path", title: "Path" },
    { name: "status", title: "Status", width: 100 }
]

const RolesRowDetail = ({ row }: { row: IAPIRole }) => (
    <>
        <Typography style={ { fontSize: "0.875rem" } }>
            <strong>Description: </strong> { row.description }
        </Typography>
        <DataTable<IAPIPermission> columns={ permissionsColumns }
                                   rows={ row?.permissions }
                                   idField="idPermission"
                                   title="Permissions"
                                   pageSize={ 10 }
                                   loading={ false }
                                   totalCount={ row?.permissions.length }
                                   defaultSorting={ [{ columnName: "name", direction: "asc" }] }/>
    </>
)

type ServiceAccountDetailsProps = {}

export const ServiceAccountDetails: FunctionComponent<ServiceAccountDetailsProps> = () => {
    const classes = useStyles()
    const { id } = useParams()
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const applications = useSelector<IStoreState, IAPIApplication[]>(state => state.apps ? state.apps : [])
    const canEdit = checkPermissions("editOrganization", "function", permissions || [])
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    const [serviceAccount, setServiceAccount] = useState<IAPIServiceAccount | undefined>()
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))
    const [openDialog, setOpenDialog] = useState(false)
    const [internalLoading, setInternalLoading] = useState(false)

    let history = useHistory()

    const [openUserDialog, setOpenUserDialog] = useState(false)

    const removeServiceAccount = async () => {
        try {
            setInternalLoading(true)
            await apiService.deleteServiceAccount(serviceAccount?.idServiceAccount || "")
        } catch {
            dispatch(enqueueSnackBar(
                { key: serviceAccount?.idServiceAccount || "", message: "Impossible to remove service account from organization", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        handleSAClose()
    }

    const enableDisableServiceAccount = async () => {
        try {
            setInternalLoading(true)
            const newStatus = serviceAccount?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
            await apiService.toggleServiceAccountStatus(serviceAccount?.idServiceAccount || "", newStatus)
            if (serviceAccount) setServiceAccount({ ...serviceAccount, status: newStatus })
        } catch {
            dispatch(enqueueSnackBar(
                { key: serviceAccount?.idOrganization || "", message: "Impossible to change Organization's status", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        handleClose()
    }


    const handleClose = () => {
        setInternalLoading(false)
        setOpenDialog(false)
    }

    const handleSAClose = () => {
        setInternalLoading(false)
        setOpenUserDialog(false)
    }


    const loadServiceAccount = () => {
        if (id) {
            dispatch(setLoading(true))
            apiService.getServiceAccount(id).then((response) => {
                setServiceAccount(response.data)
            }).catch(ex => console.log(ex)).finally(() => dispatch(setLoading(false)))
        }
    }

    useEffect(() => {
        loadServiceAccount()
    }, [])

    return (
        <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
            { loading ? (<Typography>Loading data...</Typography>) : (<>
                <Typography className={ classes.mt }
                            variant="h4">{ serviceAccount?.name } </Typography>
                <Grid container justify="center" className={ classes.mt }>
                    <Grid item>
                        { canEdit && (
                            <ToggleStatusButton style={ { marginLeft: 10 } } data-tut="organizations.toggleStatus"
                                                action={ serviceAccount?.status === "ACTIVE" ? "disable" : "enable" }
                                                onClick={ () => setOpenDialog(true) }>{ serviceAccount?.status === "ACTIVE" ? "Disable" : "Enable" }</ToggleStatusButton>) }
                        <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                                onClick={ () => dispatch(startTutorial(appViewerSteps(intl))) }>{ intl({ id: "functions.help" }) }</Button>
                    </Grid>
                </Grid></>) }
            <DataTable<IAPIRole> columns={ rolesColumns(applications) }
                                 rows={ serviceAccount?.roles }
                                 title="Roles"
                                 pageSize={ 10 }
                                 idField="idRole"
                                 loading={ loading }
                                 totalCount={ serviceAccount?.roles?.length }
                                 detailComponent={ RolesRowDetail }
                                 showDetailsButton
                                 onDetailRow={ (role) => history.push(`/roles/${ role.idRole }`) }
                                 defaultSorting={ [{ columnName: "idApplication", direction: "asc" }] }/>
            <br/>
            <Dialog
                fullScreen={ fullScreen }
                open={ openDialog }
                onClose={ handleClose }
                aria-labelledby="enable-disable-org-dialog-title"
            >
                <DialogTitle id="enable-disable-org-dialog-title">Do you want
                    to { serviceAccount?.status === "ACTIVE" ? "disable" : "enable" } { serviceAccount?.name }?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that the
                        service account { serviceAccount?.status === "ACTIVE" ? "won't" : "will" } be able
                        to authenticate with any organization.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleClose }>
                        Cancel
                    </Button>
                    <LoadingButton type="button" onClick={ enableDisableServiceAccount } loading={ internalLoading }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    )
}