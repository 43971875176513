import React, { FunctionComponent } from "react"
import "./App.css"
import { createMuiTheme, ThemeProvider } from "@material-ui/core"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { NoMatch } from "./components/NoMatch"
import store from "./store"
import { Provider, useSelector } from "react-redux"
import { DashboardContainer } from "./containers/DashboardContainer"
import { IStoreState } from "./store/types"
import { IntlWrapper } from "./intl"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { SnackbarProvider } from "notistack"
import Notifier from "./components/Notifier"
import { ExchangeContainer } from "./containers/ExchangeContainer"

type ThemeWrapperProps = {}

const ThemeWrapper: FunctionComponent<ThemeWrapperProps> = (props) => {
    const themeOption = useSelector((state: IStoreState) => state.theme)
    const theme = createMuiTheme({
        overrides: {
            MuiAppBar: {
                colorDefault: {
                    backgroundColor: themeOption === "light" ? "#ffffff" : "#424242"
                }
            },
            MuiStepper: {
                root: {
                    padding: 0
                }
            },
            MuiCardHeader: {
                action: {
                    marginTop: 0,
                    marginRight: 0,
                    alignSelf: "inherit"
                }
            },
        },
        palette: {
            type: themeOption,
            background: {
                default: themeOption === "light" ? "#ffffff" : "#424242"
            },
            primary: {
                main: '#2A6EFB',
                //main: "#fff",
                contrastText: "#fff"
            },
            secondary: {
                main: "#fff",
                contrastText: "#202020"
            }
        },
        typography: {
            button: {
                textTransform: "none",
                fontWeight: "bold"
            }
        }
    })

    return (
        <ThemeProvider theme={ theme }>
            { props.children }
        </ThemeProvider>
    )
}

const App: React.FC = () => {
    const cssRuleTitle =
        "color: rgb(249, 0, 0);" +
        "font-size: 60px;" +
        "font-weight: bold;" +
        "text-shadow: 1px 1px 5px rgb(249, 0, 0);" +
        "filter: dropshadow(color=rgb(249, 0, 0), offx=1, offy=1);"

    setTimeout(console.log.bind(console, "%cStop!", cssRuleTitle), 0)

    const cssRuleMessage =
        "color: rgb(0, 0, 0);" +
        "font-size: 20px;" +
        "font-weight: bold;" +
        "background-color: #ffffff;"
    setTimeout(console.log.bind(console, "%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.Do not enter or paste code that you do not understand.!", cssRuleMessage), 0)

    return (
        <Router>
            <Provider store={ store }>
                <IntlWrapper>
                    <ThemeWrapper>
                        <SnackbarProvider>
                            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                                <div className="App">
                                    <Notifier/>
                                    { process.env.REACT_APP_DEV_BANNER === "true" ?
                                        <div className="dev-banner">DEVELOPMENT</div> : null }
                                    <Switch>
                                        <Route path="/oauth_callback">
                                            <ExchangeContainer/>
                                        </Route>
                                        <Route
                                            path="/(|profile|applications|organizations|roles|users|services|invitations)">
                                            <DashboardContainer/>
                                        </Route>
                                        <Route component={ NoMatch }/>
                                    </Switch>
                                </div>
                            </MuiPickersUtilsProvider>
                        </SnackbarProvider>
                    </ThemeWrapper>
                </IntlWrapper>
            </Provider>
        </ Router>
    )
}

export default App
