import React from "react";
import Lottie from "react-lottie";
import animationData from "../assets/LoadingAnimation.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const Loader = ({
  height = "250px",
  width = "250px",
}: {
  height?: string;
  width?: string;
}) => <Lottie options={defaultOptions} height={height} width={width} />;
