import React, { FunctionComponent } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Divider, Typography, IconButton, AppBar, Toolbar, Grid } from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions"
import CloseIcon from "@material-ui/icons/Close"
import clsx from "clsx"
import { isMobile } from "react-device-detect"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import { StepIconProps } from "@material-ui/core/StepIcon"
import StepConnector from "@material-ui/core/StepConnector"
import { useIntl } from "react-intl"
import { LoadingButton } from "./LoadingButton"

const useStyles = makeStyles(theme => ({
    form: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(2),
        width: "100%" // Fix IE 11 issue.
    },
    buttonSpacing: {
        margin: theme.spacing(1)
    },
    hidden: {
        display: "none"
    },
    appBar: {
        zIndex: theme.zIndex.modal + 1
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    p: {
        padding: theme.spacing(4)
    },
    root: {
        width: "100%"
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}))

const ColorLibConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    active: {
        "& $line": {
            backgroundImage:
                "linear-gradient( 95deg,rgb(245,156,12) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
        }
    },
    completed: {
        "& $line": {
            backgroundImage:
                "linear-gradient( 95deg,rgb(245,156,12) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1
    }
})(StepConnector)

const useColorLibStepIconStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center"
    },
    active: {
        backgroundImage:
            "linear-gradient( 136deg, rgb(245,156,12) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
    },
    completed: {
        backgroundImage:
            "linear-gradient( 136deg, rgb(245,156,12) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)"
    }
}))

type IStep = {
    label: string
    optional?: boolean
    icon: any
}

type FormWizardDialogProps = {
    open: boolean,
    onDialogClose: () => void
    onDialogCancel: () => void
    handleSubmit: (e?: React.BaseSyntheticEvent) => void
    title: string
    loading?: boolean
    fullscreen?: boolean
    description?: string
    submitText?: string
    cancelText?: string
    Transition?: any
    steps: IStep[]
    activeStep: number
    hasTutorial?: boolean
    getStepContent: (index: number) => {}
    onOpenTutorial?: () => void
    handleNext: () => void
    handleBack: () => void
    handleReset: () => void
}

export const FormWizardDialog: FunctionComponent<FormWizardDialogProps> = (props) => {
    const classes = useStyles()
    const { formatMessage: intl } = useIntl()

    const ColorlibStepIcon = (stepIconProps: StepIconProps) => {
        const classes = useColorLibStepIconStyles()
        const { active, completed, icon } = stepIconProps

        return (
            <div
                className={ clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed
                }) }
            >
                { props.steps[icon as number - 1].icon }
            </div>
        )
    }

    const renderActions = (
        <>
            <Button onClick={ props.activeStep === 0 ? props.onDialogCancel : props.handleBack } variant="outlined">
                { props.activeStep === 0 ? props.cancelText : intl({ id: "functions.back" }) }
            </Button>
            <LoadingButton
                loading={ props.loading || false }
                onClick={ props.activeStep === props.steps.length - 1 ? props.handleSubmit : props.handleNext }
            >
                { props.activeStep === props.steps.length - 1 ? props.submitText : intl({ id: "functions.next" }) }
            </LoadingButton>
        </>
    )

    return (
        <Dialog
            fullScreen={ props.fullscreen }
            open={ props.open }
            onClose={ props.onDialogClose }
            aria-labelledby="responsive-dialog-title"
            TransitionComponent={ props.Transition }
        >
            { props.fullscreen ? (
                    <AppBar className={ classes.appBar } color="primary" elevation={ 1 } position="fixed">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={ props.onDialogCancel }
                                        aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6" className={ classes.title }>
                                { props.title }
                            </Typography>
                            { props.hasTutorial ? (
                                <Button
                                    onClick={ props.onOpenTutorial }
                                    variant="contained"
                                    color="secondary"
                                >
                                    Help
                                </Button>) : undefined }
                        </Toolbar>
                    </AppBar>
                ) :
                (<><DialogTitle id="responsive-dialog-title">{ props.title }</DialogTitle>
                    <Divider/></>)
            }
            <Toolbar/>
            <DialogContent>
                <Grid container justify="center">
                    <Grid item style={ { width: "100%", maxWidth: "1280px" } }>
                        <DialogContentText className={ clsx({ [classes.hidden]: !props.description }) }>
                            { props.description }
                        </DialogContentText>
                        <div className={ classes.root }>
                            <Stepper alternativeLabel activeStep={ props.activeStep }
                                     connector={ <ColorLibConnector/> }>
                                { props.steps.map((step: IStep) => (
                                    <Step key={ step.label }>
                                        <StepLabel StepIconComponent={ ColorlibStepIcon }
                                                   optional={ step.optional ? <Typography
                                                       style={ { fontSize: 12, textAlign: "center" } }>optional</Typography> : undefined }><Typography>{ step.label }</Typography></StepLabel>
                                    </Step>
                                )) }
                            </Stepper>
                            <Grid container justify="center" spacing={ 2 }>
                                <Grid item container justify="space-between">
                                    { props.getStepContent(props.activeStep) }
                                </Grid>
                            </Grid>
                        </div>
                        { props.children }
                        { !isMobile ? <Grid item container justify="space-between" className={ classes.p }>
                            { renderActions }
                        </Grid> : undefined }
                    </Grid>
                </Grid>
            </DialogContent>
            { isMobile ? (<DialogActions className={ classes.buttonSpacing }>
                { renderActions }
            </DialogActions>) : undefined }
        </Dialog>
    )
}

FormWizardDialog.defaultProps = {
    Transition: React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>
    ) {
        return <Fade ref={ ref } { ...props }/>
    }),
    cancelText: "Cancel",
    submitText: "Submit",
    hasTutorial: false,
    loading: false
}