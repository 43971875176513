import React, { ChangeEvent, FunctionComponent, useState } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { IAPIRole } from "../api/types"
import apiService from "../api"
import { Chip, Tooltip, Button, makeStyles } from "@material-ui/core"


type AutocompleteRolesProps = {
    onChange: (e: ChangeEvent<{}>, value: IAPIRole[] | null) => void
    options?: IAPIRole[]
    value?: IAPIRole[]
    error?: any
    dataTut?: string
}


const useStyles = makeStyles(theme => ({
    root: {
        fontWeight: 400,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    fullWidth: {
        width: '100%',
        height: '100%'
    }
}))

export const AutocompleteRoles: FunctionComponent<AutocompleteRolesProps> = (props) => {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<IAPIRole[]>([])
    const loading = open && options.length === 0
    const classes = useStyles()

    React.useEffect(() => {
        let active = true

        if (!loading) {
            return undefined
        }

        if (!props.options) {
            (async () => {
                const response = await apiService.listRoles({ orderBy: "", page: 0, perPage: 0 })
                const roles = response.data
                if (active) {
                    setOptions(roles)
                }
            })()
        } else {
            setOptions(props.options)
        }

        return () => {
            active = false
        }
    }, [loading])

    return (
        <Autocomplete<IAPIRole>
            id="roles-filled"
            options={ options }
            value={ props.value }
            groupBy={ (row) => {
                if (row.idApplication && row.application?.name) return row.application.name
                else return "Custom Roles"
            } }
            open={ open }
            onOpen={ () => {
                setOpen(true)
            } }
            onClose={ () => {
                setOpen(false)
            } }
            multiple
            getOptionLabel={ (option) => option.name + option.description || "" }
            renderOption={(option) => {
                return (
                    <Tooltip className={classes.fullWidth} title={`${option.description}`} placement="bottom-start">
                      <div>
                        <Button className={classes.root}>
                            {option.name}
                        </Button>
                      </div>
                    </Tooltip>
                );
              }
            }
            onChange={ props.onChange }
            filterSelectedOptions
            renderTags={ (value, getTagProps) =>
                value.map((option, index) => (
                    <Chip label={ option.name } { ...getTagProps({ index }) }/>
                ))
            }
            loading={ loading }
            renderInput={ params => (
                <TextField
                    { ...params }
                    color="primary"
                    variant="outlined"
                    label="Roles"
                    size="small"
                    placeholder="role"
                    fullWidth
                    name="role"
                    error={ props.error }
                    helperText={ (props.error && (props.error as any).message) || " " }
                />
            ) }
        />
    )
}