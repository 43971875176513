import React, { ChangeEvent, FunctionComponent, useState } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { IAPIOrganization } from "../api/types"
import apiService from "../api"

type AutocompleteOrganizationProps = {
    onChange: (e: ChangeEvent<{}>, value: IAPIOrganization | null) => void
    value?: IAPIOrganization
    error?: any
    dataTut?: string
}

export const AutocompleteOrganization: FunctionComponent<AutocompleteOrganizationProps> = (props) => {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<IAPIOrganization[]>([])
    const loading = open && options.length === 0

    React.useEffect(() => {
        let active = true

        if (!loading) {
            return undefined
        }

        (async () => {
            const response = await apiService.listOrganizations({ orderBy: "", page: 0, perPage: 0 })
            const orgs = response.data
            if (active) {
                setOptions(orgs)
            }
        })()

        return () => {
            active = false
        }
    }, [loading])

    return (
        <Autocomplete<IAPIOrganization>
            id="orgs-filled"
            options={ options }
            value={ props.value }
            open={ open }
            onOpen={ () => {
                setOpen(true)
            } }
            onClose={ () => {
                setOpen(false)
            } }
            getOptionLabel={ (option) => option.name || "" }
            onChange={ props.onChange }
            loading={ loading }
            renderInput={ params => (
                <TextField
                    { ...params }
                    color="primary"
                    variant="outlined"
                    label="Organization"
                    size="small"
                    placeholder="organization"
                    fullWidth
                    name="organization"
                    error={ props.error && !!props.error }
                    helperText={ (props.error && !!props.error && props.error && "Required") || " " }
                />
            ) }
        />
    )
}