import { IFeature, IOrganizationApp, IPermissionOption, IRole, ITag } from "./types"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"
import { IAPICreatePermission, IAPIPermission } from "../api/types"
import { slugify } from "../utils"

export const findTagOptions = (applications?: IOrganizationApp[]) => {
    return applications?.reduce((acc, app) => {
        if (app.available && app.visibilityTags) acc.push(...app.visibilityTags.map((tag) => { return { id: app.name + "." + tag, idApplication: app.idApplication, appName: app.name, tag }}))
        return acc
    }, [] as ITag[]) || []
}

export const findRoleOptions = (applications?: IOrganizationApp[]) => {
    return applications?.reduce((acc, app) => {
        if (app.available && app.predefinedRoles) acc.push(...app.predefinedRoles.map((role) => { return { idRole: role.idRole || "", app: app.name, idApplication: app.idApplication, name: role.name }}))
        return acc
    }, [] as IRole[]) || []
}

export const findPermissionsOptions = (applications?: IOrganizationApp[]) => {
    return applications?.reduce((acc, app) => {
        if (app.available && app.permissions) {
            acc.push(...app.permissions.filter(permission => permission.status === "ACTIVE").map(permission => { return { ...permission, app: app.name }}))
        }
        return acc
    }, [] as IPermissionOption[])
}

export const createPermissionPath = (applicationName: string, macroFunction: string, fun: string) => {
    let funSlug = slugify(fun)
    if (funSlug === "all") funSlug = "*"
    let macroFunctionSlug = slugify(macroFunction)
    if (macroFunctionSlug === "all") macroFunctionSlug = "*"
    return [slugify(applicationName), macroFunctionSlug, funSlug].join(".")
}

export const generateAPIPermissions = (name: string, features: IFeature[], permissions?: IAPIPermission[]) => features.reduce((acc, feature) => {
    const macroFunction = feature.macroFunction
    feature.functions.forEach(fun => {
        const path = createPermissionPath(name, macroFunction, fun)
        const idx = permissions?.findIndex(perm => perm.path === path)
        if (typeof idx !== 'undefined' && idx >= 0 && permissions && permissions[idx]){
            acc.push({
                idPermission: permissions[idx].idPermission, function: fun, macroFunction, path
            })
        } else {
            acc.push({
                function: fun, macroFunction, path
            })
        }

    })
    return acc
}, [] as IAPICreatePermission[])

export const useFormStyles = makeStyles((theme: Theme) => ({
    deleteIcon: {
        padding: 8
    },
    grow: {
        flexGrow: 1
    },
    stepContainer: {
        marginTop: 20,
        paddingLeft: 40,
        paddingRight: 40
    },
    verticalMargin: {
        marginTop: 20,
        marginBottom: 20
    },
    smallBottomMargin: {
        marginTop: 20,
        marginBottom: 0
    },
    formControl: {
        width: "100%",
        margin: theme.spacing(0)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    smallSelect: {
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 10.5,
        paddingBottom: 10.5
    },
    inputDistance: {
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(0)
        }
    },
    roleContainer: {
        marginBottom: theme.spacing(2)
    },
    atLeastOneError: {
        color: "red",
        marginLeft: theme.spacing(1)
    }
}))