import React, { FunctionComponent } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Divider, Typography, IconButton, AppBar, Toolbar, Grid } from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions"
import CloseIcon from "@material-ui/icons/Close"
import clsx from "clsx"
import { isMobile } from "react-device-detect"
import { LoadingButton } from "./LoadingButton"

const useStyles = makeStyles(theme => ({
    form: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(2),
        width: "100%" // Fix IE 11 issue.
    },
    buttonSpacing: {
        margin: theme.spacing(3)
    },
    hidden: {
        display: "none"
    },
    appBar: {
        zIndex: theme.zIndex.modal + 1
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    p: {
        padding: theme.spacing(4)
    },
    root: {
        width: "100%"
    }
}))

type FormDialogProps = {
    open: boolean,
    onDialogClose: () => void
    onDialogCancel: () => void
    handleSubmit: (e: React.BaseSyntheticEvent) => void
    title: string
    loading: boolean
    fullscreen?: boolean
    description?: string
    submitText?: string
    cancelText?: string
    Transition?: any
}

export const FormDialog: FunctionComponent<FormDialogProps> = (props) => {
    const classes = useStyles()

    const renderActions = (
        <>
            <Button onClick={ props.onDialogCancel } variant="outlined">
                { props.cancelText }
            </Button>
            <LoadingButton
                loading={ props.loading || false }
                onClick={ props.handleSubmit }
            >
                { props.submitText }
            </LoadingButton>
        </>
    )

    return (
        <>
            <Dialog
                fullScreen={ props.fullscreen }
                open={ props.open }
                onClose={ props.onDialogClose }
                aria-labelledby="responsive-dialog-title"
                TransitionComponent={ props.Transition }
            >
                { props.fullscreen ? (
                        <AppBar className={ classes.appBar } color="primary" elevation={ 1 } position="fixed">
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={ props.onDialogCancel }
                                            aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6" className={ classes.title }>
                                    { props.title }
                                </Typography>
                                <Button
                                    onClick={ () => console.log(true) }
                                    variant="outlined"
                                    color="secondary"
                                >
                                    Help
                                </Button>
                            </Toolbar>
                        </AppBar>
                    ) :
                    (<><DialogTitle id="responsive-dialog-title">{ props.title }</DialogTitle>
                        <Divider/></>)
                }
                <Toolbar/>
                <DialogContent>
                    <Grid container justify="center">
                        <Grid item style={ { width: "100%", maxWidth: "1280px" } }>
                            <DialogContentText className={ clsx({ [classes.hidden]: !props.description }) }>
                                { props.description }
                            </DialogContentText>
                            <div className={ classes.root }>
                                <Grid container justify="center" spacing={ 2 }>
                                    <Grid item container justify="space-between">
                                        { props.children }
                                    </Grid>
                                </Grid>
                            </div>
                            { !isMobile ? <Grid item container justify="space-between" className={ classes.p }>
                                { renderActions }
                            </Grid> : undefined }
                        </Grid>
                    </Grid>
                </DialogContent>
                { isMobile ? (<DialogActions className={ classes.buttonSpacing }>
                    { renderActions }
                </DialogActions>) : undefined }
            </Dialog>
        </>
    )
}

FormDialog.defaultProps = {
    Transition: React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>
    ) {
        return <Fade ref={ ref } { ...props }/>
    }),
    cancelText: "Cancel",
    submitText: "Submit"
}