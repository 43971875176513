import React, { ChangeEvent, FunctionComponent } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Chip } from "@material-ui/core"

type AutocompleteEmailsProps = {
    onChange: (e: ChangeEvent<{}>, value: string[]) => void
    value: string[]
    label: string
    placeholder: string
    name: string
    error?: any
    dataTut?: string
}

export const AutocompleteText: FunctionComponent<AutocompleteEmailsProps> = (props) => {

    return (
        <Autocomplete<string>
            multiple
            freeSolo
            options={[]}
            value={ props.value }
            renderTags={ (value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip size="small" variant="outlined"
                          label={ option } { ...getTagProps({ index }) } />
                ))
            }
            onChange={ props.onChange }
            renderInput={ params => (
                <TextField
                    { ...params }
                    color="primary"
                    variant="outlined"
                    label={props.label}
                    size="small"
                    placeholder={props.placeholder}
                    fullWidth
                    name={props.name}
                    error={ !!props.error }
                    helperText={ (!!props.error && (props.error as any).message) || " " }
                />
            ) }
        />
    )
}