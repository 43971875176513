import React, { FunctionComponent, useEffect, useState } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { useParams, useHistory } from "react-router-dom"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from "@material-ui/core"
import { enqueueSnackBar, setLoading, startTutorial } from "../store/actions"
import { appViewerSteps } from "../tutorials"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { DataTable } from "../components/DataTable"
import apiService from "../api"
import { IAPIApplication, IAPIOrganization, IAPIPermission, IAPIRole } from "../api/types"
import { IStoreState } from "../store/types"
import { checkPermissions } from "../utils/acl"
import { ToggleStatusButton } from "../components/ToggleStatusButton"
import { LoadingButton } from "../components/LoadingButton"
import { FormDialog } from "../components/FormDialog"
import { useForm } from "react-hook-form"
import { ICreateApplicationValues, IFeature, IFeatureError, IRoleError } from "../forms/types"
import { PermissionsStep } from "../forms/PermissionsStep"
import { RolesStep } from "../forms/RolesStep"
import { AutocompleteText } from "../components/AutocompleteText"
import { generateAPIPermissions, useFormStyles } from "../forms/utils"
import { useApplicationFormRegister } from "../forms/ApplicationsForm"
import { slugify } from "../utils"
import { AppInfoStep } from "../forms/AppInfoStep"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    mb: {
        marginBottom: theme.spacing(3)
    },
    caption: {},
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}))

const rolesColumns = [
    { name: "name", title: "Name" },
    { name: "status", title: "Status", width: 100 }
]

const RolesRowDetail = ({ row }: { row: IAPIRole }) => {
    return row.description && (<Typography style={ { fontSize: "0.875rem" } }>
        <strong>Description: </strong> { row.description }
    </Typography>)
}

const visibilityTagsColumns = [
    {
        name: "tag", title: "Tag", getCellValue: (row: string) => {
            return row
        }
    }
]
const permissionsColumns = [
    { name: "macroFunction", title: "MacroFunction" },
    { name: "function", title: "Function" },
    { name: "path", title: "Path" },
    { name: "status", title: "Status", width: 100 }
]

const organizationsColumns = [
    { name: "name", title: "Name" },
    { name: "alias", title: "alias" }
]

type EditResources = "info" | "permissions" | "roles" | "visTags" | "org"

type ApplicationDetailsProps = {}

export const ApplicationDetails: FunctionComponent<ApplicationDetailsProps> = () => {
    const formClasses = useFormStyles()
    const classes = useStyles()
    const { id } = useParams()
    const [openForm, setOpenForm] = useState(false)
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const canEdit = checkPermissions("editApplication", "function", permissions || [])
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const [app, setApp] = useState<IAPIApplication>()
    const [openDialog, setOpenDialog] = useState(false)
    const [openOrgDialog, setOpenOrgDialog] = useState(false)
    const [selectedOrg, selectOrg] = useState<IAPIOrganization | undefined>()
    const [activeEdit, setActiveEdit] = useState<EditResources>("permissions")
    const [internalLoading, setInternalLoading] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))
    let history = useHistory()

    useEffect(() => {
        if (id) {
            dispatch(setLoading(true))
            apiService.getApplication(id).then(result => {
                setApp(result.data)
            }).catch().finally(() => dispatch(setLoading(false)))
        }
    }, [])

    const enableDisableApp = async () => {
        try {
            setInternalLoading(true)
            const newStatus = app?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
            await apiService.toggleApplicationStatus(app?.idApplication || "", newStatus)
            if (app) setApp({ ...app, status: newStatus })
        } catch {
            dispatch(enqueueSnackBar(
                { key: app?.idApplication || "", message: "Impossible to change Application's status", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        setInternalLoading(false)
        handleClose()
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    const handleOrgClose = () => {
        setOpenOrgDialog(false)
    }

    const removeOrg = async () => {
        try {
            setInternalLoading(true)
            await apiService.removeApplicationUsage(selectedOrg?.idOrganization || "-", app?.idApplication || "-")
            setOpenOrgDialog(false)
            dispatch(enqueueSnackBar(
                { key: selectedOrg?.idOrganization || "", message: "Organization removed from app usage", options: { persist: false, preventDuplicate: false, variant: "success" } }
            ))
        } catch {
            dispatch(enqueueSnackBar(
                { key: selectedOrg?.idOrganization || "", message: "Impossible to change stop the organization usage", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        setInternalLoading(false)
        handleClose()
    }

    const selectActiveEdit = (resource: EditResources) => {
        setActiveEdit(resource)
        setOpenForm(true)
    }

    const { register, unregister, errors, watch, setValue, triggerValidation } = useForm<ICreateApplicationValues>({
        defaultValues: {
            roles: [],
            features: []
        }
    })
    const fields = watch({ nest: true })
    const [featureErrors, setFeatureErrors] = useState<IFeatureError[]>([])
    const [roleErrors, setRoleErrors] = useState<IRoleError[]>([])
    useApplicationFormRegister(register, unregister, intl, setFeatureErrors, setRoleErrors)

    const resetFields = () => {
        setValue("name", app?.name || "")
        setValue("appUrl", app?.appUrl)
        setValue("supportEmail", app?.supportEmail)
        setValue("termUrl", app?.termUrl)
        setValue("policyUrl", app?.privacyUrl)
        setValue("description", app?.description)
        setValue("features", app?.permissions.reduce((acc, permission) => {
            const idx = acc.findIndex(feat => feat.macroFunction === permission.macroFunction)
            if (idx > -1) {
                if (permission.path.indexOf(".*") > -1) acc[idx].functions.unshift(permission.function)
                else acc[idx].functions.push(permission.function)
            } else {
                if (permission.path.indexOf("*.*") > -1) acc.unshift({ macroFunction: permission.macroFunction, functions: [permission.function] })
                else acc.push({ macroFunction: permission.macroFunction, functions: [permission.function] })
            }

            return acc
        }, [] as IFeature[]) || [])
        setValue("roles", [])
        setValue("visTags", app?.visibilityTags)
    }

    useEffect(() => {
        resetFields()
    }, [app])

    const getFormContent = (resource: EditResources) => {
        switch (resource) {
            case "info":
                return <Grid container spacing={ 2 } className={ formClasses.stepContainer }>
                    <AppInfoStep fields={ fields } errors={ errors } disableName
                                 onChange={ (field, value, validate) => setValue(field, value, validate) }/>
                </Grid>
            case "permissions":
                return <PermissionsStep onChange={ (features) => setValue("features", features) }
                                        features={ fields.features || [] } error={ featureErrors }/>
            case "roles":
                return <RolesStep onChange={ (roles) => setValue("roles", roles) }
                                  roles={ fields.roles || [] } features={ fields.features || [] } error={ roleErrors }/>
            case "visTags":
                return <Grid item xs={ 12 } sm={ 6 } md={ 12 } className={ formClasses.stepContainer }>
                    <AutocompleteText
                        value={ fields.visTags || [] }
                        onChange={ (e, value) => {
                            setValue("visTags", value)
                        } }
                        label={ intl({ id: "applications.forms.visTags" }) }
                        placeholder="Tag"
                        name="tags"
                        error={ errors.visTags }/>
                </Grid>
        }
    }

    const handleSubmit = async () => {
        try {
            setInternalLoading(true)
            let validation = true
            switch (activeEdit) {
                case "info":
                    await triggerValidation(["appUrl", "supportEmail", "termUrl", "privacyUrl", "description"])
                    if (!errors.appUrl || !errors.supportEmail || !errors.termsUrl || !errors.policyUrl || !errors.description) {
                        await apiService.updateApplication(app?.idApplication || "-", {
                            appUrl: fields.appUrl,
                            description: fields.description,
                            idApplication: app?.idApplication || "",
                            name: app?.name || "",
                            privacyUrl: fields.policyUrl,
                            slug: slugify(app?.name || ""),
                            supportEmail: fields.supportEmail,
                            termUrl: fields.termsUrl
                        })
                    } else validation = false
                    break
                case "permissions":
                    await triggerValidation(["features"])
                    if (!errors.features) await apiService.updateApplicationPermissions(app?.idApplication || "-", generateAPIPermissions(app?.name || "", fields.features, app?.permissions))
                    else validation = false
                    break
                case "roles":
                    await triggerValidation(["roles"])
                    if (!errors.roles) await apiService.updateApplicationRoles(app?.idApplication || "-", fields.roles.map((role) => {
                        return {
                            ...role,
                            permissions: role.permissions.map(permission => {
                                const found = app?.permissions.find(appPermission => appPermission.macroFunction === permission.macroFunction && appPermission.function === permission.permission)
                                return found ? found.idPermission : ""
                            })
                        }
                    }))
                    else validation = false
                    break
                case "visTags":
                    await triggerValidation(["visTags"])
                    if (!errors.visTags) await apiService.updateApplicationVisTags(app?.idApplication || "-", fields.visTags || [])
                    else validation = false
                    break
            }
            if (validation) {
                setOpenForm(false)
                resetFields()
                dispatch(enqueueSnackBar(
                    { key: app?.idApplication || "", message: intl({ id: "messages.general.updateSuccessful" }), options: { persist: false, preventDuplicate: false, variant: "success" } }
                ))
            }
        } catch {
            dispatch(enqueueSnackBar(
                { key: app?.idApplication || "", message: intl({ id: "messages.general.updateFailed" }), options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        setInternalLoading(false)
    }

    const getFormTitle = (resource: EditResources) => {
        switch (resource) {
            case "info":
                return "Edit General Information"
            case "permissions":
                return "Edit Permissions"
            case "roles":
                return "Edit Roles"
            case "visTags":
                return "Edit Visibility Tags"
            default:
                return "Edit General Information"
        }
    }

    return (
        <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
            { loading ? (<Typography>Loading data...</Typography>) : (<><Typography
                variant="h4">{ app?.name }</Typography>
                <Grid container justify="center" className={ classes.mt }>
                    <Grid item>
                        { canEdit && (
                            <Button data-tut="applications.edit" variant="contained" color="primary"
                                    onClick={ () => selectActiveEdit("info") }>{ intl({ id: "functions.edit" }) } Info</Button>) }
                        { canEdit && (
                            <ToggleStatusButton style={ { marginLeft: 10 } } data-tut="applications.toggleStatus"
                                                action={ app?.status === "ACTIVE" ? "disable" : "enable" }
                                                onClick={ () => setOpenDialog(true) }>{ app?.status === "ACTIVE" ? "Disable" : "Enable" }</ToggleStatusButton>) }
                        <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                                onClick={ () => dispatch(startTutorial(appViewerSteps(intl))) }>{ intl({ id: "functions.help" }) }</Button>
                    </Grid>
                    <Grid item>

                    </Grid>
                </Grid>
                <Typography variant="subtitle1" className={ classes.mt }>{ app?.description }</Typography>

                <DataTable<string> columns={ visibilityTagsColumns }
                                   rows={ app?.visibilityTags }
                                   title="Visibility Tags"
                                   tableActions={
                                    canEdit && (<Button variant="contained" color="primary" size="small"
                                               onClick={ () => selectActiveEdit("visTags") }>
                                           { intl({ id: "functions.edit" }) }
                                    </Button>) }
                                   pageSize={ 10 }
                                   loading={ loading }
                                   totalCount={ app?.visibilityTags?.length }
                                   defaultSorting={ [{ columnName: "name", direction: "asc" }] }/>

                <DataTable<IAPIPermission> columns={ permissionsColumns }
                                           rows={ app?.permissions }
                                           title="Permissions"
                                           tableActions={
                                            canEdit && (<Button variant="contained" color="primary" size="small"
                                                       onClick={ () => selectActiveEdit("permissions") }>
                                                   { intl({ id: "functions.edit" }) }
                                            </Button>) }
                                           idField="idPermission"
                                           descriptionField="description"
                                           pageSize={ 10 }
                                           loading={ loading }
                                           totalCount={ app?.permissions.length }
                                           defaultSorting={ [{ columnName: "name", direction: "asc" }] }/>

                <DataTable<IAPIRole> columns={ rolesColumns }
                                     rows={ app?.predefinedRoles }
                                     title="Roles"
                                     tableActions={
                                        canEdit && (<Button variant="contained" color="primary" size="small"
                                                 onClick={ () => selectActiveEdit("roles") }>
                                             Add Roles
                                        </Button>) }
                                     idField="idRole"
                                     pageSize={ 10 }
                                     detailComponent={ RolesRowDetail }
                                     loading={ loading }
                                     totalCount={ app?.predefinedRoles.length }
                                     onDeleteRow={ (role) => console.log("delete role") }
                                     showDetailsButton
                                     onDetailRow={ (role) => history.push(`/roles/${ role.idRole }`) }
                                     defaultSorting={ [{ columnName: "name", direction: "asc" }] }/>

                <DataTable<IAPIOrganization> columns={ organizationsColumns }
                                             rows={ app?.organizations }
                                             title="Organizations"
                                             idField="idOrganization"
                                             pageSize={ 10 }
                                             loading={ loading }
                                             totalCount={ app?.organizations?.length }
                                             showDeleteButton
                                             onDeleteRow={ (org) => {
                                                 selectOrg(org)
                                                 setOpenOrgDialog(true)
                                             } }
                                             showDetailsButton
                                             onDetailRow={ (org) => history.push(`/organizations/${ org.idOrganization }`) }
                                             defaultSorting={ [{ columnName: "name", direction: "asc" }] }/></>) }
            <Dialog
                fullScreen={ fullScreen }
                open={ openDialog }
                onClose={ handleClose }
                aria-labelledby="enable-disable-app-dialog-title"
            >
                <DialogTitle id="enable-disable-app-dialog-title">Do you want
                    to { app?.status === "ACTIVE" ? "disable" : "enable" } { app?.name }?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that all your service's users and
                        clients { app?.status === "ACTIVE" ? "won't" : "will" } be able to authenticate
                        with your
                        service.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleClose }>
                        Cancel
                    </Button>
                    <LoadingButton type="button" onClick={ enableDisableApp } loading={ internalLoading }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen={ fullScreen }
                open={ openOrgDialog }
                onClose={ handleOrgClose }
                aria-labelledby="enable-disable-org-dialog-title"
            >
                <DialogTitle id="enable-disable-org-dialog-title">Do you want
                    to prevent { selectedOrg?.name } to use { app?.name } ?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that all the organization won't be able to use { app?.name }.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleOrgClose }>
                        Cancel
                    </Button>
                    <LoadingButton type="button" onClick={ removeOrg } loading={ internalLoading }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <FormDialog fullscreen={ true }
                        onDialogCancel={ () => {
                            resetFields()
                            setOpenForm(false)
                        } }
                        open={ openForm }
                        handleSubmit={ handleSubmit }
                        onDialogClose={ () => {
                            resetFields()
                            setOpenForm(false)
                        } }
                        loading={ internalLoading }
                        title={ getFormTitle(activeEdit) }>
                { getFormContent(activeEdit) }
            </FormDialog>
        </Container>
    )
}