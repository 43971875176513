import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from "@material-ui/core"
import { enqueueSnackBar, setLoading, startTutorial } from "../store/actions"
import { appViewerSteps } from "../tutorials"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { IAPIOrganization, IAPIOrganizationApp, IAPIOrganizationUser, IAPIPermission, IAPIRole, IAPIUser, IAPIUserRolesUpdate } from "../api/types"
import apiService from "../api"
import { IStoreState } from "../store/types"
import { checkPermissions } from "../utils/acl"
import { LoadingButton } from "../components/LoadingButton"
import { ToggleStatusButton } from "../components/ToggleStatusButton"
import { DataTable } from "../components/DataTable"
import qs from "qs"
import { FormDialog } from "../components/FormDialog"
import { useFormStyles } from "../forms/utils"
import { useForm } from "react-hook-form"
import { IRole, ITag } from "../forms/types"
import { AutocompleteRoles } from "../components/AutocompleteRoles"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    mb: {
        marginBottom: theme.spacing(3)
    },
    caption: {},
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}))

const rolesColumns = (applications: IAPIOrganizationApp[] | undefined) => [
    { name: "name", title: "Name" },
    {
        name: "isPredefined", title: "Is Predefined", getCellValue: (row: IAPIRole) => row.predefined ? "YES" : "NO"
    },
    {
        name: "appName", title: "Application", getCellValue: (row: IAPIRole) => {
            return (row.idApplication && applications?.find(app => app.idApplication === row.idApplication)?.name) || "n/a"
        }
    },
    { name: "status", title: "Status" }
]

const permissionsColumns = [
    { name: "macroFunction", title: "MacroFunction" },
    { name: "function", title: "Function" },
    { name: "path", title: "Path" },
    { name: "status", title: "Status", width: 100 }
]

const RolesRowDetail = ({ row }: { row: IAPIRole }) => (
    <>
        <Typography style={ { fontSize: "0.875rem" } }>
            <strong>Description: </strong> { row.description }
        </Typography>
        <DataTable<IAPIPermission> columns={ permissionsColumns }
                                   rows={ row?.permissions }
                                   idField="idPermission"
                                   title="Permissions"
                                   pageSize={ 10 }
                                   loading={ false }
                                   totalCount={ row?.permissions.length }
                                   defaultSorting={ [{ columnName: "name", direction: "asc" }] }/>
    </>
)

type UserDetailsProps = {}

export const UserOrganizationDetails: FunctionComponent<UserDetailsProps> = () => {
    const classes = useStyles()
    const formClasses = useFormStyles()
    const { idOrganization, idUser } = useParams()
    const permissions = useSelector<IStoreState, string[] | undefined>(state => state.user?.jwtPayload?.permissions)
    const canEdit = checkPermissions("editOrganization", "function", permissions || [])
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    const [user, setUser] = useState<IAPIOrganizationUser | undefined>()
    const [orgName, setOrgName] = useState<string>("")
    const [organization, setOrganization] = useState<IAPIOrganization | undefined>()
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))
    const [openDialog, setOpenDialog] = useState(false)
    const [internalLoading, setInternalLoading] = useState(false)

    let history = useHistory()
    const location = useLocation()

    const [openUserDialog, setOpenUserDialog] = useState(false)

    const removeUserFromOrganization = async () => {
        try {
            setInternalLoading(true)
            await apiService.removeUserOrganization(idOrganization, user?.user.idUserAccount || "")
            dispatch(enqueueSnackBar(
                { key: user?.user.idUserAccount || "", message: "User Succesfully removed from the Organization", options: { persist: false, preventDuplicate: false, variant: "success" } }
            ))
            history.push("/users")
        } catch {
            dispatch(enqueueSnackBar(
                { key: user?.user.idUserAccount || "", message: "Impossible to remove User from Organization", options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        handleClose()
    }

    const handleClose = () => {
        setInternalLoading(false)
        setOpenDialog(false)
    }

    const handleUserClose = () => {
        setInternalLoading(false)
        setOpenUserDialog(false)
    }

    useEffect(() => {
        const {
            organizationName
        } = qs.parse(location.search, { ignoreQueryPrefix: true })
        setOrgName(organizationName)
    }, [location])


    const loadUser = () => {
        if (idUser && idOrganization) {
            dispatch(setLoading(true))
            apiService.getOrganizationUser(idOrganization, idUser).then((response) => {
                setUser(response.data)
                setValue("roles", response.data.roles)
            }).catch((ex) => {
                dispatch(enqueueSnackBar(
                    { key: user?.user.idUserAccount || "", message: "User loading failed", options: { persist: false, preventDuplicate: false, variant: "error" } }
                ))
            }).finally(() => dispatch(setLoading(false)))
        }
    }

    const loadOrganization = () => {
        if (idOrganization) {
            dispatch(setLoading(true))
            apiService.getOrganization(idOrganization, true).then((response) => {
                setOrganization(response.data)
                console.log(response.data.roles)
                setRoleOptions(response.data.roles)
            }).catch((ex) => {
                dispatch(enqueueSnackBar(
                    { key: user?.user.idUserAccount || "", message: "Organization loading failed", options: { persist: false, preventDuplicate: false, variant: "error" } }
                ))            
            }).finally(() => dispatch(setLoading(false)))
        }
    }

    const { triggerValidation, register, unregister, errors, setValue, watch } = useForm<IAPIUser>({
        defaultValues: {
            ...user,
            roles: user?.roles
        }
    })
    const fields = watch({ nest: true })
    const [tagOptions, setTagOptions] = useState<ITag[]>([])
    const [roleOptions, setRoleOptions] = useState<IAPIRole[]>([])

    const resetFields = () => {
        setValue("roles", user?.roles || [])
    }

    useEffect(() => {
        loadUser()
        loadOrganization()
    }, [])

    const handleSubmit = async () => {
        try {
            setInternalLoading(true)
            let validation = true
            await triggerValidation(["roles", "applications", "visibilityTags"])
            if (!errors.roles) {
                const req: IAPIUserRolesUpdate = {
                    rolesIds: fields.roles?.map(r => r.idRole) || [],
                    idOrganization
                }
                await apiService.updateUserRoles(idUser, req)
            } 
            resetFields()
            dispatch(enqueueSnackBar(
                { key: user?.user.idUserAccount || "", message: intl({ id: "messages.general.updateSuccessful" }), options: { persist: false, preventDuplicate: false, variant: "success" } }
            ))
            setInternalLoading(false)
            setOpenUserDialog(false)
            loadUser();
        } catch {
            dispatch(enqueueSnackBar(
                { key: user?.user.idUserAccount || "", message: intl({ id: "messages.general.updateFailed" }), options: { persist: false, preventDuplicate: false, variant: "error" } }
            ))
        }
        setInternalLoading(false)
    }

    useEffect(() => {
        register({ name: "roles" }, {
            validate: (value: IRole[]) => (value && value.length > 0) || intl({ id: "forms.errors.required" })
        })
        return () => unregister(["roles"])
    }, [])

    return (
        <Container className={ clsx(classes.root, classes.columnCenter) } component="main">
            { loading ? (<Typography>Loading data...</Typography>) : (<>
                <Typography className={ classes.mt }
                            variant="h4">{ user?.user.firstName } { user?.user.lastName }</Typography>
                <Typography className={ classes.mt } variant="subtitle1">{ user?.user.email }</Typography>
                <Typography className={ classes.mt } variant="subtitle1"><strong>Org: </strong>{ orgName }
                </Typography>
                <Grid container justify="center" className={ classes.mt }>
                    <Grid item>
                        { canEdit && (
                            <ToggleStatusButton style={ { marginLeft: 10 } } data-tut="organizations.toggleStatus"
                                                action="disable"
                                                onClick={ () => setOpenDialog(true) }>Remove</ToggleStatusButton>) }
                        <Button style={ { marginLeft: 10 } } variant="contained" color="secondary"
                                onClick={ () => dispatch(startTutorial(appViewerSteps(intl))) }>{ intl({ id: "functions.help" }) }</Button>
                    </Grid>
                </Grid></>) }
            <DataTable<IAPIRole> columns={ rolesColumns(organization?.applications) }
                                 rows={ user?.roles }
                                 title="Roles"
                                 pageSize={ 10 }
                                 idField="idRole"
                                 loading={ loading }
                                 tableActions={
                                     <Button variant="contained" color="primary" size="small"
                                             onClick={ () => setOpenUserDialog(true) }>
                                         { intl({ id: "functions.edit" }) }
                                     </Button> }
                                 totalCount={ user?.roles?.length }
                                 detailComponent={ RolesRowDetail }
                                 showDetailsButton
                                 onDetailRow={ (role) => history.push(`/roles/${ role.idRole }`) }
                                 defaultSorting={ [{ columnName: "idApplication", direction: "asc" }] }/>
            <br/>
            <Dialog
                fullScreen={ fullScreen }
                open={ openDialog }
                onClose={ handleClose }
                aria-labelledby="enable-disable-org-dialog-title"
            >
                <DialogTitle id="enable-disable-org-dialog-title">Do you want
                    to remove the user from the organization?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Keep in mind that the
                        user won't be able
                        to authenticate with this organization.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ handleClose }>
                        Cancel
                    </Button>
                    <LoadingButton type="button" onClick={ removeUserFromOrganization } loading={ internalLoading }>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <FormDialog fullscreen
                        onDialogCancel={ () => {
                            resetFields()
                            setOpenUserDialog(false)
                        } }
                        open={ openUserDialog }
                        handleSubmit={ handleSubmit }
                        onDialogClose={ () => {
                            resetFields()
                            setOpenUserDialog(false)
                        } }
                        loading={ internalLoading }
                        title={ "Edit Roles" }>
                <Grid container className={ formClasses.stepContainer } justify="center" spacing={ 2 }>
                    <Grid item container justify="center" spacing={ 2 }>
                        <Typography variant="h5" className={ formClasses.verticalMargin }>Insert users' roles and
                            visibility tags</Typography>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <AutocompleteRoles
                            options={ roleOptions || [] }
                            value={ fields.roles }
                            onChange={ (e: ChangeEvent<{}>, value: IAPIRole[] | null) => {
                                if (value) setValue("roles", value, true)
                            } }
                            error={ errors.roles }
                        />
                    </Grid>
                </Grid>
            </FormDialog>
        </Container>
    )
}