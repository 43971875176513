const acl: IAcl = {
    pages: {
        home: ["*"],
        profile: ["*"],
        company: ["nova_auth.*.*", "nova_auth.company_management.*", "nova_auth.company_management.view_company"],
        organizations: ["*"],
        applications: ["nova_auth.*.*", "nova_auth.application_management.*", "nova_auth.application_management.edit"],
        roles: ["*"],
        users: ["nova_auth.*.*", "nova_auth.user_management.*", "nova_auth.user_management.invite"],
        services: ["nova_auth.*.*", "nova_auth.service_management.*", "nova_auth.role_management.edit", "nova_auth.role_management.list", "nova_auth.role_management.create"],
        invitations: ["nova_auth.*.*", "nova_auth.user_management.*", "nova_auth.organization_management.invite", "nova_auth.user_management.invite"]
    },
    functions: {
        editOrganization: ["nova_auth.*.*", "nova_auth.organization_management.*", "nova_auth.organization_management.edit"],
        inviteOrganization: ["nova_auth.*.*", "nova_auth.organization_management.*", "nova_auth.organization_management.invite"],
        changeOrganizationStatus: ["nova_auth.*.*"],
        editApplication: ["nova_auth.*.*", "nova_auth.application_management.*", "nova_auth.application_management.edit"],
        createApplication: ["nova_auth.*.*", "nova_auth.application_management.*", "nova_auth.application_management.create"],
        disableApplication: ["nova_auth.*.*", "nova_auth.application_management.*", "nova_auth.application_management.edit"],
        editRole: ["nova_auth.*.*", "nova_auth.user_management.*", "nova_auth.user_management.edit"],
        createRole: ["nova_auth.*.*", "nova_auth.user_management.invite"],
        editUser: ["nova_auth.*.*", "nova_auth.user_management.*", "nova_auth.user_management.edit"],
        deleteUser: ["nova_auth.*.*", "nova_auth.user_management.*", "nova_auth.user_management.delete"],
        inviteUser: ["nova_auth.*.*", "nova_auth.user_management.*", "nova_auth.user_management.create"],
        editServiceAccount: ["nova_auth.*.*", "nova_auth.service_management.*", "nova_auth.service_management.edit"],
        createServiceAccount: ["nova_auth.*.*", "nova_auth.service_management.*", "nova_auth.service_management.create"],
    }
}

interface IAcl {
    pages: { [key: string]: string[] }
    functions: { [key: string]: string[] }
}

type PageType = "home" | "profile" | "company" | "organizations" | "applications" | "roles" | "users" | "services" | "invitations"

export function checkPermissions(resource: PageType | string, type: "page" | "function" | "property", permissions: string[]): boolean {
    let can = false
    switch (type) {
        case "page":
            can = acl.pages[resource] && (acl.pages[resource].includes("*") || permissions.some(r => acl.pages[resource].includes(r)))
            break
        case "function":
            can = acl.functions[resource] && (acl.functions[resource].includes("*") || permissions.some(r => acl.functions[resource].includes(r)))
            break
        case "property":
            break
        default:
            return false
    }
    return can
}