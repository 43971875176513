import React, { FunctionComponent } from "react"
import { Card, CardActions, CardContent, Divider, Grid, Link, Typography } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { useIntl } from "react-intl"

const useStyles = makeStyles((theme: Theme) => ({
    cardActions: {
        padding: theme.spacing(2)
    },
    cardContent: {
        overflowY: "auto",
        padding: theme.spacing(2),
        height: 200
    },
    cardText: {
        textAlign: "left"
    },
    cardMainContent: {
        alignItems: "center"
    },
    card: {
        minWidth: 275
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    buttonLink: {
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 600,
    }
}))


type HomeCardProps = {
    homeCard: any
    onChangeIndex: (id: number) => void
}

export const HomeCard: FunctionComponent<HomeCardProps> = (props) => {
    const classes = useStyles()
    const { homeCard } = props
    const {formatMessage: intl } = useIntl();

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.cardMainContent} spacing={1}>
                    <Grid className={classes.cardText} item xs={6}>
                        <Typography variant="h5">
                            {intl({id: homeCard.intlId + '.title'})}
                        </Typography>
                        <Typography variant="subtitle1" color={"textSecondary"} className={classes.caption}>{intl({id: homeCard.intlId + '.description'})}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <img width={homeCard.width} alt="homeCard Icons" src={require("../assets/"+homeCard.img)} />
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActions} >
                <Link color="primary" className={classes.buttonLink} onClick={() => props.onChangeIndex(homeCard.id)}>
                    {intl({id: homeCard.intlId + '.action'})}
                </Link>
            </CardActions>
        </Card>
    )
}