import React, { FunctionComponent } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Drawer as MuiDrawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@material-ui/core"
import clsx from "clsx"
import { drawerWidth, pages } from "../utils/config"
import { IPage } from "../pages/types"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { checkPermissions } from "../utils/acl"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    drawerPaper: {
        width: drawerWidth,
        borderRight: "none"
    },
    drawerItem: {
        paddingLeft: theme.spacing(3),
        borderRadius: theme.spacing(0, 3, 3, 0),
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
    },
    drawerItemActive: {
        color: theme.palette.primary.main,
        backgroundColor: "#2A6EFB20",
        "&:hover": {
            backgroundColor: "#2A6EFB20",
        }
    },
    drawerItemIconActive: {
        color: theme.palette.primary.main
    },
    mobileDrawer: {
        borderBottom: "1px solid #ccc",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    }
}))

type DrawerProps = {
    pages: IPage[]
    onChangeIndex: (index: number) => void
    location: string
    intro: boolean
}

export const Drawer: FunctionComponent<DrawerProps> = (props) => {
    const classes = useStyles()
    const { formatMessage: intl } = useIntl()
    const permissions = useSelector((state: IStoreState) => state.user?.jwtPayload?.permissions || [])

    return (
        <MuiDrawer
            className={ classes.drawer }
            variant="permanent"
            classes={ {
                paper: classes.drawerPaper
            } }
        >
            <Toolbar/>
            <List>
                { pages.map((page, index) => props.intro || checkPermissions(page.value, "page", permissions) ?
                    (<ListItem data-tut={ page.dataTut } button key={ page.id }
                               className={ clsx({ [classes.drawerItem]: true, [classes.drawerItemActive]: page.regex.test(props.location) }) }
                               onClick={ () => {
                                   props.onChangeIndex(index)
                               } }>
                        <ListItemIcon
                            className={ clsx({ [classes.drawerItemIconActive]: page.regex.test(props.location) }) }>{ page.icon }</ListItemIcon>
                        <ListItemText primary={ intl({ id: page.nameId }) }/>
                    </ListItem>) : undefined
                ) }
            </List>
        </MuiDrawer>
    )
}