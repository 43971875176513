import React, { FunctionComponent } from "react"
import { Button, CircularProgress } from "@material-ui/core"

type LoadingButtonProps = {
    loading: boolean
    type?: "button" | "submit" | "reset" | undefined
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const LoadingButton: FunctionComponent<LoadingButtonProps> = (props) => {
    return (
        <Button
            type={props.type}
            variant="contained"
            color="primary"
            disabled={ props.loading }
            onClick={ props.onClick }
            endIcon={ props.loading ? <CircularProgress color="secondary" size={ 14 }/> : undefined }
        >
            { props.children }
        </Button>
    )
}

LoadingButton.defaultProps = {
    type: undefined
}