import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import qs from "qs";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { asyncExchangeCode } from "../store/thunks";
import { IStoreState } from "../store/types";
//import FlareComponent  from 'flare-react'
import { Loader } from "../components/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    position: "absolute",
    width: 120,
    height: 120,
    marginTop: "-16px",
  },
  loadingText: {
    marginTop: "10px",
  },
}));

type ExchangeContainerProps = {};

export const ExchangeContainer: FunctionComponent<ExchangeContainerProps> = (
  props
) => {
  const location = useLocation();
  const user = useSelector((state: IStoreState) => state.user);
  const [username, setUsername] = useState("");
  const [imgUrl, setImgUrl] = useState();
  let history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const classes = useStyles();

  useEffect(() => {
    const { username, code, imgUrl } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (code) {
      setImgUrl(imgUrl);
      setUsername(username);
      dispatch(asyncExchangeCode(code));
    } else {
      history.push("/");
    }
  }, [location]);

  return user ? (
    <Redirect to="/" />
  ) : (
    <Container className={classes.root} component="main">
      <CssBaseline />
      <Loader />
      <Typography className={classes.loadingText}>
        {intl({ id: "welcome.text" }, { name: username || "" })}
      </Typography>
    </Container>
  );
};
