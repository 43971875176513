import React, { ChangeEvent, forwardRef, FunctionComponent, useEffect } from "react"
import PermissionsIcon from "@material-ui/icons/LockOpenOutlined"
import GeneralInfoIcon from "@material-ui/icons/AssignmentOutlined"
import ReviewIcon from "@material-ui/icons/AssignmentTurnedInOutlined"
import { Chip, Grid, MenuItem, Slide, TextField, Typography } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { FormWizardDialog } from "../components/FormWizardDialog"
import { IAPIError, IAPIOrganization, IAPIRole } from "../api/types"
import { useFormStyles } from "./utils"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { ICreateServiceValues, ITag } from "./types"
import apiService from "../api"
import { enqueueSnackBar, setLoading } from "../store/actions"
import { AutocompleteTags } from "../components/AutocompleteTags"
import { AutocompleteRoles } from "../components/AutocompleteRoles"
import { AutocompleteOrganization } from "../components/AutocompleteOrganization"
import { Autocomplete } from "@material-ui/lab"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ ref } { ...props } />
})

type ServicesFormProps = {
    open: boolean
    onClose: Function
    onSuccess: (id: string) => void
}

export const ServicesForm: FunctionComponent<ServicesFormProps> = (props) => {
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const dispatch = useDispatch()
    const classes = useFormStyles()

    const { handleSubmit, triggerValidation, register, unregister, errors, setValue, watch } = useForm<ICreateServiceValues>({
        reValidateMode: "onChange",
        defaultValues: {
            selectedTags: [],
            roles: [],
            externalIds: [],
            sessionDuration: 60,
            frontend: 0
        }
    })
    const fields = watch({ nest: true })

    const [activeStep, setActiveStep] = React.useState(0)

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = async () => {
        let result
        switch (activeStep) {
            case 0:
                await triggerValidation(["name", "organization", "frontend", "sessionDuration"])
                result = errors.name || errors.organization || errors.frontend || errors.sessionDuration
                break
            case 1:
                await triggerValidation(["roles", "selectedTags", "externalIds"])
                result = errors.roles || errors.selectedTags || errors.externalIds
                break
        }
        if (!result) setActiveStep(activeStep + 1)
    }

    const handleReset = () => {
        reset()
        setActiveStep(0)
    }

    const reset = () => {
        setValue("name", "")
        setValue("description", "")
        setValue("selectedTags", [])
        setValue("roles", [])
        setValue("externalIds", [])
        setValue("sessionDuration", 60)
        setValue("frontend", 0)
    }

    useEffect(() => {
        setTimeout(() => {
            const elements = document.querySelectorAll("#___reactour button")
            elements.forEach((el: any) => (el.tabIndex = -1))
        }, 100)
    })

    useEffect(() => {
        register({ name: "name" }, {
            validate: value => (value && value.length > 0) || "Required"
        })
        register({ name: "organization" }, {
            required: "Required"
        })
        register({ name: "description" }, {})
        register({ name: "frontend" }, {
            required: "Required"
        })
        register({ name: "sessionDuration" }, {
            min: 0 || "Should be > 0"
        })
        register({ name: "selectedTags" }, {})
        register({ name: "roles" }, {})
        register({ name: "externalIds" }, {})

        return () => unregister(["name", "organization", "description", "frontend", "sessionDuration", "selectedTags", "roles", "externalIds"])
    }, [register, unregister])

    const onSubmit = async (data: ICreateServiceValues, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault()
        dispatch(setLoading(true))
        apiService.createServiceAccount({
            description: data.description,
            frontend: data.frontend !== 0,
            idOrganization: data.organization?.idOrganization || "",
            name: data.name,
            rolesId: data.roles.map(role => role.idRole),
            sessionDuration: data.sessionDuration * 60 * 1000, //ms value
            vTags: data.selectedTags.map(tag => tag.tag)
        }).then((response) => {
                const data = response.data
                reset()
                dispatch(enqueueSnackBar({
                    key: data.idServiceAccount || "", message: "Data Correctly Saved!", options: {
                        preventDuplicate: false, variant: "success"
                    }
                }))
                props.onClose()
            }
        ).catch((ex: IAPIError) => {
                dispatch(enqueueSnackBar({
                    key: ex.code || "", message: "Ops! Something went wrong", options: {
                        preventDuplicate: false, variant: "error"
                    }
                }))
            }
        ).finally(() => dispatch(setLoading(false)))
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={ 2 } className={ classes.stepContainer }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>
                                Insert the service name, description and the organization it will operate for
                            </Typography>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                            <TextField
                                onChange={ (e) => {
                                    setValue("name", e.target.value)
                                } }
                                color="primary"
                                variant="outlined"
                                label="Service Name"
                                size="small"
                                placeholder="name"
                                fullWidth
                                required
                                name="name"
                                value={ fields.name || "" }
                                error={ !!errors.name }
                                helperText={ (!!errors.name && (errors.name as any).message) || " " }
                            />
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                            <AutocompleteOrganization
                                onChange={ (e: ChangeEvent<{}>, value: IAPIOrganization | null) => {
                                    if (value) setValue("organization", value, true)
                                } }
                                error={ errors.organization }
                            />
                        </Grid>
                        <Grid item xs={ 12 } sm={ 12 } md={ 8 }>
                            <TextField
                                data-tut="service.create.description"
                                variant="outlined"
                                fullWidth
                                color="primary"
                                size="small"
                                label="Description"
                                onChange={ (e) => {
                                    setValue("description", e.target.value)
                                } }
                                value={ fields.description || "" }
                                error={ !!errors.description }
                                helperText={ (!!errors.description && (errors.description as any).message) || " " }
                            />
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 2 }>
                            <TextField
                                variant="outlined"
                                select
                                required
                                fullWidth
                                id="method"
                                label="Frontend"
                                defaultValue={ 0 }
                                value={ fields.frontend }
                                onChange={ (e) => {
                                    setValue("frontend", parseInt(e.target.value, 10))
                                } }
                                name="frontend"
                                size="small"
                            >
                                <MenuItem value={ 1 }>Yes</MenuItem>
                                <MenuItem value={ 0 }>No</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 6 } md={ 2 }>
                            <TextField
                                data-tut="service.create.duration"
                                variant="outlined"
                                fullWidth
                                color="primary"
                                size="small"
                                label="Jwt duration (minutes)"
                                type="number"
                                inputProps={ {
                                    min: 0
                                } }
                                onChange={ (e) => {
                                    const value = Number(e.target.value).toString() || "1"
                                    e.target.value = value
                                    setValue("sessionDuration", parseInt(value, 10))
                                } }
                                value={ fields.sessionDuration }
                                error={ !!errors.sessionDuration }
                                helperText={ (!!errors.sessionDuration && (errors.sessionDuration as any).message) || " " }
                            />
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container className={ classes.stepContainer } justify="center" spacing={ 2 }>
                        <Grid item container justify="center" spacing={ 2 }>
                            <Typography variant="h5" className={ classes.verticalMargin }>
                                Insert service's roles and visibility tags
                            </Typography>
                        </Grid>
                        <Grid item className={ classes.grow }>
                            <AutocompleteTags
                                onChange={ (e: ChangeEvent<{}>, value: ITag[]) => {
                                    setValue("selectedTags", value)
                                } }
                                error={ !!errors.selectedTags }
                            />
                        </Grid>
                        <Grid item xs={ 12 }>
                            <AutocompleteRoles
                                onChange={ (e: ChangeEvent<{}>, value: IAPIRole[] | null) => {
                                    if (value) setValue("roles", value, true)
                                } }
                                error={ errors.roles }
                            />
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Autocomplete<string>
                                multiple
                                id="emails-filled"
                                freeSolo
                                options={[]}
                                defaultValue={ fields.externalIds }
                                renderTags={ (value: string[], getTagProps) =>
                                    value.map((option: string, index: number) => (
                                        <Chip size="small" variant="outlined"
                                              label={ option } { ...getTagProps({ index }) } />
                                    ))
                                }
                                onChange={ (e, value) => {
                                    setValue("externalIds", value, true)
                                } }
                                renderInput={ params => (
                                    <TextField
                                        { ...params }
                                        color="primary"
                                        variant="outlined"
                                        label="External ids (press Enter after each external id)"
                                        size="small"
                                        placeholder="ID"
                                        fullWidth
                                        name="externalIds"
                                        error={ !!errors.externalIds }
                                        helperText={ (!!errors.externalIds && (errors.externalIds as any).message) || " " }
                                    />
                                ) }
                            />
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container className={ classes.stepContainer } justify="center" spacing={ 2 }>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">You're going to create the service:</Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6"><strong>{ fields.name }</strong></Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">
                                for the Organization: <strong>{ fields.organization?.name }</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant="h6">with the following roles:</Typography>
                        </Grid>
                        {
                            fields.roles.map(role => (
                                <Grid item xs={ 12 } key={ role.idRole }>
                                    <Typography
                                        variant="h6">{ role.idApplication ? role.application?.name : fields.organization?.name }'s <strong>{ role.name }</strong>
                                    </Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                )
            default:
                return "Unknown step"
        }
    }

    return (
        <FormWizardDialog Transition={ Transition } open={ props.open } fullscreen
                          onDialogClose={ () => {
                              reset()
                              props.onClose()
                          } }
                          onDialogCancel={ () => {
                              reset()
                              props.onClose()
                          } }
                          handleSubmit={ handleSubmit(onSubmit) } title="Create Service"
                          steps={ [{ label: "Service Info", icon: <GeneralInfoIcon/> }, {
                              label: "Roles & Visibility Tags", icon: <PermissionsIcon/>
                          }, { label: "Review", icon: <ReviewIcon/> }] }
                          activeStep={ activeStep }
                          loading={ loading }
                          getStepContent={ getStepContent }
                          handleBack={ handleBack }
                          handleNext={ handleNext }
                          handleReset={ handleReset }>
        </FormWizardDialog>
    )
}
