import { Middleware } from "redux"
import { IJwtPayload, IStoreState } from "./types"
import apiService from "../api"
import { enqueueSnackBar, logout, setRefreshingToken, setUser } from "./actions"
import React from "react"
import jwtDecode from "jwt-decode"

export const jwtRefresher: Middleware = ({ dispatch, getState }) => next => action => {
    const { user, refreshingToken } = getState() as IStoreState

    // 5 minutes from now
    const refreshThreshold = 300000
    const now = new Date().getTime()

    if (action.type !== "SET_REFRESHING_TOKEN" && user && !refreshingToken && user.jwtPayload && user.jwtPayload.exp && ((user.jwtPayload.exp * 1000 - now) < refreshThreshold)) {
        dispatch(setRefreshingToken(true))
        apiService.refreshToken().then(result => {
            const jwt = result.data.access_token
            apiService.setJwt(jwt)
            const jwtPayload = jwtDecode<IJwtPayload>(jwt) || ""
            dispatch(setUser({ ...user, jwt, jwtPayload }))
        }).catch().finally(() => dispatch(setRefreshingToken(false)))
    }
    return next(action)
}

export const authInterceptor: Middleware = ({ dispatch, getState }) => next => action => {
    if (action.statusCode === 401) {
        const { user } = getState() as IStoreState
        if (user) {
            dispatch(enqueueSnackBar({
                dismissed: false, key: "start_logout", message: "Please Re-Login To Use the Manager", options: {
                    action: () => {}, preventDuplicate: true, variant: "error"
                }
            }))
        }
        dispatch(logout())
    } else {
        next(action)
    }
}