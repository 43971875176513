import React, { FunctionComponent } from "react"
import { Button, Chip, Grid, IconButton, TextField } from "@material-ui/core"
import { useIntl } from "react-intl"
import { Autocomplete } from "@material-ui/lab"
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add"
import { useFormStyles } from "./utils"
import { IFeature, IFeatureError } from "./types"

type PermissionsStepProps = {
    onChange: (permissions: any) => void
    features: IFeature[]
    error: IFeatureError[]
}

export const PermissionsStep: FunctionComponent<PermissionsStepProps> = (props) => {
    const classes = useFormStyles()
    const { formatMessage: intl } = useIntl()


    const updateFeatures = (value: any, idx: number, field: string) => {
        switch (field) {
            case "macroFunction":
                props.onChange([...props.features.slice(0, idx), { ...props.features[idx], macroFunction: value }, ...props.features.slice(idx + 1)])
                break
            case "permissions":
                props.onChange( [...props.features.slice(0, idx), { ...props.features[idx], functions: value }, ...props.features.slice(idx + 1)])
                break
        }
    }

    const removeFunction = (index: number) => {
        props.onChange([...props.features.slice(0, index), ...props.features.slice(index + 1)])
    }

    const addFunction = () => {
        const name = `function_${ props.features.length }`
        props.onChange([...props.features, { macroFunction: name, functions: ["All"] }])
    }

    return (
        <><Grid className={ classes.smallBottomMargin } item container justify="space-between"
                spacing={ 0 }>
            { props.features.map((feature, index) => {
                const fieldName = `features[${ index }]`
                return (
                    <Grid key={ fieldName } item container justify="space-between" spacing={ 1 }>
                        <Grid item xs={ 12 } sm={ 2 }>
                            <TextField
                                data-tut="app.create.macroFunction"
                                variant="outlined"
                                color="primary"
                                size="small"
                                label={ intl({ id: "applications.forms.macroFunction" }) }
                                fullWidth
                                disabled={ index === 0 }
                                name="macroFunction"
                                onChange={ (e) => {
                                    updateFeatures(e.target.value, index, "macroFunction")
                                } }
                                value={ feature.macroFunction }
                                error={ props.error && !!props.error[index] && props.error[index].macroFunction }
                                helperText={ (props.error && !!props.error[index] && props.error[index].macroFunction && intl({ id: "forms.errors.required" })) || " " }
                            />
                        </Grid>
                        <Grid item xs={ index === 0 ? 12 : 11 } sm={ index === 0 ? 10 : 9 }>
                            <Autocomplete<string>
                                multiple
                                freeSolo
                                value={ feature.functions as string[] }
                                onChange={ (e, value) => {
                                    updateFeatures(value, index, "permissions")
                                } }
                                options={[]}
                                disabled={ index === 0 }
                                renderTags={ (value: string[], getTagProps) =>
                                    value.map((option: string, idx: number) => (
                                        <Chip  { ...getTagProps({ index: idx }) }
                                               disabled={ idx === 0 }
                                               variant="outlined"
                                               size="small"
                                               label={ option }/>
                                    ))
                                }
                                renderInput={ params => (
                                    <TextField
                                        data-tut="app.create.permissions"
                                        { ...params }
                                        color="primary"
                                        variant="outlined"
                                        label={ intl({ id: "applications.forms.functions" }) }
                                        size="small"
                                        placeholder={ intl({ id: "applications.forms.functions" }) }
                                        fullWidth
                                        error={ props.error && !!props.error[index] && props.error[index].functions }
                                        helperText={ (props.error && !!props.error[index] && props.error[index].functions && intl({ id: "forms.errors.required" })) || " " }
                                    />
                                ) }
                            />
                        </Grid>
                        { index === 0 ? undefined :
                            (<Grid item>
                                <IconButton edge="end"
                                            data-tut="app.create.deleteFunction"
                                            aria-label="delete"
                                            onClick={ () => removeFunction(index) }>
                                    <DeleteIcon/>
                                </IconButton>
                            </Grid>) }
                    </Grid>)
            }) }
        </Grid>
        <Grid item>
            <Button data-tut="app.create.addFunction"
            onClick={ () => addFunction() }
            variant="contained"
            color="primary"
            startIcon={ <AddIcon/> }
        >
            { intl({ id: "applications.forms.function.new" }) }
        </Button>
        </Grid>
</>
)
}