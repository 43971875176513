import React, { FunctionComponent, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { Button, Typography } from "@material-ui/core"
import { homeCards } from "../utils/config"
import { DataTable } from "../components/DataTable"
import { useIntl } from "react-intl"
import { RolesForm } from "../forms/RolesForm"
import { useHistory } from "react-router-dom"
import apiService from "../api"
import { IAPIRole } from "../api/types"
import { formatDate } from "../utils"
import axios, { CancelTokenSource } from "axios"
import { setLoading } from "../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState } from "../store/types"

const CancelToken = axios.CancelToken

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    mb: {
        marginBottom: theme.spacing(3)
    }
}))

const columns = [
    { name: "name", title: "Name" },
    {
        name: "isPredefined", title: "Is Predefined", getCellValue: (row: IAPIRole) => row.predefined ? "YES" : "NO"
    },
    {
        name: "application", title: "Application", getCellValue: (row: IAPIRole) => {
            return row.application ? row.application.name : ""
        }
    },
    { name: "status", title: "Status" },
    {
        name: "created", title: "Created", getCellValue: (row: IAPIRole) => {
            return formatDate(row.created || "")
        }
    }
]

type RolesProps = {}

export const Roles: FunctionComponent<RolesProps> = (props) => {
    const classes = useStyles()
    const { formatMessage: intl } = useIntl()
    const [openForm, setOpenForm] = useState(false)
    const [roles, setRoles] = useState<IAPIRole[]>([])
    let history = useHistory()
    const source = CancelToken.source()
    const dispatch = useDispatch()
    const loading = useSelector<IStoreState, boolean>(state => state.loading)

    async function listRoles(source: CancelTokenSource) {
        try {
            dispatch(setLoading(true))
            const result = await apiService.listRoles({ page: 0, perPage: 0 }, undefined, source.token)
            setRoles(result.data)
        } catch (e) {
            //if (e.statusCode !== 0) setError(true)
        }
        dispatch(setLoading(false))
    }

    useEffect(() => {
        listRoles(source)
        return () => {
            source.cancel("Operation Canceled By User")
            dispatch(setLoading(false))
        }
    }, [])

    return (
        <Container className={ classes.root } component="main" style={ { maxWidth: "100%" } }>
            <Typography variant="h4">{ intl({ id: homeCards[3].intlId + ".title" }) }</Typography>
            <Typography variant="subtitle1"
                        className={ classes.caption }>{ intl({ id: homeCards[3].intlId + ".description" }) }</Typography>
            <Button className={ classes.mb } variant="contained" color="primary"
                    onClick={ () => setOpenForm(true) }>{ intl({ id: "functions.addNew" }) }</Button>
            <DataTable<IAPIRole> loading={ loading }
                                 totalCount={ roles.length }
                                 columns={ columns }
                                 rows={ roles }
                                 idField="idRole"
                                 pageSize={ 10 }
                                 pageSizes={ [5, 10, 15] }
                                 defaultSorting={ [{ columnName: "name", direction: "asc" }] }
                                 showDetailsButton
                                 onDetailRow={ (row) => history.push(`/roles/${ row.idRole }`) }/>
            <RolesForm open={ openForm } onClose={ (done: boolean) => {
                done && listRoles(source)
                setOpenForm(false)
            } }/>
        </Container>
    )
}