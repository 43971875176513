import React, { ChangeEvent, FunctionComponent } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { ITag } from "../forms/types"
import { Chip } from "@material-ui/core"
import { findTagOptions, useFormStyles } from "../forms/utils"
import { useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { IAPIApplication } from "../api/types"

type AutocompleteTagsProps = {
    onChange: (e: ChangeEvent<{}>, value: ITag[]) => void
    value?: ITag[]
    error?: any
    dataTut?: string
}

export const AutocompleteTags: FunctionComponent<AutocompleteTagsProps> = (props) => {
    const classes = useFormStyles()
    const apps = useSelector<IStoreState, IAPIApplication[] | undefined>(state => state.apps)
    const tagOptions = findTagOptions(apps?.map(app => { return { ...app, idApplication: app.idApplication || "", available: true }}))

    return (
        <Autocomplete<ITag>
            multiple
            id="tags-outlined"
            options={ tagOptions }
            groupBy={ option => option.appName }
            getOptionLabel={ option => option.tag }
            filterSelectedOptions
            renderTags={ (value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={ option.appName + ": " + option.tag } { ...getTagProps({ index }) }/>
                ))
            }
            value={ props.value }
            onChange={ props.onChange }
            className={ classes.formControl }
            renderInput={ params => (
                <TextField
                    color="primary"
                    { ...params }
                    variant="outlined"
                    label="Visibility Tags"
                    fullWidth
                    name="tags"
                    error={ !!props.error }
                    helperText={ (!!props.error && (props.error as any).message) || " " }
                />
            ) }
        />
    )
}